export interface ViewLink {
    id: number;
    name: string;
    abbreviation: string;
    privilege?: string;
    isGlobal?: boolean;
}

export class ViewTile {
    id: number;
    name: string;
    isGroup: boolean;
    selectedLink: ViewLink | undefined;
    links: ViewLink[];

    constructor(init?: Partial<ViewTile>) {
        this.id = init?.id ?? 0;
        this.name = init?.name ?? '';
        this.isGroup = init?.isGroup ?? false;
        this.selectedLink = init?.selectedLink;
        this.links = init?.links ?? [];
    }
}
