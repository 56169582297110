<section class="view-notes-container">
    <header>
        <div class="view-notes" (mouseenter)="listIconHighlight = true" (mouseleave)="listIconHighlight = false">
            <cc-icon
                [icon]="'list'"
                [size]="'medium'"
                [color]="'var(--grid-action-color)'"
            >
            </cc-icon>
            <span>{{ buttonLabel }}</span>
        </div>

        <cc-button [type]="'naked'" (mouseenter)="editIconHighlight = true" (mouseleave)="editIconHighlight = false" (pressed)="onClose()">
            @if (!inPresentationMode && (!isGlobal || !isGlobalEditPartial)) {
                <cc-icon
                    [icon]="'edit-pencil'"
                    [size]="'medium'"
                    [class.highlighted]="editIconHighlight"
                    (click)="onClose()"
                >
                </cc-icon>
            }
            {{ closeButtonText }}
        </cc-button>
     </header>
     <main>
        <h2>{{ title }}</h2>

        @for (note of notes; track note) {
            <p>{{ note }}</p>
        }
     </main>
</section>
