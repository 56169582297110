// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const isEdge = document.location.href.includes('ddv-edge') ||
    document.location.hostname.includes('poc-rnd') ||
    document.location.pathname.includes('aws-rnd');

export const baseEnvironment = {
    production: false,
    // eslint-disable-next-line max-len
    agGridLicenseKey: 'Using_this_{AG_Grid}_Enterprise_key_{AG-057106}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Hedgeserv_Corporation}_is_granted_a_{Multiple_Applications}_Developer_License_for_{40}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{3}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{26_June_2025}____[v3]_[01]_MTc1MDg5MjQwMDAwMA==83c729ba726c70bba2bc9ad97c463754',
    isEdge,
    apm: {
        environment: 'dev',
        active: false, // change this to "true" if you want to send instrumentation for dev
    },
    ddvMWLocation: {
        hostAndPort: undefined,
        rootUri: isEdge ? '/ddv-edge/api/' : '/ddv/api/',
    },
    monitorMWLocation: {
        hostAndPort: undefined,
        rootUri: isEdge ? '/ddv-edge/' : '/ddv/',
    },
    ddvApiLocation: {
        hostAndPort: undefined,
        rootUri: '/ddv/v2/api/',
    },
    monitorApiLocation: {
        hostAndPort: undefined,
        rootUri: '/ddv/v2/',
    },
    trebekApiLocation: {
        hostAndPort: undefined,
        rootUri: isEdge ? '/public-api-edge/rest/' : '/public-api/rest/',
    },
    usageApiLocation: {
        hostAndPort: undefined,
        rootUri: '/client_usage/',
    },
    flagsApiLocation: {
        hostAndPort: undefined,
        rootUri: '/featureflags/api/',
    },
    wauthApiLocation: {
        hostAndPort: undefined,
        rootUri: '/wauth/api/',
    },
    xtlkApiLocation: {
        hostAndPort: undefined,
        rootUri: '/two-way/api/',
    },
    reportRunnerApiLocation: {
        hostAndPort: undefined,
        rootUri: '/data/',
    },
    errorLoggerLocation: {
        hostAndPort: undefined,
        rootUri: '/errors',
    },
    nqsApiLocation: {
        hostAndPort: undefined,
        rootUri: '/named-query/api/',
    },
    cfbApiLocation: {
        hostAndPort: undefined,
        rootUri: '/field-bldr/api/',
    },
    fmpLocation: {
        hostAndPort: undefined,
        rootUri: '/fmp',
    },
    pscLocation: {
        hostAndPort: undefined,
        rootUri: '/psc/',
    },
    portfolioDetailsHostAndPort: undefined,
    onPremApiHost: 'localhost:8100',  // this is the default when running `npm start`,
    replaceHostInMetadataAndQueryURLs: true,
    xtlkManagementLocation: {
        hostAndPort: undefined,
        rootUri: '/two-way/',
    },
    requestMetadataFromNQS: false,
};
