<div class="dsd-selector-container">
    <div class="labeled-dropdown datatype">
        <label>Query Type</label>

        @if (enabled) {
            <div class="arrow-selector">
                <app-dropdown
                    id="dsd-data-type-select"
                    [options]="queryTypeList"
                    [(selected)]="queryType"
                    (selectedChange)="onQueryTypeChanged()"
                    [readonly]="isReadOnly">
                </app-dropdown>
            </div>
        } @else {
            <div>
                <span class="disabled-label">{{ queryType.text !== 'ALL' ? queryType.text : 'TBD' }}</span>
            </div>
        }
    </div>

    <div class="labeled-dropdown">
        <label>Name</label>

        @if (enabled) {
            <div>
                <app-typeahead
                    [dataSource]="filteredDataSource"
                    [configuration]="{ placeholder: 'Name', displayProperty: 'name' }"
                    (itemSelected)="onDatasetDefinitionSelected($event)"
                    [preSelectedItem]="preSelectedItem"
                    [readonly]="isReadOnly">
                </app-typeahead>
            </div>
        } @else {
            <div>
                <span class="disabled-label">{{ (datasetDefinition && datasetDefinition.name) || 'TBD' }}</span>
            </div>
        }

        @if (showJumpLink) {
            <div class="manage-button-container" [ngClass]="{'readonly': isReadOnly}">
                @if (!isNamedQuerySelected) {
                    <a
                        class="manage-button"
                        [routerLink]="['../../../dataset-definitions/edit', datasetDefinition ? datasetDefinition.id : 'none']"
                        [queryParams]="{from: this.jumpedFrom}"
                    >
                        {{ datasetDefinition ? 'edit dataset' : 'new dataset' }}
                    </a>
                } @else {
                    <a
                        class="manage-button"
                        target="_blank"
                        [href]="routerLink"
                    >
                        edit dataset
                    </a>
                }
            </div>
        }
    </div>
</div>
