import { Inject, NgModule, Optional } from '@angular/core';
import { ApiConfiguration, defaultApiConfiguration, hostAndPortForService, HttpModule } from '@ddv/http';

import {
    NamedQueriesService,
    cfbApiLocationToken,
    trebekApiLocationToken,
    replaceHostInMetadataAndQueryURLsToken,
} from './services/named-queries.service';

@NgModule({
    imports: [HttpModule],
    providers: [
        NamedQueriesService,
        {
            provide: replaceHostInMetadataAndQueryURLsToken,
            useFactory: (c: ApiConfiguration): boolean => c.replaceHostInMetadataAndQueryURLs,
            deps: [[new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: trebekApiLocationToken,
            useFactory: (c: ApiConfiguration): string => {
                const config = c ?? defaultApiConfiguration;
                return `${window.location.protocol}//${hostAndPortForService(config.trebekApiLocation.hostAndPort, config.onPremApiHost)}`;
            },
            deps: [[new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: cfbApiLocationToken,
            useFactory: (c: ApiConfiguration): string => {
                const config = c ?? defaultApiConfiguration;
                return `${window.location.protocol}//${hostAndPortForService(config.cfbApiLocation.hostAndPort, config.onPremApiHost)}`;
            },
            deps: [[new Inject('apiConfig'), new Optional()]],
        },
    ],
    declarations: [],
    exports: [],
})
export class NamedQueriesModule { }
