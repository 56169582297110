import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'ddv-action-icon',
    templateUrl: './action-icon.component.html',
    styleUrls: ['./action-icon.component.scss'],
})
export class ActionIconComponent {
    @Input() iconName = '';
    @Input() actionName = '';
    @Input() displayName = '';

    @Output() actionPressed: EventEmitter<string> = new EventEmitter();

    protected highlight = false;

    @HostListener('click') protected onClick(): void {
        this.actionPressed.emit(this.actionName);
    }

    @HostListener('mouseenter') protected onMouseEnter(): void {
        this.highlight = true;
    }

    @HostListener('mouseleave') protected onMouseLeave(): void {
        this.highlight = false;
    }
}
