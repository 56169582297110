import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Action, isCellLevelEditActionHandler, LegacyAction } from '@ddv/models';
import { removeDuplicates } from '@ddv/utils';

interface IconDescriptor {
    iconName: string;
    actionName: string;
    displayName: string;
}

@Component({
    selector: 'ddv-actions-handler',
    templateUrl: './actions-handler.component.html',
    styleUrls: ['./actions-handler.component.scss'],
})
export class ActionsHandlerComponent implements OnChanges {
    @Input() actions: (Action | LegacyAction)[] | undefined;
    @Input() filterName: string | undefined;
    @Input() filterActive = false;

    @Output() actionPressed: EventEmitter<string> = new EventEmitter();
    @Output() filterActiveChange: EventEmitter<boolean> = new EventEmitter();

    protected iconDescriptors: IconDescriptor[] = [];

    ngOnChanges(): void {
        this.buildActions();
    }

    private buildActions(): void {
        if (!this.actions) {
            return;
        }

        this.actions = this.actions.filter((action: Action | LegacyAction) => {
            let shouldSubmitInBatch = true;
            if (isCellLevelEditActionHandler(action.handler)) {
                shouldSubmitInBatch = action.handler.submitInBatch;
            }

            return isActionWithIcon(action.name) && shouldSubmitInBatch;
        });

        const actionNames = removeDuplicates(this.actions.map((action: Action | LegacyAction) => action.name));
        const iconDescriptors = actionNames.map((an) => actionNameToIconDescriptor(an)) ?? [];
        this.iconDescriptors = iconDescriptors.reduce((acc: IconDescriptor[], iconDescriptor: IconDescriptor) => {
            const hasDuplicateName = acc.some((id: IconDescriptor) => id.displayName === iconDescriptor.displayName);
            if (!hasDuplicateName) {
                acc.push(iconDescriptor);
            }
            return acc;
        }, []);
    }
}

function isActionWithIcon(actionName: string): boolean {
    return actionName === 'release' || actionName.toLowerCase().includes('edit');
}

function actionNameToIconDescriptor(actionName: string): IconDescriptor {
    if (actionName === 'release') {
        return {
            iconName: 'send',
            displayName: actionName,
            actionName,
        };
    } else if (actionName.toLowerCase().includes('edit')) {
        return {
            iconName: 'push',
            displayName: 'Submit Edits',
            actionName,
        };
    }
    console.error('Unknown action name');
    return {
        iconName: '',
        actionName: '',
        displayName: '',
    };
}
