<div class="add-filter pos-rel">
    <div class="disp-flex">

        <ul class="top-menu">
            <li>Filters</li>

            <li>
                <button type="button" class="empty-btn" (click)="clearAllFilters()">Clear All Filters</button>
            </li>
        </ul>

        <div class="dropdown-wrapper search-box" appMousedownOutside (mousedownOutside)="showAddFilters = false">
            <div class="search-box">
                <input
                    readonly
                    type="search"
                    class="formControl"
                    name="addFilter"
                    autocomplete="off"
                    (click)="showAddFilter()"
                    [disabled]="!metadataLoaded"
                    [placeholder]="metadataLoaded ? 'Add Filter' : 'Loading Metadata...'"/>

                <span class="icon-search1"></span>
            </div>

            @if (!noFiltersAvailable && showAddFilters) {
                <div class="dropdown">
                    <div class="filter-attr-wrap">
                        <app-filterable-attribute (selectAttribute)="addNewAttribute($event)"></app-filterable-attribute>
                    </div>

                    <div class="filter-value-wrap">
                        <app-filterable-value
                            [selectedDashboardFilter]="newDashboardFilter"
                            (addFilteredValues)="addNewFilterEntry($event)">
                        </app-filterable-value>
                    </div>
                </div>
            }
    </div>

        @if (!noFiltersAvailable) {
            <ul class="search-menu">
                @for (filter of dashboardFilters; let i = $index; track i) {
                    <li>
                        <div class="disp-table search-table-relative">
                            <div class="disp-table-cell search-table-header">
                                <span class="text-inner">{{ filter.displayName }}</span>
                            </div>

                            <div class="disp-table-cell search-table-cell">
                                @if (!filter.isMasterWidgetFilter) {
                                    <div class="dropdown-wrapper no-rel">
                                    <span class="text-inner">
                                        <span class="icon-arrow-down" (click)="toggleFilterableAttribute($event, i)"></span>
                                    </span>

                                    @if (showFilterableAttribute === i) {
                                        <div
                                            class="dropdown custom-drop attr-dropdown"
                                            appMousedownOutside
                                            (mousedownOutside)="showFilterableAttribute = -1">

                                            <app-filterable-attribute
                                                [selectedDashboardFilter]="filter"
                                                (selectAttribute)="selectedAttribute(i)">
                                            </app-filterable-attribute>
                                        </div>
                                    }
                                    </div>
                                } @else {
                                    <div class="disp-table-cell is-master">
                                        <span class="icon-filteroutline"></span>

                                        <label>M</label>
                                    </div>
                                }
                            </div>

                            <div class="disp-table-cell text-center search-table-select">
                                <span class="text-inner">{{ filter.criteria?.toLowerCase() }}</span>
                            </div>

                            @if (filter.values) {
                                <div class="disp-table-cell">
                                    @if (filter.values.length !== 0 && filter.values.length < 5) {
                                        <div>
                                            @for (value of filter.values; let valueIndex = $index; track valueIndex) {
                                                <div class="tag-btn">{{ value || 'Blanks' }}
                                                    @if (!filter.isMasterWidgetFilter) {
                                                        <span class="icon-close1" (click)="removeValue(i, valueIndex)"></span>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                    @if (filter.values.length >= 5 || filter.values.length === 0) {
                                        <div>
                                            <span class="tag-btn tag-all-btn">
                                                {{ filter.values.length }} of {{ totalValueCount[filter.name!] }} Selected
                                            </span>
                                        </div>
                                    }
                                </div>
                            }

                            @if (!filter.isMasterWidgetFilter) {
                                <div class="disp-table-cell search-table-cell">
                                    <div class="dropdown-wrapper no-rel">
                                        <span class="text-inner">
                                            <span class="icon-arrow-down" (click)="toggleFilterableValue($event, i);"></span>
                                        </span>

                                    @if (showFilterableValue === i) {
                                        <div
                                            class="dropdown right-position custom-drop value-dropdown"
                                            appMousedownOutside
                                            (mousedownOutside)="showFilterableValue = -1">

                                            <app-filterable-value
                                                [selectedDashboardFilter]="filter"
                                                (addFilteredValues)="addFilterValues($event, i)">
                                            </app-filterable-value>
                                        </div>
                                    }
                                    </div>
                                </div>
                            }

                            @if (!filter.isMasterWidgetFilter) {
                                <div class="disp-table-cell search-table-cell">
                                    <span class="text-inner">
                                        <span class="icon-close1" (click)="deleteFilterEntry(i)"></span>
                                    </span>
                                </div>
                            }
                        </div>
                    </li>
                }
            </ul>
            <div class="save-cancel">
                <button class="empty-btn" type="button" (click)="onCancelFilters()">Cancel</button>

                <button type="submit" class="fill-btn" (click)="onApplyFilters()">Apply</button>
            </div>
        } @else {
            <div class="no-data error">
                <span>NO FILTERS AVAILABLE</span>
            </div>
        }
    </div>
</div>
