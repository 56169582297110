import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CurrentStateService, DirtyFlagService } from '@ddv/behaviors';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { DashboardGroup, DashboardService, DdvWidgetService } from '@ddv/dashboards';
import { CompareModeService } from '@ddv/datasets';
import { UserService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import { Workspace, ManagerService } from '@ddv/layout';
import {
    AppWidgetState,
    Client,
    CompareMode,
    DashboardFilter,
    DashboardModel,
    DashboardPreference,
    DashboardSnapshot,
    DatasetDefinitionDetails,
    ExportInfo,
    FilterQueryParam,
    Fund,
    isInvestorQueryType,
    Mode,
    MODE,
    QueryPeriodTypeName,
    QueryTypeName,
    UserPreferences,
    WIDGET_LIFECYCLE_EVENT,
} from '@ddv/models';
import { ClientsService, FundsService, FuzzyDatesService, FuzzyDatesServiceV2 } from '@ddv/reference-data';
import { deepCompare, deepClone } from '@ddv/utils';
import { FuzzyDate } from '@hs/ui-core-date';
import { combineLatest } from 'rxjs';

// this component is NOT a dashboard at all
// its just the "top bar" of the dashboard that includes the name, the query params, etc
@Component({
    selector: 'app-hs-dashboard',
    templateUrl: './hs-dashboard.component.html',
    styleUrls: ['./hs-dashboard.component.scss'],
})
export class HsDashboardComponent extends MultiSubscriptionComponent implements OnChanges, OnInit, OnDestroy {
    @Input() currentDashboard: Workspace | undefined;
    @Input() inPresentationMode = false;
    @Input() dashboardGroups: DashboardGroup[] | undefined;
    @Input() dashboardSnapshots: DashboardSnapshot[] | undefined;
    @Input() currentActiveDate: string | null | undefined;

    @Output() cancelEdit = new EventEmitter();
    @Output() revertChanges = new EventEmitter();
    @Output() saveDashboardWidgets = new EventEmitter<DashboardPreference>();
    @Output() exportOptionsClose = new EventEmitter<ExportInfo>();
    @Output() dashboardSwitch = new EventEmitter<string>();

    isLastDashboardClosed = false;

    protected queryParams: DashboardPreference | undefined;
    protected showHighlight = true;
    protected showSettings = false;
    protected clientCode = '';
    protected isMultiClient = false;
    protected userSelectedDate: { dateFrom: string, dateTo: string } | undefined;
    protected isHIDataAvailable = false;
    protected isQueryTypeRecon = false;

    private fuzzyDates: FuzzyDate[] | undefined;
    private fundOptions: Fund[] = [];
    private clientOptions: Client[] = [];
    private userPreference: UserPreferences | undefined;
    private dashboardParamsChangedOnView = false;
    private detailWidgetId: number | undefined;

    constructor(
        private readonly currentStateService: CurrentStateService,
        private readonly dashboardService: DashboardService,
        private readonly dirtyFlagService: DirtyFlagService,
        private readonly fundService: FundsService,
        private readonly clientsService: ClientsService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        private readonly fuzzyDatesServiceV2: FuzzyDatesServiceV2,
        private readonly manager: ManagerService,
        private readonly userService: UserService,
        private readonly queryParamsService: QueryParamsService,
        private readonly compareModeService: CompareModeService,
        private readonly ddvWidgetService: DdvWidgetService,
    ) {
        super();
    }

    private static isViewAndSavedQueryParamsEqual(viewPreference?: DashboardPreference, savedPreference?: DashboardPreference): boolean {
        return (!!viewPreference &&
            !!savedPreference &&
            viewPreference.startDate === savedPreference.startDate &&
            viewPreference.endDate === savedPreference.endDate &&
            viewPreference.activeDate === savedPreference.activeDate &&
            deepCompare(viewPreference.filters, savedPreference.filters) &&
            deepCompare(viewPreference.funds, savedPreference.funds)) &&
            deepCompare(viewPreference.clients, savedPreference.clients);
    }

    ngOnInit(): void {
        this.subscribeTo(this.userService.userPreferences$, (userPref: UserPreferences) => {
            this.userPreference = userPref;
            this.userSelectedDate = { dateFrom: this.userPreference.startDate ?? '', dateTo: this.userPreference.endDate ?? '' };
        });

        this.subscribeTo(this.queryParamsService.dashboardQueryParams, (dashboardPref: DashboardPreference) => {
            this.queryParams = dashboardPref;
        });

        this.subscribeTo(combineLatest([this.currentStateService.clientCode$, this.currentStateService.isMultiClient$]),
            ([clientCode, isMultiClient]) => {
                this.clientCode = clientCode;
                this.isMultiClient = isMultiClient;

                if (!this.isMultiClient) {
                    this.subscribeTo(this.fundService.funds(), (data: Fund[]) => {
                        if (data) {
                            this.fundOptions = data;
                        }
                    });
                } else {
                    this.subscribeTo(this.clientsService.clients(), (data: string[]) => {
                        if (data) {
                            this.clientOptions = data.map((el) => ({ clientId: el, clientName: el }));
                        }
                    });
                }
            });

        this.subscribeTo(this.ddvWidgetService.detailWidgetId, (detailWidgetId) => {
            this.detailWidgetId = detailWidgetId;
        });

        this.subscribeTo(this.fuzzyDatesServiceV2.getFuzzyDatesCC(), (fuzzyDates) => {
            this.fuzzyDates = fuzzyDates;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.currentDashboard || this.dirtyFlagService.isDirty) {
            return;
        }

        if (changes.currentDashboard) {
            this.onCurrentDashboardChanges();
        }

        if (changes.inPresentationMode) {
            this.onModeChanges();
        }

        this.setIsHIDataAvailable();
        this.setIsQueryTypeRecon();
    }

    setHighlightVisibility(visible: boolean): void {
        this.showHighlight = visible;
    }

    protected onUpdateDashboardQueryParams(preference: DashboardPreference): void {
        preference.areFiltersAppliedByMaster = false;
        this.queryParamsService.dispatchUpdatedQueryParams(preference);
        if (!this.inPresentationMode) {
            this.activateDirtyFlagOnParams();
        } else {
            this.dashboardParamsChangedOnView = true;
        }
    }

    protected onApplySelectedClients(clients: Client[]): void {
        this.queryParamsService.dispatchUpdatedQueryParams({
            clients,
            comparing: this.queryParams?.comparing ? CompareMode.BOTH : undefined,
        });
        if (!this.inPresentationMode) {
            this.activateDirtyFlagOnParams();
        } else {
            this.dashboardParamsChangedOnView = true;
        }
    }

    protected onClearHighlight(): void {
        this.manager.sendMessageToAllWidgetsOnWorkspace(0, {
            action: 'VISUALIZATION_SELECTED',
            dataSource: [],
            selectedItem: null,
        });
    }

    protected onCloseDashboardExport(exportInfo: ExportInfo): void {
        this.exportOptionsClose.emit(exportInfo);
    }

    protected onRefreshCurrentDashboard(): void {
        this.queryParamsService.dispatchNewQueryParams({ ...this.queryParams, isPreferenceChangedOnRefresh: true });
    }

    protected onRestoreCurrentDashboard(): void {
        this.onClearHighlight();
        this.populateDashboardQueryParams(MODE.EDIT_WORKSPACE);

        if (this.isMultiClient && this.detailWidgetId) {
            this.ddvWidgetService.restoreOriginalViewFilters();
        }

        this.compareModeService.disableCompareMode();

        this.manager.sendMessageToAllWidgetsOnWorkspace(0, {
            action: WIDGET_LIFECYCLE_EVENT.VIEW_RESTORED,
        });

        this.dirtyFlagService.exitDirtyState();
    }

    protected onApplySelectedFunds(funds: Fund[]): void {
        this.queryParamsService.dispatchUpdatedQueryParams({
            funds,
            comparing: this.queryParams?.comparing ? CompareMode.BOTH : undefined,
            areFiltersAppliedByMaster: false,
        });
        if (!this.inPresentationMode) {
            this.activateDirtyFlagOnParams();
        } else {
            this.dashboardParamsChangedOnView = true;
        }

        this.updateFuzzyDatesForInvestorDataset(funds);
    }

    protected onApplyDateChanges(preference: FilterQueryParam): void {
        preference.comparing = this.queryParams?.comparing ? CompareMode.ORIGINAL : undefined;
        if (!preference.initialLoad) {
            this.onUpdateDashboardQueryParams(preference);
        }
    }

    protected onDashboardFiltersApplied(dashboardFilters: DashboardFilter[]): void {
        this.onUpdateDashboardQueryParams({
            filters: dashboardFilters,
        });
    }

    private setIsQueryTypeRecon(): void {
        this.isQueryTypeRecon = !!this.currentDashboard?.extraParameters?.widgets.some((w) =>
            w.datasetDefinition?.queryType?.name === QueryTypeName.RECON);
    }

    private setIsHIDataAvailable(): void {
        this.isHIDataAvailable = !!(this.currentDashboard?.extraParameters?.widgets.some((w) => {
            const queryTypeName: string | undefined = w.datasetDefinition?.queryType?.name;
            if (!queryTypeName) {
                return false;
            }

            return queryTypeName === QueryTypeName.INVESTORS_ACTIVITY ||
                queryTypeName === QueryTypeName.INVESTORS_ASSETS ||
                queryTypeName === QueryTypeName.INVESTORS_PERFORMANCE ||
                queryTypeName === QueryTypeName.ECDI;
        }) && this.fundOptions.some((fund) => fund.availability === 'hi' || fund.availability === 'both'));
    }

    private activateDirtyFlagOnParams(): void {
        if (this.isQueryParamChanged(MODE.EDIT_WORKSPACE)) {
            this.dirtyFlagService.enterDirtyState(this.currentDashboard?.id ?? '');
        }
    }

    private isQueryParamChanged(mode: Mode): boolean {
        if (!this.manager.getWorkspace()) {
            return false;
        }

        const dashboardModel: DashboardModel = this.manager.getWorkspace()?.getExtraParameters();
        let storedLastPref = dashboardModel.dashboardPreferences.find((preference) => {
            return preference.dashboardPreferenceMode === mode;
        });

        if (!storedLastPref) {
            storedLastPref = { funds: [], filters: [], clients: [] };
        }

        return !HsDashboardComponent.isViewAndSavedQueryParamsEqual(this.queryParams, storedLastPref);
    }

    private onCurrentDashboardChanges(): void {
        this.populateDashboardQueryParams(MODE.EDIT_WORKSPACE);
    }

    private onModeChanges(): void {
        if (!this.inPresentationMode && this.dashboardParamsChangedOnView) {
            this.queryParamsService.dispatchUpdatedQueryParams({ dashboardPreferenceMode: MODE.EDIT_WORKSPACE });
            this.activateDirtyFlagOnParams();
            this.dashboardParamsChangedOnView = false;
        } else {
            const detailWidgetState = this.manager.getWidgetState(this.detailWidgetId ?? 0);
            if (!detailWidgetState) {
                this.populateDashboardQueryParams();
                if (this.queryParams?.filters?.every((filter) => !filter.isMasterWidgetFilter)) {
                    this.manager.updateWidgetsOnMasterFiltersRemoved();
                }
            }
        }
    }

    private populateDashboardQueryParams(mode?: Mode): void {
        const dashboard = this.manager.getWorkspace();
        if (dashboard) {
            const extraParams = dashboard.getExtraParameters();
            const preferenceMode = mode ?? this.getPreferenceMode(extraParams);
            const params = this.setDashboardQueryParamsPriority(extraParams, preferenceMode);

            params.filters?.forEach((filter) => {
                if (filter.values?.some((value) => this.findFuzzyDateByName(value))) {
                    this.processFilterValues(filter);
                }
            });
            this.queryParamsService.dispatchNewQueryParams(params);
        }
    }

    private setDashboardQueryParamsPriority(extraParams: DashboardModel, mode?: Mode): DashboardPreference {
        const preferenceMode: Mode = this.inPresentationMode ? MODE.VIEW : MODE.EDIT_WORKSPACE;
        let dashbPref: DashboardPreference = { funds: [], filters: [], dashboardPreferenceMode: preferenceMode, clients: [] };
        if (!this.inPresentationMode && extraParams?.dashboardPreferences) {
            const dashboardPref = extraParams.dashboardPreferences.find((preference) =>
                preference.dashboardPreferenceMode === MODE.EDIT_WORKSPACE);
            dashbPref = deepClone(dashboardPref) ?? dashbPref;
            dashbPref.funds = dashbPref.funds ?? [];
            dashbPref.clients = dashbPref.clients ?? [];
            dashbPref.acknowledged = !!this.userPreference?.showAcknowledged;
            return dashbPref;
        }

        const newMode = mode ?? MODE.VIEW;
        const workspaceParams = this.manager.getExtraParametersForWorkspace();
        dashbPref = this.dashboardService.getDefaultQueryParams(
            this.fundOptions,
            this.clientOptions,
            workspaceParams);
        const widgetDatasets: DatasetDefinitionDetails[] = workspaceParams?.widgets.map((w: AppWidgetState) => w.datasetDefinition) || [];
        const isCalendarVisible = !widgetDatasets.length ||
            widgetDatasets.some((dsd) => dsd.queryPeriodType?.name !== QueryPeriodTypeName.NO_CALENDAR);
        this.overridePrefWithUserPref(dashbPref);
        this.overridePrefWithSavedParams(dashbPref, extraParams, newMode);
        dashbPref.activeDate = dashbPref.activeDate === undefined ? dashbPref.endDate : dashbPref.activeDate;
        dashbPref.dashboardPreferenceMode = preferenceMode;
        if (!isCalendarVisible) {
            dashbPref.startDate = '';
            dashbPref.endDate = '';
        }
        dashbPref.acknowledged = !!this.userPreference?.showAcknowledged;
        return dashbPref;
    }

    private overridePrefWithUserPref(pref: DashboardPreference): void {
        if (!this.userPreference) {
            return;
        }

        if (this.userPreference.fundsCodes?.length) {
            pref.funds = this.fundOptions.filter((f) => this.userPreference?.fundsCodes.includes(f.fundId));
        }
        if (this.userPreference.clientsCodes?.length) {
            pref.clients = this.clientOptions.filter((c) => this.userPreference?.clientsCodes.includes(c.clientId));
        }
        pref.startDate = this.userPreference.startDate || pref.startDate;
        pref.endDate = this.userPreference.endDate || pref.endDate;
    }

    private overridePrefWithSavedParams(dashboardPref: DashboardPreference, extraParams: DashboardModel, mode: Mode): void {
        if (extraParams?.dashboardPreferences?.length) {
            const savedPref = extraParams.dashboardPreferences.find((preference) => preference.dashboardPreferenceMode === mode);
            if (savedPref) {
                if (savedPref.funds?.length) {
                    dashboardPref.funds = savedPref.funds;
                }
                if (savedPref.clients?.length) {
                    dashboardPref.clients = savedPref.clients;
                }
                dashboardPref.startDate = savedPref.startDate || dashboardPref.startDate;
                dashboardPref.endDate = savedPref.endDate || dashboardPref.endDate;
                dashboardPref.activeDate = savedPref.activeDate;
                dashboardPref.filters = savedPref.filters;
                dashboardPref.highlight = savedPref.highlight;
                dashboardPref.version = extraParams?.getQueryParamVersion?.(); // undefined in tests
            }
        }
    }

    private getPreferenceMode(extraParams: DashboardModel): Mode {
        const hasViewMode = extraParams.dashboardPreferences.some((preference) =>
            preference.dashboardPreferenceMode === MODE.VIEW,
        );
        return hasViewMode ? MODE.VIEW : MODE.EDIT_WORKSPACE;
    }

    private updateFuzzyDatesForInvestorDataset(funds: Fund[]): void {
        const widgetsOnBoard = this.currentDashboard?.extraParameters?.widgets ?? [];
        if (widgetsOnBoard.length && this.areAllWidgetsInvestor(widgetsOnBoard)) {
            const updatedFunds = funds.map((fund) => ({ code: fund.fundId }));
            const queriesIds = this.getQueriesIds(widgetsOnBoard);
            this.fuzzyDatesService.pushMostRecentFuzzyDatesForInvestorDataset(updatedFunds, queriesIds).subscribe();
        }
    }

    private findFuzzyDateByName(name: string | number | boolean): FuzzyDate | undefined {
        return this.fuzzyDates?.find((fuzzyDate) => fuzzyDate.name === name.toString());
    }

    private processFilterValues(filter: DashboardFilter): void {
        const fuzzies: string[] = [];
        const values: string[] = [];

        filter.values?.forEach((value, index) => {
            const fuzzyDate = this.findFuzzyDateByName(value);
            fuzzies[index] = fuzzyDate?.name ?? '';
            values[index] = fuzzyDate?.commonDate.toDashFormat() ?? value.toString();
        });
        filter.fuzzies = fuzzies;
        filter.values = values;
    }

    private areAllWidgetsInvestor(widgets: AppWidgetState[]): boolean {
        const widgetsWithDSDs = this.getAllWidgetsWithDSDs(widgets);
        const widgetsWithNamedQueries = this.getAllWidgetsWithNamedQueries(widgets);
        return widgetsWithDSDs.every((w) => w.datasetDefinition?.dataType === 'Investor') &&
            widgetsWithNamedQueries.every((w) => isInvestorQueryType(w.datasetDefinition?.queryType?.name));
    }

    private getAllWidgetsWithDSDs(widgets: AppWidgetState[]): AppWidgetState[] {
        return widgets.filter((w) => w.datasetDefinition?.id !== -1);
    }

    private getAllWidgetsWithNamedQueries(widgets: AppWidgetState[]): AppWidgetState[] {
        return widgets.filter((w) => w.namedQueryId);
    }

    private getQueriesIds(widgets: AppWidgetState[]): (number | string)[] {
        return [
            ...this.getAllWidgetsWithDSDs(widgets).map((widget) => widget.datasetDefinition?.id ?? 0),
            ...this.getAllWidgetsWithNamedQueries(widgets).map((widget) => widget.namedQueryId ?? ''),
        ];
    }
}
