<section class="configure-notes-container">
    <form>
        <header>
            <h2>Configure Text Message</h2>
        </header>
    
        <main>
            <div class="row">
                <div>
                    <cc-single-select-dropdown
                        [label]="'Button Label'"
                        [size]="'fit-to-parent'"
                        [options]="buttonLabelOptions"
                        [(selected)]="buttonLabel"
                    >
                    </cc-single-select-dropdown>
                </div>
                <div>
                    <cc-text-input
                        [label]="'Title'"
                        [size]="'fit-to-parent'"
                        [disallowSpecialCharacters]="true"
                        [(value)]="title"
                    >
                    </cc-text-input>
                </div>
            </div>
            <div class="row">
                <cc-textarea
                    [label]="'Message'"
                    [size]="'fit-to-parent'"
                    [rows]="15"
                    [(value)]="message"
                >
                </cc-textarea>
            </div>
        </main>
    
        <footer>
            @if (editConfiguration) {
                <div class="delete-note-container">
                    <div class="delete-note" (click)="onDelete()">
                        <cc-icon
                            [icon]="'close-circle'"
                            [size]="'medium'"
                            [color]="'var(--delete-note)'"
                            [hoverColor]="'var(--delete-note)'"
                            (click)="onDelete()"
                        >
                        </cc-icon>
                        <span>Delete Note</span>
                    </div>
                </div>
            }
            <cc-button [type]="'naked'" (pressed)="onCancel()">Cancel</cc-button>
            <cc-button [type]="'solid'" [disabled]="!buttonLabel || !title || !message" (pressed)="onSave()">Save</cc-button>
        </footer>
    </form>
</section>
