import { Injectable } from '@angular/core';
import { DdvDate, FilterQueryParam, FuzzyDates, QueryParams } from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { deepCompare, getUTCDate } from '@ddv/utils';

import { DefaultQueryParamsService } from './default-query-params.service';

@Injectable()
export class DatasetQueryParamsDifferService {
    private defaultQueryParams: QueryParams = {};
    private fuzzyDates: FuzzyDates = new FuzzyDates();

    constructor(defaultQueryParamsService: DefaultQueryParamsService, fuzzyDatesService: FuzzyDatesService) {
        defaultQueryParamsService.defaultQueryParams$
            .subscribe({
                next: (defaultQueryParams) => this.defaultQueryParams = defaultQueryParams,
            });

        fuzzyDatesService.fuzzyDates()
            .subscribe({
                next: (fuzzyDates) => this.fuzzyDates = fuzzyDates,
            });
    }

    areQueryParamsEqual(oldParams: FilterQueryParam | undefined, newParams: FilterQueryParam | undefined): boolean {
        if (deepCompare(oldParams, newParams)) { // completely the same
            return true;
        }

        if (!deepCompare(copyParamsWithoutDates(oldParams), copyParamsWithoutDates(newParams))) { // there is a change in the non-date filters
            return false;
        }

        const oldStartDate = datePropertyAsDate('startDate', oldParams, this.fuzzyDates, this.defaultQueryParams);
        const oldEndDate = datePropertyAsDate('endDate', oldParams, this.fuzzyDates, this.defaultQueryParams);
        const newStartDate = datePropertyAsDate('startDate', newParams, this.fuzzyDates, this.defaultQueryParams);
        const newEndDate = datePropertyAsDate('endDate', newParams, this.fuzzyDates, this.defaultQueryParams);

        if (!oldStartDate || !oldEndDate || !newStartDate || !newEndDate) {
            return false;
        }

        return newStartDate.getTime() === oldStartDate.getTime() && newEndDate.getTime() === oldEndDate.getTime();
    }
}

// exporting this function makes testing much easier
// otherwise we need a LOT of scenarios to test the different paths
export function datePropertyAsDate(
    which: 'startDate' | 'endDate',
    params: FilterQueryParam | undefined,
    fuzzyDates: FuzzyDates,
    defaultQueryParams: QueryParams | undefined,
): Date | undefined {
    const paramsStartDate = params?.[which] ?? defaultQueryParams?.[which];
    if (!paramsStartDate) {
        return;
    }

    if (paramsStartDate && DdvDate.isStringValidDate(paramsStartDate)) {
        return getUTCDate(paramsStartDate);
    }

    const fuzzyDate = fuzzyDates[which === 'startDate' ? 'findFromDate' : 'findToDate'](paramsStartDate);
    return fuzzyDate?.actualDate;
}

function copyParamsWithoutDates(params: FilterQueryParam | undefined): FilterQueryParam {
    return {
        funds: params?.funds,
        isComparing: params?.isComparing ? params?.isComparing : undefined,
        comparing: params?.isComparing ? params?.comparing : undefined, // we don't care about that if we aren't comparing
        compareDates: params?.compareDates,
        isPreferenceChangedOnRefresh: params?.isPreferenceChangedOnRefresh,
        clients: params?.clients,
        acknowledged: params?.acknowledged,
        includeManuallyReleased: params?.includeManuallyReleased, // only relevant for net-settlement breaks
    };
}
