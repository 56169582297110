import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { DirtyFlagService } from '@ddv/behaviors';
import { ModalDialogService, MultiSubscriptionComponent } from '@ddv/common-components';
import { DashboardFiltersComponent } from '@ddv/dashboards';
import { Entitlements, UserEntitlements, UserEntitlementService } from '@ddv/entitlements';
import { Workspace, LayoutService, ManagerService } from '@ddv/layout';
import { ExportInfo, DeviceMode } from '@ddv/models';
import { deviceModeIsTablet } from '@ddv/utils';

@Component({
    selector: 'app-hs-dashboard-header-menu',
    templateUrl: 'hs-dashboard-header-menu.component.html',
    styleUrls: ['./hs-dashboard-header-menu.component.scss'],
})
export class HSDashboardHeaderMenuComponent extends MultiSubscriptionComponent implements OnInit, OnDestroy {
    @Input() inPresentationMode = false;
    @Input() currentDashboard: Workspace | undefined;
    @Input() isMultiClient = false;

    @Output() closeDashboardExportMenu = new EventEmitter<ExportInfo>();
    @Output() restoreClicked = new EventEmitter();
    @Output() refreshClicked = new EventEmitter();
    @Output() queryParamApply = new EventEmitter();

    showExportOptions = false;
    isTabletMode = false;
    userEntitlements: UserEntitlements | undefined;
    userCanExport = false;

    constructor(
        private readonly layoutService: LayoutService,
        private readonly dirtyFlagService: DirtyFlagService,
        private readonly modalService: ModalDialogService,
        private readonly manager: ManagerService,
        private readonly userEntitlementsService: UserEntitlementService,
    ) {
        super();

        this.isTabletMode = deviceModeIsTablet();
    }

    ngOnInit(): void {
        this.subscribeTo(this.userEntitlementsService.entitlementsForClientCode$, (userEntitlements) => {
            this.userEntitlements = userEntitlements;
            this.userCanExport = this.userEntitlements.hasPermission(Entitlements.EXPORT);
        });
    }

    toggleExportOptions(): void {
        if (this.userCanExport) {
            this.showExportOptions = !this.showExportOptions;
        }
    }

    closeDashboardExport(exportInfo: ExportInfo): void {
        this.showExportOptions = false;
        this.closeDashboardExportMenu.emit(exportInfo);
    }

    toggleMode(deviceMode: DeviceMode): void {
        if (this.currentDashboard && this.currentDashboard?.getExtraParameters().deviceMode !== deviceMode) {
            this.currentDashboard.getExtraParameters().deviceMode = deviceMode;
            this.dirtyFlagService.enterDirtyState(this.currentDashboard.id);
            this.layoutService.toggleTabletMode(deviceMode);
        }
    }

    openDashboardFilter(): void {
        const dashboardFilterRef = this.modalService.open(DashboardFiltersComponent, { windowClass: 'filter-popup' });
        dashboardFilterRef.componentInstance.showHighlight = true;
        dashboardFilterRef.componentInstance.filterTitle = 'Dashboard Filters';
        dashboardFilterRef.componentInstance.isMultiClient = this.isMultiClient;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dashboardFilterRef.componentInstance.fundsApply.subscribe((funds: any) => {
            this.queryParamApply.emit({ funds });
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dashboardFilterRef.componentInstance.clientsApply.subscribe((clients: any) => {
            this.queryParamApply.emit({ clients });
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dashboardFilterRef.componentInstance.dateFilterApply.subscribe((dates: any) => {
            this.queryParamApply.emit(dates);
        });
        dashboardFilterRef.componentInstance.restoreBtnClicked.subscribe(() => {
            this.restoreClicked.emit();
        });
        dashboardFilterRef.componentInstance.refreshBtnClicked.subscribe(() => {
            this.refreshClicked.emit();
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dashboardFilterRef.componentInstance.filterApply.subscribe((dashboardFilters: any) => {
            this.queryParamApply.emit({ filters: dashboardFilters });
        });
        dashboardFilterRef.componentInstance.dashboardExport.subscribe(() => {
            dashboardFilterRef.close();
        });
        dashboardFilterRef.componentInstance.clearHighlight.subscribe(() => {
            this.manager.sendMessageToAllWidgetsOnWorkspace(0, {
                action: 'VISUALIZATION_SELECTED',
                dataSource: [],
                selectedItem: null,
            });
        });
    }
}
