<div class="add-datasets">
    <div class="search-box">
        <input
            type="search"
            class="formControl"
            name="searchAttributeFilter"
            autocomplete="off"
            [(ngModel)]="searchAttributeFilter"/>

        <span class="icon-search1"></span>
    </div>

    <ul class="dataset-list">
        @for (attribute of metadataList | textValueFilter: searchAttributeFilter; track $index) {
            <li>
                <div
                    class="empty-btn"
                    (click)="onSelectAttribute(attribute)"
                    [ngClass]="{'selected': (selectedDashboardFilter?.name === attribute.value)}"
                    innerHTML="{{ attribute.text | highlight : searchAttributeFilter }}">
                </div>
            </li>
        }
    </ul>
</div>
