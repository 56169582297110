import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BehaviorsModule } from '@ddv/behaviors';
import { CommonComponentsModule } from '@ddv/common-components';
import { DashboardsModule } from '@ddv/dashboards';
import { EntitlementsModule } from '@ddv/entitlements';
import { HttpModule } from '@ddv/http';
import { LayoutModule } from '@ddv/layout';
import { SocketModule } from '@ddv/socket';
import { LibSvgIconsModule } from '@ddv/svg-icons';
import { WidgetsModule } from '@ddv/widgets';
import { AdditionalFiltersComponent } from '@hs/ui-core-additional-filters';
import { SingleSelectDropdownComponent } from '@hs/ui-core-dropdowns';
import { ButtonComponent, CheckboxComponent, OptionToggleComponent, TextareaComponent, TextInputComponent } from '@hs/ui-core-inputs';
import { ModalDirective } from '@hs/ui-core-layout';
import { IconComponent } from '@hs/ui-core-presentation';

import { ConfigureDashboardNotesComponent } from './components/configure-dashboard-notes/configure-dashboard-notes.component';
import { DashboardBarComponent } from './components/dashboard-bar/dashboard-bar.component';
import { DashboardExportComponent } from './components/dashboard-export/dashboard-export.component';
import { DashboardFinderComponent } from './components/dashboard-finder-dialog/dashboard-finder.component';
import { DashboardFamilyFilter } from './components/dashboard-finder-dialog/db-family-dialog/dashboard-family.pipe';
import {
    DashboardFamilyDialogComponent,
} from './components/dashboard-finder-dialog/db-family-dialog/db-family-dialog.component';
import { DashboardNavComponent } from './components/dashboard-nav/dashboard-nav.component';
import { FindViewIconComponent } from './components/dashboard-nav/find-view-icon/find-view-icon.component';
import { FindWidgetIconComponent } from './components/dashboard-nav/find-widget-icon/find-widget-icon.component';
import { DashboardNotesComponent } from './components/dashboard-notes/dashboard-notes.component';
import { FiltersBarComponent } from './components/filters-bar/filters-bar.component';
import {
    HSDashboardHeaderMenuComponent,
} from './components/hs-dashboard/hs-dashboard-header-menu/hs-dashboard-header-menu.component';
import {
    HSDashboardHeaderComponent,
} from './components/hs-dashboard/hs-dashboard-header/hs-dashboard-header.component';
import { HsDashboardComponent } from './components/hs-dashboard/hs-dashboard.component';
import { ViewEditDashboardComponent } from './components/view-edit-dashboard/view-edit-dashboard.component';
import { WidgetFilterComponent } from './components/widget-filter/widget-filter.component';
import { CanDeactivateViewEditGuard } from './services/can-deactivate-guard.service';
import { DashboardNavService } from './services/dashboard-nav.service';
import { DashboardNotesService } from './services/dashboard-notes.service';
import { DashboardPDFExportService } from './services/dashboard-pdf-export';
import { DdvScreenDataCaptureService } from './services/ddv-screendata-capture.service';
import { PDFExportService } from './services/pdf-export.service';
import { RevertDashboardService } from './services/revert-dashboard.service';

@NgModule({
    imports: [
        BehaviorsModule,
        CommonComponentsModule,
        CommonModule,
        DashboardsModule,
        DragDropModule,
        EntitlementsModule,
        FormsModule,
        HttpModule,
        LayoutModule,
        ReactiveFormsModule,
        RouterModule,
        SocketModule,
        WidgetsModule,
        LibSvgIconsModule,
        AdditionalFiltersComponent,
        ButtonComponent,
        CheckboxComponent,
        IconComponent,
        OptionToggleComponent,
        SingleSelectDropdownComponent,
        TextareaComponent,
        TextInputComponent,
        ModalDirective,
    ],
    providers: [
        CanDeactivateViewEditGuard,
        DashboardNavService,
        DashboardPDFExportService,
        DdvScreenDataCaptureService,
        PDFExportService,
        RevertDashboardService,
        DashboardNotesService,
    ],
    declarations: [
        ConfigureDashboardNotesComponent,
        DashboardBarComponent,
        DashboardExportComponent,
        DashboardFamilyDialogComponent,
        DashboardFamilyFilter,
        DashboardFinderComponent,
        DashboardNavComponent,
        DashboardNotesComponent,
        FiltersBarComponent,
        FindViewIconComponent,
        FindWidgetIconComponent,
        HsDashboardComponent,
        HSDashboardHeaderComponent,
        HSDashboardHeaderMenuComponent,
        ViewEditDashboardComponent,
        WidgetFilterComponent,
    ],
    exports: [
        ViewEditDashboardComponent,
    ],
})
export class ViewsModule {}
