<div class="dashboard-finder">
    <div class="dashboard-finder-header">
        <div class="search-box">
            <span class="hs-icon-search-right"></span>

            <input
                appInputRestrict
                appAutoFocus
                id="searchParam"
                type="search"
                placeholder="Find a View"
                name="searchParam"
                allowPattern="^[\w\s\/\-%&^#@()<>]+$"
                autocomplete="off"
                [(ngModel)]="searchParam"
                (ngModelChange)="filterSearchResults()"
                [focus]="!isLoading" />
        </div>
    </div>

    <div class="dashboard-finder-subheader">
        <div class="view-toggle toggle">
            <cc-option-toggle
                id="view-toggle"
                [(value)]="showSelectedViews"
                [labels]="['Selected', 'All']"
                (valueChange)="toggleSelectedViews($event)"
            >
            </cc-option-toggle>
        </div>

        @if (canManageViewGroups) {
            <div class="btn-container">
                <button class="empty-btn cancel-btn" (click)="onCancel()">Cancel</button>

                <button class="ghost-btn apply-btn" [disabled]="!isApplyButtonEnabled" (click)="onApply()">Apply</button>
            </div>
        }
    </div>

    @if (filteredDashboardGroupsList.length) {
        <div class="search-div-scroll" [attr.filtered]="isFiltered">
            @for (dashboardGroup of filteredDashboardGroupsList; track $index) {
                <div class="family">
                    <div class="family-header">
                        @if (dashboardGroup.name) {
                            <cc-icon
                                [icon]="'check-circle'"
                                (iconClicked)="toggleGroupDefault(dashboardGroup)"
                                [title]="dashboardGroup.isDefault ? 'Remove from Navigation' : 'Add to Navigation'"
                                [color]="dashboardGroup.isDefault ? 'var(--finder-tick-fill-check)' : ''"
                            />
                        }

                        <span class="family-title">{{ dashboardGroup.name ? dashboardGroup.name : 'All Other Views' }}</span>

                        <span class="views-number">
                            {{ dashboardGroup.dashboards.length === 1 ? '1 View' : dashboardGroup.dashboards.length + ' Views' }}
                        </span>
                    </div>

                    @for (dashboard of dashboardGroup.dashboards; track $index) {
                        <div [id]="'dashboard-search-result-' + dashboard.id" class="view">
                            <div class="view-title" (click)="toggleDefault(dashboard, dashboardGroup)">
                                <cc-icon
                                    [icon]="'check-circle'"
                                    (iconClicked)="toggleDefault(dashboard, dashboardGroup)"
                                    [title]="dashboard.isDefault ? 'Remove from Navigation' : 'Add to Navigation'"
                                    [color]="dashboard.isDefault ? 'var(--finder-tick-fill-check)' : ''"
                                />

                                <h2 [innerHTML]="dashboard.name | highlight : searchParam"></h2>

                                <h4>({{ dashboard.abbreviation }})</h4>
                            </div>

                            @if (dashboard.isDefault && canManageViewGroups) {
                                <div class="family-picker" appMousedownOutside (mousedownOutside)="showFamilyToggle[dashboard?.id ?? ''] = false">
                                    <div
                                        class="familydiv-right"
                                        [ngClass]="['view-' + dashboard?.id?.toString()]"
                                        (click)="toggleFamilyModal(dashboard?.id)"
                                    >
                                        <span>Family: </span>

                                        <span class="family-name">{{ dashboardGroup.name ? dashboardGroup.name : 'None' }}</span>

                                        <cc-icon
                                            [icon]="'chevron-down2'"
                                            [title]="'More Options'"
                                            (iconClicked)="toggleFamilyModal(dashboard?.id)"
                                        />
                                    </div>

                                    @if (showFamilyToggle[dashboard?.id ?? '']) {
                                        <div class="family-modal">
                                            <div class="note-group">
                                                Note: Any change of family name will affect all users
                                            </div>

                                            <app-dashboard-family-dialog
                                                [dashboardGroupsList]="dashboardGroupsList"
                                                [currentDashboardGroup]="findCurrentDashboardGroup(dashboardGroup.name)"
                                                [currentDashboard]="dashboard"
                                                (changeDashboardFamily)="changeDashboardFamily($event)"
                                                (showFamilyToggle)="showFamilyToggle[dashboard?.id ?? ''] = $event"
                                                (dashboardFamilyChanged)="isApplyButtonEnabled = $event">
                                            </app-dashboard-family-dialog>
                                        </div>
                                    }
                                </div>
                            } @else {
                                <div class="familydiv-right-wrapper">
                                    <div class="familydiv-right" [ngClass]="['view-' + dashboard?.id?.toString()]">
                                        <span>Family: </span>

                                        <span class="family-name">{{ dashboardGroup.name ? dashboardGroup.name : 'None' }}</span>
                                    </div>
                                </div>
                            }

                            <div class="view-icons">
                                @if (dashboard?.iconClass === 'global') {
                                    <cc-icon
                                        [icon]="'global'"
                                        [title]="'Global'"
                                        [flat]="true"
                                    />
                                }

                                @if (dashboard?.iconClass === 'private') {
                                    <cc-icon
                                        [icon]="'lock'"
                                        [title]="'Private'"
                                        [flat]="true"
                                    />
                                }

                                <div class="view-icons-wrapper">
                                    @if (canDeleteDashboard) {
                                        <cc-icon
                                            [icon]="'trashcan'"
                                            (iconClicked)="removeDashboard(dashboard?.id)"
                                            [disabled]="dashboard.privilege === 'VIEW' || !!dashboard.isGlobal"
                                            [title]="'Remove'"
                                        />
                                    }

                                    <cc-icon
                                        [icon]="'dots-vertical'"
                                        (iconClicked)="toggleDetails(dashboard?.id)"
                                        [title]="'Properties'"
                                    />
                                </div>
                            </div>

                            <div class="tags-list">
                                @for (tag of dashboard?.dashboardTags ?? []; track $index) {
                                    <div
                                        class="tag"
                                        [innerHTML]="tag.name | highlight : searchParam">
                                    </div>
                                }
                            </div>

                            @if (showDetails[dashboard?.id ?? '']) {
                                <div class="info-block">
                                    <h4>Description</h4>
                                    <p>{{dashboard?.description}}</p>

                                    <h4>Owner</h4>
                                    <p>{{dashboard?.createdBy}}</p>

                                    <h4>Source</h4>
                                    <p>{{dashboard?.isShared ? 'Shared' : 'Private'}}</p>

                                    <h4>Created</h4>
                                    <p>{{dashboard?.createdDate | date:'MM/dd/yyyy HH:mm:ss a'}}</p>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    }

    <app-loader [isDataLoading]="isLoading"></app-loader>

    @if (!isLoading && !filteredDashboardGroupsList.length) {
        <div class="no-data error parent-height-dash-finder">
            <span>NO DASHBOARDS AVAILABLE</span>
        </div>
    }
</div>

