<div class="udf-field" [class.boolean-field]="type === 'boolean'">
    @switch (type) {
        @case ('string') {
            <div class="user-defined-field-grid-cell">
                {{ cellValue }}
            </div>
        }

        @case ('decimal') {
            <div class="user-defined-field-grid-cell">
                {{ cellValue }}
            </div>
        }

        @case ('choice') {
            <cc-single-select-dropdown
                cssClass="picklist"
                [anchorType]="'grid'"
                [matchAnchorWidth]="true"
                [searchable]="false"
                [allowNoSelection]="true"
                [autoScrollSelectedIntoView]="true"
                [options]="dropdownOptions"
                [selected]="dropdownCellValue"
                [disabled]="!canEditUdf"
                (selectedChange)="updateDropdownValue($event)">
            </cc-single-select-dropdown>
        }

        @case ('date') {
            <cc-date-select-dropdown
                [format]="'us'"
                [anchorType]="'grid'"
                [anchorSize]="'large'"
                [value]="dateCellValue"
                [fuzzies]="fuzzyDates"
                [disabled]="!canEditUdf"
                (valueChange)="updateDateValue($event)">
            </cc-date-select-dropdown>
        }

        @case ('boolean') {
            <cc-option-toggle
                class="centered"
                [labels]="{ trueLabel: 'True', falseLabel: 'False' }"
                [disabled]="!canEditUdf"
                [(value)]="booleanCellValue"
                (valueChange)="updateBooleanValue($event)">
            </cc-option-toggle>
        }
    }
</div>
