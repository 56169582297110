import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '@ddv/common-components';
import { UserService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import { Client, DashboardPreference, UserPreferences, WidgetFilterParams } from '@ddv/models';

@Component({
    selector: 'ddv-action-icons',
    templateUrl: './action-icons.component.html',
    styleUrls: ['./action-icons.component.scss'],
})
export class ActionIconsComponent implements OnInit {
    @Input() params: DashboardPreference | WidgetFilterParams | undefined;
    @Input() isTFLDetails = false;

    @Output() handleAcknowledge: EventEmitter<void> = new EventEmitter();
    @Output() handleResubmit: EventEmitter<void> = new EventEmitter();

    showAcknowledged = false;
    uploadModalOpened = false;

    private userPreferences: UserPreferences | undefined;

    constructor(
        private readonly alertService: AlertService,
        private readonly queryParamsService: QueryParamsService,
        private readonly userService: UserService,
    ) { }

    ngOnInit(): void {
        this.userService.userPreferences$.subscribe((userPreferences: UserPreferences) => {
            this.userPreferences = userPreferences;
            this.showAcknowledged = !!userPreferences.showAcknowledged;
        });
    }

    toggleUploadModal(): void {
        if ((this.params as DashboardPreference)?.clients?.length === 1) {
            this.uploadModalOpened = !this.uploadModalOpened;
        } else {
            this.alertService.warn('Upload is allowed for one client only. Please select a client');
        }
    }

    getClient(): Client | undefined {
        return (this.params as DashboardPreference)?.clients?.[0];
    }

    closeUploadModal(outcome: string): void {
        this.uploadModalOpened = false;

        if (outcome === 'Success') {
            this.alertService.success('Trade Uploaded successfully');
        } else if (outcome === 'Failed') {
            this.alertService.error('Upload failed');
        }
    }

    toggleAcknowledgement(event: boolean): void {
        this.showAcknowledged = event;
        this.setVizDataAccordingToAcknowledgedSelection();
        this.updateUserPreferences();
    }

    private setVizDataAccordingToAcknowledgedSelection(): void {
        this.queryParamsService.dispatchUpdatedQueryParams({ acknowledged: this.showAcknowledged });
    }

    private updateUserPreferences(): void {
        if (this.userPreferences) {
            this.userPreferences.showAcknowledged = this.showAcknowledged;
            this.userService.updateUserPreferenceData(this.userPreferences).subscribe();
        }
    }
}
