import { crosstalkColumns } from './crosstalk/constants';
import { DatasetFetchRuntimeParams, Query } from './query';

export abstract class QueryTemplateHolder {
    private unionQueries: string[] = [];

    protected constructor(public id: string | number) {}

    populateQueryTemplate(params: DatasetFetchRuntimeParams): Query {
        this.unionQueries = this.normalizeQueryTemplate().split('@@UNION@@');

        return convertQueryTemplateToQuery(
            this.unionQueries[0],
            params,
            this.normalizeConversableType());
    }

    populateStackedQueryTemplate(params: DatasetFetchRuntimeParams): Query[] {
        this.unionQueries = this.normalizeQueryTemplate().split('@@UNION@@');

        return this.unionQueries.map((queryTemplate) => {
            return convertQueryTemplateToQuery(
                queryTemplate,
                params,
                this.normalizeConversableType());
        });
    }

    abstract normalizeQueryTemplate(): string;
    abstract normalizeConversableType(): string | undefined;
    abstract normalizeQueryTypeName(): string;
    abstract normalizeQueryEndPoint(clientCode: string): string;
    abstract normalizeQueryIsStacked(): boolean;
}

function convertQueryTemplateToQuery(
    queryTemplate: string,
    params: DatasetFetchRuntimeParams,
    conversableType: string | undefined,
): Query {
    let escapedTemplate = checkForNullSafeReplace(queryTemplate, 'timeseriesRange.from', params.dateRange.from);
    escapedTemplate = checkForNullSafeReplace(escapedTemplate, 'timeseriesRange.to', params.dateRange.to);
    escapedTemplate = checkForNullSafeReplace(escapedTemplate, 'timeseriesRange.granularity', params.timeseriesGranularity?.toLowerCase());
    escapedTemplate = checkForNullSafeReplace(escapedTemplate, 'includeLinks', params.includeLinks);
    escapedTemplate = checkForNullSafeReplace(escapedTemplate, 'fundCodeList', JSON.stringify(params.fundCodes));

    const query: Query = JSON.parse(escapedTemplate);

    if (query?.timeseriesRange?.granularity) {
        query.timeseriesRange.granularity = query.timeseriesRange.granularity.toLowerCase();
    }

    if (Object.prototype.hasOwnProperty.call(params.specialCaseParams, 'includeManuallyReleased')) {
        query.includeManuallyReleased = params.specialCaseParams.includeManuallyReleased;
    }

    if (Object.prototype.hasOwnProperty.call(params.specialCaseParams, 'acknowledged')) {
        query.acknowledged = params.specialCaseParams.acknowledged;
    }

    if (conversableType) {
        // some datasets, like adhoc, don't have the "selectedColumns" property
        // and for those Trebek is automatically adding the crosstalk columns to the response
        // without us requesting them, we just need to add the "conversationType"
        query.selectedColumns?.push(...crosstalkColumns, { columnId: 'conversationId' });
        query.conversationType = conversableType;
    }

    return query;
}

function checkForNullSafeReplace(s: string, key: string, value?: string | null | boolean): string {
    if (value == null || value === 'null') {
        return s;
    }

    let escaped = s;
    while (escaped.indexOf(`@@${key}@@`) !== -1) {
        escaped = nullSafeReplace(escaped, key, value);
    }

    return escaped;
}

function nullSafeReplace(s: string, key: string, value?: string | null | boolean): string {
    if (value == null || value === 'null') {
        return s;
    }

    return s.replace(`@@${key}@@`, `${value}`);
}
