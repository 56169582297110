<div class="renderer-wrapper">
    @if (!showComment && editMode) {
        <span class="add-comment-sign">&#43;</span>
    }
    <span
        class="comment-cell-content"
        [innerHTML]="comment">
    </span>
    <app-conversation-history-icon title="Open history" (pressed)="openHistory($event)"></app-conversation-history-icon>
</div>
