import { Mode } from '../application-mode';
import { DeviceMode } from '../dashboards/device-mode';
import { PermissionType } from '../dashboards/permission-type';
import { TagType } from '../tag';

export const MODE: { [key: string]: Mode } = {
    EDIT_WORKSPACE: 'editWorkspace',
    VIEW: 'view',
    MANAGE_DATASETS: 'manageDatasets',
    CLOSE: 'close',
};

export const PERMISSION: { [key: string]: PermissionType } = {
    EDIT: 'EDIT',
    VIEW: 'VIEW',
};

export const FILTER_TYPE: { [key: string]: FilterType } = {
    DASHBOARD: 'dashboard',
    WIDGET: 'widget',
    CORE_WIDGET: 'coreWidget',
};

export const TAG_TYPE: { [key: string]: TagType } = {
    USER: 'USER',
    SHARED: 'SHARED',
};

export const DEVICE_MODE: { [key: string]: DeviceMode } = {
    DESKTOP: 'DESKTOP',
    TABLET: 'TABLET',
};

export const MANAGE_WIDGET = 'manageWidget';
export const MANAGE_WIDGET_ID = 9999;
export const MANAGE_WIDGET_WS_ID = 'mw_ws';
export const MANAGE_WIDGET_WS_KEY = 'MANAGE_WIDGET';

export const EXCLUDE = 'EXCLUDE';
export const INCLUDE = 'INCLUDE';

export type FilterType = 'dashboard' | 'widget' | 'coreWidget';

export const LOADER_CANCELLED_MESSAGE = 'Please change the parameters and resubmit';
export const LOADER_MESSAGE = '';
export const NO_ROWS_TO_SHOW_MESSAGE = 'No Rows to Show';
export const CLEAR_FILTERS_TO_VIEW_ROWS_MESSAGE = 'Clear Filters to View Rows';
export const TREBEK_QUERY_ERROR_MESSAGE = 'Error in fetching data. Please change the funds or the date range to try again';
export const HI_DATA_USER_ERROR_MESSAGE = 'User does not have access to Hedge Investor Data.';
export const HI_DATA_ADDITIONAL_MESSAGE = 'Please contact your administrator';
export const ACCESS_RESTRICTED_MESSAGE = 'Access Restricted';
export const VIZ_STYLE_CLASS: { [key: string]: string } = {
    SIMPLE_GRID: 'icon-simple-grid',
    ADVANCED_GRID: 'icon-advancedgrid',
    PIE_CHART: 'icon-pie-chart',
    DONUT_CHART: 'icon-donut',
    LINE_CHART: 'icon-line-chart',
    HORIZONTAL_BAR_CHART: 'icon-horizontal-bar',
    VERTICAL_BAR_CHART: 'icon-vertical-bar',
    HORIZONTAL_STACKED_BAR_CHART: 'icon-horizontal-stacked',
    VERTICAL_STACKED_BAR_CHART: 'icon-vertical-stacked',
    VERTICAL_MIRRORED_BAR_CHART: 'icon-vertical-mirrored',
    SUMMARY: 'icon-summary-widget',
    STACKED_AREA_CHART: 'icon-stacked-area-chart',
};
export const COMPARABLE_VIZ_LIST = ['ADVANCED_GRID', 'HORIZONTAL_BAR_CHART', 'VERTICAL_BAR_CHART'];
export const MANAGE_WIDGET_DEFAULT_MESSAGE = 'Choose mandatory fields to see the preview';
export const SYSTEM_ERROR_MESSAGE = 'System Error';
export const DEFAULT_WIDGET_POSITION = {
    endColumn: 90,
    endRow: 60,
    startingColumn: 0,
    startingRow: 0,
    minWidth: 200,
};

export const CONFIRM_DELETE = 'Are you sure you want to delete?';

export const GLOBAL_SAVE_MESSAGE = 'You are about to save a change to a global view, widget, or dataset. ' +
'This will affect all global views, widgets or datasets across all clients. ' +
'If this is your intention, click Yes, otherwise click No.';
export const GLOBAL_SAVE_NOTE = '*Note: This does not affect view level changes to fund selection or date range,' +
' these are set by client.';

export const WIDGET_REMOVAL_MESSAGE = 'Are you sure you would like to permanently remove the following widget from this view';

export const NO_ENTITLEMENTS = {
    CODE: 'NO_ENTITLEMENTS',
    TEXT: 'Forbidden. Required entitlements: "investor". This user has: "portfolio".',
};
