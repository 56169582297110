@if (!isLastDashboardClosed) {
    <app-hs-dashboard-header
        [currentDashboard]="currentDashboard"
        [isLastDashboardClosed]="isLastDashboardClosed"
        [inPresentationMode]="inPresentationMode"
        [dashboardGroups]="dashboardGroups"
        [dashboardSnapshots]="dashboardSnapshots"
        (cancelEdit)="cancelEdit.next($event)"
        (revertChanges)="revertChanges.next($event)"
        (saveClick)="saveDashboardWidgets.emit(queryParams)"
        (dashboardSwitch)="dashboardSwitch.emit($event)">
    </app-hs-dashboard-header>

    @if (currentDashboard) {
        <app-dashboard-filters
            [showHighlight]="showHighlight"
            [showAdditionalFilters]="false"
            [reducer]="'queryParamReducer'"
            [isQueryTypeRecon]="isQueryTypeRecon"
            [isHIDataAvailable]="isHIDataAvailable"
            [clientCode]="clientCode"
            [isMultiClient]="isMultiClient"
            [userSelectedDate]="userSelectedDate"
            [currentActiveDate]="currentActiveDate"
            [currentDashboardId]="currentDashboard.id"
            (fundsApply)="onApplySelectedFunds($event)"
            (clientsApply)="onApplySelectedClients($event)"
            (dateFilterApply)="onApplyDateChanges($event)"
            (restoreBtnClicked)="onRestoreCurrentDashboard()"
            (refreshBtnClicked)="onRefreshCurrentDashboard()"
            (filterApply)="onDashboardFiltersApplied($event)"
            (clearHighlight)="onClearHighlight()">
        </app-dashboard-filters>

        <div class="export-icon-list">
            <app-hs-dashboard-header-menu
                [currentDashboard]="currentDashboard"
                [inPresentationMode]="inPresentationMode"
                [isMultiClient]="isMultiClient"
                (restoreClicked)="onRestoreCurrentDashboard()"
                (refreshClicked)="onRefreshCurrentDashboard()"
                (closeDashboardExportMenu)="onCloseDashboardExport($event)"
                (queryParamApply)="onUpdateDashboardQueryParams($event)">
            </app-hs-dashboard-header-menu>
        </div>

        <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="showSettings = false">
            <cc-icon
                [icon]="'cog-outline'"
                [size]="'medium'"
                title="User Settings"
                (iconClicked)="showSettings = !showSettings"
            >
            </cc-icon>

            @if (showSettings) {
                <div class="dropdown right-position no-padding">
                    <app-user-preference
                        (userPreferencesClosed)="showSettings = false"
                        (fundsApply)="showSettings = false"
                        (clientsApply)="showSettings = false"
                    >
                    </app-user-preference>
                </div>
            }
        </div>
    }
}
