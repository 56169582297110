export interface QueryType {
    id?: number; // only present for dataset definitions
    name: string;
    dataEndpoint: string;
    metadataEndpoint: string;
}
// this is useful in things like constructors where you need some value for QueryType such that you
// don't have to make the type `| undefined` and you trust that by the time the property is
// actually accessed it will get a real queryType (like from an API call)
export const dummyQueryType: QueryType = { id: -1, name: '', dataEndpoint: '', metadataEndpoint: '' };

export interface QueryPeriodType {
    id: number;
    name: string;
}

export const enum QueryPeriodTypeName {
    RANGE_WITH_ACTIVE_DATE = 'Range with Active Date',
    RANGE = 'Range',
    POINT_IN_TIME = 'Point in Time',
    NO_CALENDAR = 'No Calendar',
}

export const enum QueryTypeName {
    AD_HOC = 'AdHoc',
    CASH_PROJECTION = 'CashProjection',
    CHECKLIST = 'Checklist',
    ECDI = 'InvestorsEconomicDistribution',
    EOD = 'EOD',
    GQL = 'GQL',
    INSTRUMENT= 'Instrument',
    INVESTORS_ACTIVITY = 'InvestorsActivity',
    INVESTORS_PERFORMANCE = 'InvestorsPerformance',
    INVESTORS_ASSETS = 'InvestorsAssets',
    NET_SETTLEMENT_DETAILS = 'NetSettlement',
    PORTFOLIO = 'Portfolio',
    POSITIONS = 'Positions',
    RECON = 'Recon',
    SETTLEMENT_BREAKS = 'SettlementBreaks',
    SNOWFLAKE = 'Snowflake',
    TAX = 'DynamicTaxReporting',
    TFL_INCOMPLETE_FILES = 'TFLIncompleteFiles',
    TRADE_FILE_DETAILS = 'TradeFileDetails',
    TRADE_FILE_LOADER = 'TradeFileLoader',
    TRADE_FILE_SUMMARY = 'TradeFileSummary',
    TRADES = 'Trades',
    TRANSACTIONS = 'Transactions'
}

const investorQueryTypeNames: QueryTypeName[] = [
    QueryTypeName.INVESTORS_ACTIVITY,
    QueryTypeName.INVESTORS_ASSETS,
    QueryTypeName.INVESTORS_PERFORMANCE,
    QueryTypeName.ECDI,
];

export function isInvestorQueryType(queryType: string | undefined): boolean {
    return investorQueryTypeNames.includes(queryType as QueryTypeName);
}
