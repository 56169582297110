import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardsModule } from '@ddv/dashboards';
import { SocketModule } from '@ddv/socket';
import { VisualizationsModule } from '@ddv/visualizations';
import { IconComponent } from '@hs/ui-core-presentation';

import { CanActivateManageWidgetsGuardService } from './can-activate-manage-widgets-guard.service';
import { LeftNavModule } from './components/left-nav/left-nav.module';
import { ManageWidgetsDashboardComponent } from './components/manage-widgets-dashboard/manage-widgets-dashboard.component';
import { ManageWidgetsComponent } from './components/manage-widgets/manage-widgets.component';
import { DataService } from './services/data.service';
import { FieldMetadataService } from './services/field-metadata.service';

@NgModule({
    imports: [
        CommonModule,
        DashboardsModule,
        LeftNavModule,
        SocketModule,
        VisualizationsModule,
        IconComponent,
    ],
    providers: [
        CanActivateManageWidgetsGuardService,
        DataService,
        FieldMetadataService,
    ],
    declarations: [
        ManageWidgetsComponent,
        ManageWidgetsDashboardComponent,
    ],
    exports: [
        ManageWidgetsComponent,
    ],
})
export class ManageWidgetsModule {}
