import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { Observable } from 'rxjs';

import { DashboardNote } from '../models/dashboard-note';

@Injectable()
export class DashboardNotesService {
    private clientCode = '';

    constructor(
        private readonly currentStateService: CurrentStateService,
        @Inject(ApiServices.ddvApi) private readonly ddvApiExecutor: ApiExecutorService,
    ) {
        this.currentStateService.clientCode$.subscribe({
            next: (clientCode) => this.clientCode = clientCode,
        });
    }

    fetchDashboardNote(dashboardId: string): Observable<DashboardNote | undefined> {
        return this.ddvApiExecutor.invokeServiceWithParams<DashboardNote>(this.clientCode, `dashboards/${dashboardId}/note`);
    }

    createDashboardNote(dasboardId: string, dashboardNote: DashboardNote): Observable<DashboardNote> {
        return this.ddvApiExecutor.invokeServiceWithBody<DashboardNote>(this.clientCode, `dashboards/${dasboardId}/note`, 'POST', dashboardNote);
    }

    updateDashboardNote(dashboardId: string, dashboardNote: DashboardNote): Observable<DashboardNote> {
        return this.ddvApiExecutor.invokeServiceWithBody<DashboardNote>(this.clientCode, `dashboards/${dashboardId}/note`, 'PUT', dashboardNote);
    }

    deleteDashboardNote(dashboardId: string): Observable<void> {
        return this.ddvApiExecutor.invokeServiceWithBody(this.clientCode, `dashboards/${dashboardId}/note`, 'DELETE', null);
    }
}
