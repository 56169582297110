import { ConfigItem } from './config-item';

export class VizConfigs {
    values: ConfigItem[];
    slicers: ConfigItem[];
    tooltips: ConfigItem[];
    axisTimes?: ConfigItem[];
    details?: ConfigItem[];
    mirroredValues?: ConfigItem[];

    constructor(init?: Partial<VizConfigs>) {
        this.values = init?.values ?? [];
        this.slicers = init?.slicers ?? [];
        this.tooltips = init?.tooltips ?? [];
        this.axisTimes = init?.axisTimes;
        this.details = init?.details;
        this.mirroredValues = init?.mirroredValues;

        this.populateNameValue?.(this.values);
        this.populateNameValue?.(this.slicers);
        this.populateNameValue?.(this.tooltips);
        this.populateNameValue?.(this.axisTimes);
        this.populateNameValue?.(this.details);
        this.populateNameValue?.(this.mirroredValues);
    }

    getFlatList?(): ConfigItem[] {
        return this.values.concat(this.slicers, this.tooltips, this.axisTimes ?? [], this.details ?? [], this.mirroredValues ?? []);
    }

    hasValueConfigForColumn?(columnId: string): boolean {
        return this.values.some((entry) => entry.name === columnId);
    }

    // Until we decide if we like "name" or "value" we need
    // to make sure the same thing is in both places
    private populateNameValue?(configs?: ConfigItem[]): void {
        configs?.forEach((config) => {
            config.name = config.name ?? config.value;
            config.value = config.name ?? config.value;
        });
    }
}
