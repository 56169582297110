import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { CurrentStateService, RealtimeActiveService } from '@ddv/behaviors';
import { ModalDialogService } from '@ddv/common-components';
import { CustomSelectionChangedEvent } from '@ddv/data-grid';
import {
    CompareModeService,
    CrosstalkDataService,
    DatasetManagerService,
    DatasetRefresherService,
    DefaultQueryParamsService,
    MetadataService,
    SlowDataApprovalService,
} from '@ddv/datasets';
import { UserService } from '@ddv/entitlements';
import { ClientDatasetFilterService, QueryParamsService } from '@ddv/filters';
import { LayoutService, ManagerService } from '@ddv/layout';
import {
    WIDGET_LIFECYCLE_EVENT,
    VisualizationType,
    AppWidgetState,
    DataUpdateBody,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    WidgetSettingsOverrides,
    VizConfigs,
    VisualizationMenuItem,
    isTradeFileDetails,
    MetadataLookup,
    ConfigItem,
    ExportFilteredData,
} from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { deepClone, getDefault } from '@ddv/utils';
import {
    BaseVisualizationComponent,
    PropertyNameValue,
    BaseBarChartVisualizationComponent,
    BaseCircleChartVisualizationComponent,
    LineChartVisualizationComponent,
    StackedAreaChartVisualizationComponent,
    BaseGridVisualizationComponent,
    AdvancedGridVisualizationComponent,
    SimpleGridVisualizationComponent,
    GridEvent,
    VisualizationDescriptor,
    visualizationDescriptorMap,
    HorizontalBarChartVisualizationComponent,
} from '@ddv/visualizations';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { ApplicationBaseWidgetComponent } from '../../application-base-widget.component';
import { ExportableData } from '../../models/exportable-data';
import { FilteredDataService } from '../../services/filtered-data.service';
import { WidgetExportService } from '../../services/widget-export.service';
import { WidgetService } from '../../services/widget.service';
import { WidgetHeaderComponent } from '../widget-header/widget-header.component';

@Component({
    selector: 'app-visualization-widget',
    templateUrl: './visualization-widget.component.html',
    styleUrls: ['../../application-base-widget.component.scss'],
})
export class VisualizationWidgetComponent extends ApplicationBaseWidgetComponent implements OnDestroy {
    @Input() useNewLegend = false;
    componentRef: ComponentRef<BaseVisualizationComponent> | undefined;
    instanceLoadedObs: Observable<boolean>;

    @Output() detailWidgetClicked = new EventEmitter<{ detailWidgetId: number, clientCode: string }>();
    @Output() gridSelectionChanged = new EventEmitter<CustomSelectionChangedEvent>();

    @ViewChild('widgetHeaderComponent', { static: true }) private readonly widgetHeader: WidgetHeaderComponent | undefined;
    // the tests stomps on this container with a sub version so it cannot be marked readonly
    // its not worth trying to fix that as the proper solution here is to stop using so many dynamic components
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    @ViewChild('vizContent', { read: ViewContainerRef, static: false }) private container: ViewContainerRef | undefined;

    private readonly instanceLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private isClosing = false;
    private widgetPreferenceSubscription: Subscription | undefined;
    private subscriptions: Subscription[] = [];
    private selectionChangedEventSubscription: Subscription | undefined;
    private isStackedQuerySubscription: Subscription | undefined;
    private cachedConfigValues: { [key: string]: ConfigItem[] } = {};

    constructor(
        private readonly filteredDataService: FilteredDataService,
        cdr: ChangeDetectorRef,
        clientDatasetFilterService: ClientDatasetFilterService,
        datasetManagerService: DatasetManagerService,
        layoutService: LayoutService,
        metadataService: MetadataService,
        modalService: ModalDialogService,
        widgetService: WidgetService,
        workspaceManager: ManagerService,
        queryParamsService: QueryParamsService,
        private readonly compareModeService: CompareModeService,
        private readonly widgetExportService: WidgetExportService,
        private readonly crosstalkDataService: CrosstalkDataService,
        realtimeActiveService: RealtimeActiveService,
        currentStateService: CurrentStateService,
        slowDataApprovalService: SlowDataApprovalService,
        fuzzyDateService: FuzzyDatesService,
        userService: UserService,
        datasetRefresherService: DatasetRefresherService,
        defaultQueryParamsService: DefaultQueryParamsService,
    ) {
        super(
            cdr,
            clientDatasetFilterService,
            datasetManagerService,
            layoutService,
            metadataService,
            modalService,
            widgetService,
            workspaceManager,
            queryParamsService,
            slowDataApprovalService,
            realtimeActiveService,
            fuzzyDateService,
            defaultQueryParamsService,
            currentStateService,
            userService,
            datasetRefresherService);

        this.instanceLoadedObs = this.instanceLoadedSubject.asObservable();
    }

    // this moronicness is necessary because of all the dynamic components
    override toggleUseNewLegend(useNewLegend: boolean): void {
        super.toggleUseNewLegend(useNewLegend);

        if (this.componentRef) {
            this.componentRef.instance.useNewLegend = this.useNewLegend;
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.widgetPreferenceSubscription) {
            this.widgetPreferenceSubscription.unsubscribe();
        }

        if (this.isStackedQuerySubscription) {
            this.isStackedQuerySubscription.unsubscribe();
        }

        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
        this.removeDataset();
    }

    override updateFieldMetadata(fieldMetadata: MetadataLookup): void {
        super.updateFieldMetadata(fieldMetadata);

        if (this.componentRef) {
            // this is only necessary because of dynamic components
            this.componentRef.instance.updateFieldMetadata(fieldMetadata);
        }
    }

    onVisualizationChanged(event: VisualizationMenuItem): void {
        // Only when we have multiviz widget and we're switching between vizs
        if (this.isInstanceAGrid() && this.widgetPrefs!.visualizationConfigs.length >= 2) {
            this.setGridBaseFilterAndSortValues(this.widgetPrefs!.currentVisualization!);
        }

        if (this.widgetPrefs?.widgetFilters?.isComparing) {
            this.compareModeService.disableCompareMode();
        }

        if (this.selectionChangedEventSubscription) {
            this.selectionChangedEventSubscription.unsubscribe();
        }

        this.selectedVizForm = event.uid as VisualizationType;
        this.updateWidgetVisualization();
        if (!this.isLoaderCancelled) {
            this.renderVisualization();
        }
    }

    override onWidgetSettingOverridesChanged(updatedWidget: AppWidgetState, changes: WidgetSettingsOverrides): void {
        if (changes.defaultSlicerForDefaultVisualization) {
            const vizSlicers = this.getUserPreferenceForSelectedViz()?.configs?.slicers ??
                this.widgetPrefs?.visualizationConfigs[0].configs?.slicers ??
                [];

            const index = vizSlicers.findIndex((slicer) => slicer.value === changes.defaultSlicerForDefaultVisualization);

            this.onFilterChanged(vizSlicers[index]);
        }

        if (changes.defaultVisualizationType) {
            const visItems = this.getVisItems().map((i) => {
                return { ...i, selected: i.uid === changes.defaultVisualizationType } as VisualizationMenuItem;
            });

            this.widgetHeader?.updateVizMenuOptions(visItems);
        }

        super.onWidgetSettingOverridesChanged(updatedWidget, changes);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onFilterChanged(event: any): void {
        if (this.widgetPrefs?.widgetFilters?.isComparing) {
            this.compareModeService.disableCompareMode();
        }
        if (this.componentRef?.instance) {
            this.componentRef.instance.onFilterChanged(event);
            this.selectedSlicer = event;
            this.updateWidgetTitle();
        }
    }

    override onMasterChanged(isMaster: boolean): void {
        if (this.componentRef?.instance) {
            this.componentRef.instance.isMaster = isMaster;
        }
    }

    override widgetLifeCycleCallBack(
        eventName: WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION | WIDGET_LIFECYCLE_EVENT.DATA_UPDATE,
        data: DataUpdateBody): void;
    override widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, data: WidgetLifeCycleData): void;
    override widgetLifeCycleCallBack(
        eventName: WidgetLifecycleEvent | WIDGET_LIFECYCLE_EVENT.DATA_UPDATE | WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION,
        param: WidgetLifeCycleData | DataUpdateBody,
    ): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.INIT_WIDGET:
                this.fetchWidgetPreferences(() => {
                    this.updateWidgetHeader();
                });
                this.triggerCompLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.LOADING_DATA, null);
                break;
            case WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION:
                this.onInterWidgetCommunication(param as { action: WIDGET_LIFECYCLE_EVENT });
                break;
            case WIDGET_LIFECYCLE_EVENT.WIDGET_PASSIVE_MODE:
                if (this.widgetPreferenceSubscription) {
                    this.widgetPreferenceSubscription.unsubscribe();
                }
                this.removeDataset();
                break;
            case WIDGET_LIFECYCLE_EVENT.WIDGET_ACTIVE_MODE:
                this.onActiveMode();
                break;
            case WIDGET_LIFECYCLE_EVENT.ON_CLOSE:
                this.isClosing = true;
                this.removeQueryParamFilters();
                break;
            case WIDGET_LIFECYCLE_EVENT.DATA_UPDATE:
                (param as DataUpdateBody).data = this.clientDatasetFilterService.filterData(
                    (param as DataUpdateBody).data,
                    { filters: (param as DataUpdateBody).filters?.filters });
                if ((param as DataUpdateBody).compareData) {
                    (param as DataUpdateBody).compareData = this.clientDatasetFilterService.filterData(
                        (param as DataUpdateBody).compareData ?? [],
                        { filters: (param as DataUpdateBody).filters?.filters });
                }
                this.triggerCompLifeCycleCallBack(eventName, param);
                this.enableMenuIcons();
                break;
            default:
                if (!this.isClosing || !(eventName as string).toLowerCase().includes('comment')) {
                    this.triggerCompLifeCycleCallBack(eventName, param);
                }
        }
    }

    onActiveMode(): void {
        if (this.shouldWidgetReload()) {
            this.onWidgetPreferencesChanged();
        } else if (this.isWidgetPreferenceFetched) {
            this.fetchMetaData();
            this.fetchDataset();
        } else {
            this.fetchWidgetPreferences(() => {
                this.updateWidgetHeader();
            });
        }
    }

    onWidgetPreferencesChanged(): void {
        this.fetchWidgetPreferences(() => {
            this.widgetHeader?.updateVizMenuOptions(this.getVisItems());
            this.renderVisualization();
        });
        this.setWidgetReloaded();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    triggerCompLifeCycleCallBack(eventName: any, param: any): void {
        if (this.componentRef) {
            this.componentRef.instance.widgetLifeCycleCallBack(eventName, param);
        }
    }

    override onInterWidgetCommunication(data: WIDGET_LIFECYCLE_EVENT | { action: WIDGET_LIFECYCLE_EVENT }): void {
        super.onInterWidgetCommunication(data);
        if (data === WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_DATA) {
            const filteredData = this.setExportData(data);
            this.filteredDataService.updateFilteredData(filteredData ?? {});
        } else if (data === WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_ADVANCED_GRID_DATA_TO_CSV) {
            const filteredData = this.setExportData(data);
            this.filteredDataService.updateFilteredData(filteredData ?? {});
        } else if (data === WIDGET_LIFECYCLE_EVENT.EXPORT_FULL_DATA) {
            const fullData = this.setExportData(data);
            this.filteredDataService.updateFullData(fullData ?? {});
        } else if (data === WIDGET_LIFECYCLE_EVENT.LOADING_DATA) {
            if (this.isLoaderCancelled || this.isErrorOccurred) {
                this.isLoaderCancelled = false;
                this.isErrorOccurred = false;
                this.cdr.detectChanges();
                this.renderVisualization();
            }
            this.onDataLoading();
            this.triggerCompLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.LOADING_DATA, data);
        } else if (data && (data as { action: WIDGET_LIFECYCLE_EVENT }).action === WIDGET_LIFECYCLE_EVENT.VISUALIZATION_SELECTED) {
            this.triggerCompLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.VISUALIZATION_SELECTED, data);
        } else if (data && (data as { action: WIDGET_LIFECYCLE_EVENT }).action === WIDGET_LIFECYCLE_EVENT.VIEW_RESTORED) {
            this.triggerCompLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.VIEW_RESTORED, data);
        } else if (data === WIDGET_LIFECYCLE_EVENT.GROUP_COLUMNS_ORDER_CHANGED) {
            this.triggerCompLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.GROUP_COLUMNS_ORDER_CHANGED, null);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    override widgetLifeCyclePostProcess(eventName: WIDGET_LIFECYCLE_EVENT, data: any): void {
        super.widgetLifeCyclePostProcess(eventName, data);
        if (this.componentRef?.instance.widgetLifeCyclePostProcess) {
            this.componentRef.instance.widgetLifeCyclePostProcess(eventName, data);
        }
    }

    override displayIcons(): void {
        super.displayIcons(false);
        this.toggleCustomIcon();
        if (this.showLoader) {
            this.disableMenuIcons();
        }
    }

    toggleIcons(): void {
        super.displayIcons(false);
        this.toggleCustomIcon();
        if (this.showLoader) {
            this.disableMenuIcons();
        }
    }

    exportWidget(): void {
        const vizComponent = this.componentRef?.instance;
        if (!vizComponent) {
            return;
        }
        const metadata = vizComponent.getMetadata();
        if (!metadata) {
            return;
        }

        if (this.isInstanceAGrid()) {
            this.onInterWidgetCommunication(WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_ADVANCED_GRID_DATA_TO_CSV);
            const isTFLDetails = isTradeFileDetails(this.widgetPrefs?.datasetDefinition?.name ?? '');
            this.widgetExportService.exportWidgetFilteredCSV(vizComponent.widgetId, metadata, isTFLDetails);
        } else if (this.isInstanceNotAGrid()) {
            this.onInterWidgetCommunication(WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_DATA);
            this.widgetExportService.exportWidgetFilteredCSV(vizComponent.widgetId, metadata);
        }
    }

    areFiltersApplied(): boolean {
        if (this.componentRef?.instance instanceof AdvancedGridVisualizationComponent ||
            this.componentRef?.instance instanceof SimpleGridVisualizationComponent
        ) {
            return this.componentRef.instance.areFiltersApplied();
        }

        return false;
    }

    private isInstanceAGrid(): boolean {
        return this.componentRef?.instance instanceof AdvancedGridVisualizationComponent ||
            this.componentRef?.instance instanceof SimpleGridVisualizationComponent;
    }

    private isInstanceNotAGrid(): boolean {
        return this.componentRef?.instance instanceof BaseCircleChartVisualizationComponent ||
            this.componentRef?.instance instanceof LineChartVisualizationComponent ||
            this.componentRef?.instance instanceof StackedAreaChartVisualizationComponent ||
            this.componentRef?.instance instanceof BaseBarChartVisualizationComponent ||
            this.componentRef?.instance instanceof HorizontalBarChartVisualizationComponent;
    }

    private setGridBaseFilterAndSortValues(currentVis: VisualizationType): void {
        const currentConfigs = this.widgetPrefs!.visualizationConfigs.find((v) => v.visualizationType === currentVis)?.configs?.values;
        if (currentConfigs) {
            currentConfigs.forEach((v, i) => {
                v.agFilter = this.cachedConfigValues[currentVis]?.[i]?.agFilter ?? null;
                v.agSort = this.cachedConfigValues[currentVis]?.[i]?.agSort ?? null;
                v.agSortIndex = this.cachedConfigValues[currentVis]?.[i]?.agSortIndex ?? null;
            });
        }
    }

    private removeQueryParamFilters(): void {
        const widgetIds = this.workspaceManager.getWidgetIdsForWorkspace();
        if (widgetIds.length === 0) {
            this.queryParamsService.removeFilters();
        }
    }

    private updateWidgetHeader(): void {
        const isAdvancedGrid = this.widgetPrefs?.visualizationConfigs[0].visualizationType === 'ADVANCED_GRID';
        this.headers = this.getHeaders({
            hasCustomFilter: !!this.widgetPrefs?.visualizationConfigs.some((item) => (item.configs?.slicers?.length ?? 0) > 1),
            hasViz: true,
            settingsOverridesMenu: true,
            isConversable: !!this.widgetPrefs?.datasetDefinition?.conversableType && isAdvancedGrid,
            vizMenuItems: this.getVisItems(),
        });
        this.widgetHeader?.updateMenuOptions(this.headers);
        this.widgetHeader?.selectVisualization({ value: this.getSelectedVizProps().id });
        this.displayIcons();
    }

    private updateWidgetVisualization(): void {
        if (this.widgetPrefs) {
            this.widgetPrefs.currentVisualization = this.selectedVizForm;
            this.workspaceManager.setWidgetExtraPreferences(this.id, this.widgetPrefs);
            this.onVizInfoChanged(this.widgetPrefs, this.widgetPrefs.currentVisualization!);
        }
    }

    private fetchWidgetPreferences(callback: () => void): void {
        this.isWidgetPreferenceFetched = false;
        this.widgetPrefs = this.workspaceManager.getWidgetPreferences(this.id) ?? undefined;

        if (this.widgetPrefs?.id) {
            this.widgetPrefs.visualizationConfigs.forEach((vizConfig) => {
                this.cachedConfigValues[vizConfig.visualizationType] = deepClone(vizConfig.configs?.values) ?? [];
            });
            this.updateWidgetPrefs(callback);
            return;
        }

        this.widgetPreferenceSubscription = this.getWidgetPreferencesSubscription(callback);
    }

    private updateWidgetPrefs(callback: () => void): void {
        if (!this.widgetPrefs) {
            return;
        }

        this.isWidgetPreferenceFetched = true;

        Object.assign(this.widgetPrefs, this.widgetService.getWidgetSetting(this.widgetPrefs));
        this.widgetPrefs.widgetPosition = this.layoutService.getWidgetPositionById(this.id);
        this.workspaceManager.setWidgetExtraPreferences(this.id, this.widgetPrefs);

        const id = this.widgetPrefs.namedQueryId ?? this.widgetPrefs?.datasetDefinition?.id;
        this.setIsStackedQuery(id);
        this.fetchMetaData();
        this.fetchDataset();
        this.updateWidgetTitle();
        this.selectedVizForm = this.getDefaultVizForm();
        callback();
    }

    private setIsStackedQuery(datasetId: number | string | undefined): void {
        if (datasetId && !this.isStackedQuerySubscription) {
            this.isStackedQuerySubscription = this.workspaceManager
                .isStackedQuery(datasetId)
                .subscribe((isStackedQuery) => this.isStackedQuery = isStackedQuery);
        }
    }

    private toggleCustomIcon(): void {
        if (this.headers.length) {
            if (!this.isCustomFilterVisible()) {
                this.hideIcons(['custom-filter']);
            } else {
                this.showIcons(['custom-filter']);
            }
        }
    }

    private renderVisualization(): void {
        if (!this.container || !this.widgetPrefs) {
            return;
        }

        this.container.remove();
        this.componentRef = this.container.createComponent(this.getSelectedVizProps().component);

        const visualizationComponent = this.componentRef.instance;
        visualizationComponent.widgetId = this.id;
        const datasetDefinition = this.widgetPrefs.datasetDefinition;
        visualizationComponent.datasetId = this.widgetPrefs.namedQueryId ?? datasetDefinition?.id ?? 0;
        visualizationComponent.isMaster = !!this.widgetPrefs.isMaster;
        visualizationComponent.hideLoaderAfterFirstDataLoad = !!this.widgetPrefs.hideLoaderAfterFirstDataLoad;
        visualizationComponent.isManagingWidget = this.isManagingWidget;
        visualizationComponent.useNewLegend = this.useNewLegend;
        visualizationComponent.fieldMetadata = this.fieldMetadata;

        // this is only necessary because of tests.  but i failed to figure out the test
        if (visualizationComponent.valueClickedInMaster) {
            visualizationComponent.valueClickedInMaster.subscribe((event: PropertyNameValue) => {
                this.queryParamsService.toggleMasterFilter(event.property, event.name, event.value, event.selectedValues);
            });
        }

        const vizConfigs = this.getUserPreferenceForSelectedViz();
        visualizationComponent.preferences = vizConfigs;
        const configs = vizConfigs?.configs ?? {} as VizConfigs;
        if (this.selectedSlicer) {
            visualizationComponent.vizInfo = { slicer: this.selectedSlicer, values: [] };
        }

        const slicers = configs.slicers || [];
        this.widgetHeader?.updateFilterOptions(slicers);
        if (visualizationComponent.vizSlicer) {
            visualizationComponent.vizSlicer.subscribe((slicer) => {
                this.widgetHeader?.onFilterChanged(slicer);
                this.updateWidgetTitle();
            });
        } else {
            this.updateWidgetTitle();
        }

        if (this.widgetPrefs.coreWidgetType === 'VISUALIZATION' && vizConfigs?.visualizationType.indexOf('GRID') !== -1) {
            this.subscribeToGridChanges(this.componentRef.instance as BaseGridVisualizationComponent);
        }

        this.toggleCustomIcon();

        if (this.isDataLoaded) {
            this.updateComponentData();
        }

        if (this.componentRef.instance instanceof AdvancedGridVisualizationComponent) {
            this.componentRef.instance.isGridReadyObs?.subscribe((isReady) => {
                if (isReady) {
                    this.instanceLoadedSubject.next(true);
                }
            });
        }

        this.updateWidgetVisualization();
    }

    private subscribeToGridChanges(component: BaseGridVisualizationComponent): void {
        this.subscriptions.push(component.gridStateUpdated
            .subscribe((gridEvent: GridEvent) => {
                if (!this.showLoader && ['FILTER', 'SORT', 'PINNED'].includes(gridEvent.event)) {
                    this.widgetService.publishGridStateUpdated(gridEvent);
                }
            }));
        this.subscriptions.push(component.detailWidgetClicked
            .subscribe(({ detailWidgetId, clientCode }) => this.detailWidgetClicked.next({ detailWidgetId, clientCode })));

        if (component instanceof AdvancedGridVisualizationComponent) {
            this.selectionChangedEventSubscription = component.selectionChangedEvent
                .subscribe((data: CustomSelectionChangedEvent) => {
                    this.gridSelectionChanged.emit(data);
                });
            this.subscriptions.push(this.selectionChangedEventSubscription);
        }
    }

    private getDefaultVizForm(): VisualizationType {
        if (!this.widgetPrefs?.visualizationConfigs) {
            console.error(`${this.widgetPrefs?.name}[${this.widgetPrefs?.id}] has no visConfigs`);
        }
        const defaultConfig = getDefault(this.widgetPrefs!.visualizationConfigs);
        if (!defaultConfig) {
            console.error(`${this.widgetPrefs?.name}[${this.widgetPrefs?.id}] has no default viz config`);
        }

        return defaultConfig!.visualizationType;
    }

    private getSelectedVizProps(): VisualizationDescriptor {
        return visualizationDescriptorMap[this.selectedVizForm!];
    }

    private getVisItems(): VisualizationMenuItem[] {
        return this.widgetPrefs?.visualizationConfigs.map((item) => {
            const viz = visualizationDescriptorMap[item.visualizationType];
            return {
                uid: item.visualizationType,
                label: viz.name,
                value: viz.id,
                cssClass: viz.cssClass,
            };
        }) ?? [];
    }

    private isCustomFilterVisible(): boolean {
        if (this.selectedVizForm === 'LINE_CHART' || this.selectedVizForm === 'STACKED_AREA_CHART') {
            const slicers = this.getUserPreferenceForSelectedViz()?.configs?.slicers || [];
            return slicers.length !== 0;
        }
        return !(this.selectedVizForm === 'SIMPLE_GRID' || this.selectedVizForm === 'ADVANCED_GRID');
    }

    // this method is no more dangerous than it was BEFORE giving componentRef a type
    // however, now it's just more explicit that this method relies on the right type of
    // event coming in to control what type of visualization component is actually being used
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private setExportData(exportType: string): Record<string, ExportableData> | undefined {
        const instance = this.componentRef?.instance;
        if (!instance || !this.widgetPrefs) {
            return;
        }

        const advancedGridInstance = instance as AdvancedGridVisualizationComponent;
        const baseGridInstance = instance instanceof BaseGridVisualizationComponent ? instance : undefined;
        const exportData = this.getDataForExportType(exportType as WIDGET_LIFECYCLE_EVENT, advancedGridInstance);
        const groupers = baseGridInstance?.getExportFilteredDataGroupers() ?? [];
        let visibleColumns = baseGridInstance?.getFilteredExportVisibleColumns() ?? [];
        let crosstalkImportColumns: string[] | undefined;

        if (this.widgetPrefs && this.widgetExportService.shouldIncludeCrosstalkKeys(this.widgetPrefs)) {
            const datasetId = this.widgetPrefs.namedQueryId ?? this.widgetPrefs.datasetDefinition?.id ?? 0;
            crosstalkImportColumns = this.crosstalkDataService.addCrosstalkImportKeysToData(
                datasetId,
                advancedGridInstance.getVisibleRowsData(exportType),
                exportData,
                exportType === WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_DATA && !!groupers.length);
            crosstalkImportColumns.push('conversationId');
            visibleColumns.push(...crosstalkImportColumns);
        }

        if (crosstalkImportColumns?.length) {
            visibleColumns = visibleColumns.filter((column) => column !== 'Conversation Id');
        }

        return {
            [this.getWidgetId()]: {
                datasetId: this.widgetPrefs.datasetDefinition?.id ?? 0,
                widgetName: this.widgetPrefs.displayNameType === 'CUSTOM' ? this.widgetPrefs.customDisplayName : this.widgetPrefs.name,
                data: exportData.data,
                summary: exportData.summary,
                datasetType: this.widgetPrefs.datasetDefinition?.dataType,
                startDate: this.filters.startDate,
                endDate: this.filters.endDate,
                groupers,
                visibleColumns,
                crosstalkImportColumns,
            },
        };
    }

    private getDataForExportType(type: WIDGET_LIFECYCLE_EVENT, visualization: AdvancedGridVisualizationComponent): ExportFilteredData {
        // these methods are all on the BaseGridVisualizationComponent
        // TODO: switch the type and see what breaks
        switch (type) {
            case WIDGET_LIFECYCLE_EVENT.EXPORT_FULL_DATA:
                return visualization.getExportFullData();
            case WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_DATA:
                return visualization.getExportFilteredData();
            default:
                return visualization.getExportFilteredDataForCSV();
        }
    }

    private onVizInfoChanged(updatedWidget: AppWidgetState, defaultVisualizationType: VisualizationType): void {
        if (this.componentRef?.instance) {
            if (!(this.componentRef.instance instanceof AdvancedGridVisualizationComponent) &&
                !(this.componentRef.instance instanceof SimpleGridVisualizationComponent)) {
                const slicer = updatedWidget.getDefaultSlicerObjectForVisualizationType(defaultVisualizationType);
                if (slicer) {
                    this.componentRef.instance.onVizInfoChanged(slicer);
                }
            }
        }
    }

    private getWidgetPreferencesSubscription(callback: () => void): Subscription {
        return this.widgetService.getWidgetPreferences(this.id, this.userPreferences?.clientCode ?? '')
            .subscribe({
                next: (widgetPrefs: AppWidgetState) => {
                    this.widgetPrefs = widgetPrefs;
                    this.updateWidgetPrefs(callback);
                },
                error: (error) => {
                    /* eslint-disable no-underscore-dangle */
                    if (error._body) {
                        if (typeof error._body === 'string') {
                            error._body = JSON.parse(error._body);
                        }
                    }
                    /* eslint-enable no-underscore-dangle */
                },
            });
    }
}
