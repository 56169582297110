    <form class="saveas-widget" novalidate [formGroup]="saveWidgetForm!" (ngSubmit)="saveWidgetInfo(saveWidgetForm!.value)">
        <h2 class="properties-modal-header" *ngIf="!isCopy">Widget Properties</h2>

        <div class="input-box">
            <div class="input-box-label">
                <label>{{ isCopy ? 'Save As' : 'Name' }}</label>

                <span> (MAXIMUM OF 40 CHARACTERS)</span>

                <input
                    id="widget-name"
                    type="text"
                    placeholder="Widget Name"
                    maxlength="40"
                    formControlName="name"
                    autocomplete="off"
                    [class.readonly]="isDashboardWidget"
                    [attr.readonly]="(isDashboardWidget || disableEditGlobalWidget) ? true : null"
                    [ngClass]="{'has-error': saveWidgetForm!.get('name')?.invalid && (saveWidgetForm!.get('name')!.touched || saveWidgetForm!.get('name')!.dirty),
                                'not-allowed': disableEditGlobalWidget}"
                    (focusout)="validateDuplicate()" />
            </div>

            <div class="error-message" *ngIf="saveWidgetForm!.get('name')!.hasError('required') && (saveWidgetForm!.get('name')!.touched || saveWidgetForm!.get('name')!.dirty)">
                Name is required.
            </div>

            <div class="error-message" *ngIf="saveWidgetForm!.get('name')!.hasError('isSpaces') && (saveWidgetForm!.get('name')!.touched || saveWidgetForm!.get('name')!.dirty)">
                Name cannot be only spaces.
            </div>

            <div class="error-message" *ngIf="saveWidgetForm!.get('name')!.hasError('pattern') && (saveWidgetForm!.get('name')!.touched || saveWidgetForm!.get('name')!.dirty)">
                Name contains an invalid character.
            </div>

            <div class="error-message" *ngIf="saveWidgetForm!.get('name')!.hasError('maxlength') && (saveWidgetForm!.get('name')!.touched || saveWidgetForm!.get('name')!.dirty)">
                Name should not exceed 40 characters.
            </div>

            <div class="error-message" *ngIf="saveWidgetForm!.get('name')!.hasError('isDuplicate') && (saveWidgetForm!.get('name')!.touched || saveWidgetForm!.get('name')!.dirty)">
                Name already exists. Please try a different name
            </div>
        </div>

        <div class="input-box" *ngIf="!isDashboardWidget">
            <h4 class="displayname">Display Name
                <span> (MAXIMUM OF 40 CHARACTERS)</span>
            </h4>

            <div class="sharing-radio-btn-group">
                <div class="radio-btn" [hidden]="isReadOnly && !display_as_auto.checked" [class.readonly]="isReadOnly || disableEditGlobalWidget">
                    <input
                        #display_as_auto
                        id="display_as_auto"
                        name="displayNameType"
                        type="radio"
                        value="AUTO"
                        formControlName="displayNameType"
                        [attr.checked]="display_as_auto.checked ? 'checked' : null"
                        [attr.disabled]="(isAutoOptionDisabled() || disableEditGlobalWidget) ? true : null"
                        [ngClass]="{'not-allowed': disableEditGlobalWidget}"/>

                    <label for="display_as_auto">
                        <span [hidden]="isReadOnly && display_as_auto.checked"></span>Auto
                    </label>
                </div>

                <div class="radio-btn" [hidden]="isReadOnly && !display_as_configured.checked" [class.readonly]="isReadOnly || disableEditGlobalWidget">
                    <input
                        #display_as_configured
                        id="display_as_configured"
                        name="displayNameType"
                        type="radio"
                        value="CONFIGURED"
                        formControlName="displayNameType"
                        [attr.checked]="display_as_configured.checked ? 'checked' : null"
                        [attr.disabled]="(isReadOnly || disableEditGlobalWidget) ? true : null"
                        [ngClass]="{'not-allowed': disableEditGlobalWidget}" />

                    <label for="display_as_configured">
                        <span [hidden]="isReadOnly && display_as_configured.checked"></span>As Configured
                    </label>
                </div>

                <div class="radio-btn" [hidden]="isReadOnly && !display_as_custom.checked" [class.readonly]="isReadOnly || disableEditGlobalWidget">
                    <input
                        #display_as_custom
                        name="displayNameType"
                        id="display_as_custom"
                        type="radio"
                        value="CUSTOM"
                        formControlName="displayNameType"
                        [attr.checked]="display_as_custom.checked ? 'checked' : null"
                        [attr.disabled]="(isReadOnly || disableEditGlobalWidget) ? true : null"
                        [ngClass]="{'not-allowed': disableEditGlobalWidget}" />

                    <label for="display_as_custom">
                        <span [hidden]="isReadOnly && display_as_custom.checked"></span>Custom
                    </label>
                </div>
            </div>

            <div class="customDisplayName" *ngIf="saveWidgetForm!.get('displayNameType')!.value === 'CUSTOM'">
                <input
                    id="widget-display-name"
                    type="text"
                    placeholder="Display Name"
                    maxlength="40"
                    formControlName="customDisplayName"
                    autocomplete="off"
                    [ngClass]="{'has-error': saveWidgetForm!.get('customDisplayName')!.invalid && saveWidgetForm!.get('customDisplayName')!.touched, 'not-allowed': disableEditGlobalWidget}"
                    [attr.readonly]="(isReadOnly || disableEditGlobalWidget) ? true : null"
                    [class.readonly]="isReadOnly" />

                <div class="error-message" *ngIf="saveWidgetForm!.get('customDisplayName')!.hasError('required') && saveWidgetForm!.get('customDisplayName')!.touched">
                    Custom name is required.
                </div>

                <div class="error-message" *ngIf="saveWidgetForm!.get('customDisplayName')!.hasError('pattern') && saveWidgetForm!.get('customDisplayName')!.touched">
                    Please enter a valid name.
                </div>

                <div class="error-message" *ngIf="saveWidgetForm!.get('customDisplayName')!.hasError('maxlength') && saveWidgetForm!.get('customDisplayName')!.touched">
                    Custom name should not exceed 40 characters.
                </div>
            </div>
        </div>

        <div class="input-box">
            <div class="input-box-label">
                <label for="widget-description">Description</label>

                <textarea
                    id="widget-description"
                    placeholder="Add Widget Description"
                    formControlName="description"
                    [readOnly]="isDashboardWidget || disableEditGlobalWidget"
                    [class.readonly]="isDashboardWidget || disableEditGlobalWidget"
                    [ngClass]="{'has-error': saveWidgetForm!.get('description')!.invalid && saveWidgetForm!.get('description')!.touched,
                                'not-allowed': disableEditGlobalWidget}">
                </textarea>
            </div>

            <div class="error-message" *ngIf="saveWidgetForm!.get('description')!.hasError('required') && saveWidgetForm!.get('description')!.touched">
                Description is required.
            </div>

            <div class="error-message" *ngIf="saveWidgetForm!.get('description')!.hasError('minlength') && saveWidgetForm!.get('description')!.touched">
                Description should have at least 15 characters.
            </div>
        </div>

        <div class="widget-datasource" *ngIf="isDashboardWidget && isHSUser">
            <div class="read-only-box">
                <label for="query-name" class="read-only-box-label">Query Name</label>

                <span id="query-name">{{ widgetQueryName }}</span>
            </div>

            <div class="read-only-box">
                <label for="widget-query-type" class="read-only-box-label">QUERY TYPE</label>

                <span id="widget-query-type">{{ widgetQueryTypeName }}</span>
            </div>
        </div>

        <div *ngIf="!isDashboardWidget" class="toggle-div">
            <label>Selectable</label>

            <cc-option-toggle
                id="is-widget-selectable"
                formControlName="isSelectable"
                [disabled]="disableEditGlobalWidget"
                [ngClass]="{'disabled-toggle': disableEditGlobalWidget}"
                [value]="!!saveWidgetForm!.get('isSelectable')!.value"
                [labels]="['On', 'Off']"
                (valueChange)="saveWidgetForm!.get('isSelectable')!.setValue($event)">
            </cc-option-toggle>
        </div>

        <div *ngIf="!isDashboardWidget" class="toggle-div">
            <label>Display Header</label>

            <cc-option-toggle
                id="display-widget-header"
                formControlName="enableWidgetHeader"
                [disabled]="disableEditGlobalWidget"
                [ngClass]="{'disabled-toggle': disableEditGlobalWidget}"
                [value]="!!saveWidgetForm!.get('enableWidgetHeader')!.value"
                [labels]="['On', 'Off']"
                (valueChange)="saveWidgetForm!.get('enableWidgetHeader')!.setValue($event)">
            </cc-option-toggle>
        </div>

        <div *ngIf="!isDashboardWidget" class="toggle-div">
            <label>Auto-refresh</label>

            <cc-option-toggle
                id="enable-widget-auto-refresh"
                formControlName="enableAutoRefresh"
                [disabled]="disableEditGlobalWidget"
                [ngClass]="{'disabled-toggle': disableEditGlobalWidget}"
                [value]="!!saveWidgetForm!.get('enableAutoRefresh')!.value"
                [labels]="['On', 'Off']"
                (valueChange)="saveWidgetForm!.get('enableAutoRefresh')!.setValue($event)">
            </cc-option-toggle>
        </div>

        <div *ngIf="!isDashboardWidget && isComparableWidget" class="toggle-div">
            <label>Compare Mode</label>

            <cc-option-toggle
                id="enable-widget-compare-mode"
                formControlName="enableCompareMode"
                [disabled]="disableEditGlobalWidget || isStackedQuery"
                [ngClass]="{'disabled-toggle': disableEditGlobalWidget || isStackedQuery}"
                [value]="!!saveWidgetForm!.get('enableCompareMode')!.value"
                [labels]="['On', 'Off']"
                (valueChange)="saveWidgetForm!.get('enableCompareMode')!.setValue($event)">
            </cc-option-toggle>
        </div>

        <div *ngIf="!isDashboardWidget" class="toggle-div">
            <label>View Filters</label>

            <cc-option-toggle
                id="widget-subscribe"
                formControlName="isSubscribedToDashboardFilters"
                [disabled]="isMultiClient || disableEditGlobalWidget"
                [ngClass]="{'disabled-toggle': isMultiClient || disableEditGlobalWidget}"
                [value]="!!saveWidgetForm!.get('isSubscribedToDashboardFilters')!.value"
                [labels]="['Subscribed', 'Unsubscribed']"
                (valueChange)="saveWidgetForm!.get('isSubscribedToDashboardFilters')!.setValue($event)">
            </cc-option-toggle>
        </div>

        <div class="sharing-settings">
            <div class="sharing-radio-btn-group">
                <h4>Sharing</h4>

                <div class="radio-btn" [hidden]="isDashboardWidget && !sharing_private.checked" [class.readonly]="isDashboardWidget">
                    <input
                        #sharing_private
                        id="sharing_private"
                        name="isShared"
                        type="radio"
                        formControlName="isShared"
                        [attr.disabled]="isDashboardWidget ? true : null"
                        [attr.checked]="sharing_private.checked ? 'checked' : null"
                        [value]=false />

                    <label for="sharing_private">
                        <span [hidden]="isDashboardWidget && sharing_private.checked"></span>
                        Private
                    </label>
                </div>

                <div class="radio-btn" [hidden]="isDashboardWidget && !sharing_shared.checked" [class.readonly]="isDashboardWidget">
                    <input
                        #sharing_shared
                        id="sharing_shared"
                        name="isShared"
                        type="radio"
                        formControlName="isShared"
                        [attr.disabled]="isDashboardWidget ? true : null"
                        [attr.checked]="sharing_shared.checked ? 'checked' : null"
                        [value]=true />

                    <label for="sharing_shared">
                        <span [hidden]="isDashboardWidget && sharing_shared.checked"></span>Shared
                    </label>
                </div>
            </div>

            <div class="settings-checkbox-group" *ngIf="isGlobal">
                <h4>Settings</h4>

                    <div class="global-indicator">
                        <ddv-global-icon></ddv-global-icon>
                        Global
                    </div>
            </div>
        </div>

        <div class="app-user-select" *ngIf="saveWidgetForm!.get('isShared')!.value" [class.readonly]="isDashboardWidget">
            <app-user-select
                id="view-user-select"
                appScrollToChild
                [title]="'View only:'"
                [featureType]="'WIDGET'"
                [(selectedUsers)]="userWidgetPermissions"
                [permission]="'VIEW'">
            </app-user-select>

            <app-user-select
                id="edit-user-select"
                appScrollToChild
                [title]="'View &amp; Edit:'"
                [featureType]="'WIDGET'"
                [(selectedUsers)]="userWidgetPermissions"
                [permission]="'EDIT'">
            </app-user-select>
        </div>

        <div class="tag user-tags">
            <h4>USER TAGS</h4>

            <app-custom-autocompleter [config]="tagsConfiguration" appScrollToChild></app-custom-autocompleter>
        </div>

        <div class="tag shared-tags">
            <h4>SHARED TAGS</h4>

            <app-custom-autocompleter [config]="tagsConfiguration" appScrollToChild></app-custom-autocompleter>
        </div>

        <div class="save-cancel">
            <button
                id="widget-save-as-cancel"
                class="empty-btn"
                type="button"
                (click)="modalDialogActive.close('cancel')"
            >
                Cancel
            </button>

            <button
                id="widget-save-as-submit"
                class="fill-btn"
                type="submit"
                [disabled]="isSaveDisabled()"
                routerlinkactive="selected"
                ng-reflect-router-link-active="selected"
            >
                Save
            </button>
        </div>
    </form>
