import { Injectable } from '@angular/core';
import { QueryParamsService } from '@ddv/filters';
import { DashboardPreference, VisualizationConfigs } from '@ddv/models';

@Injectable()
export class GridUriBuilderService {
    private dashboardQueryParams: DashboardPreference | undefined;

    constructor(private readonly queryParamsService: QueryParamsService) {
        this.queryParamsService.dashboardQueryParams.subscribe((dashboardQueryParams) => this.dashboardQueryParams = dashboardQueryParams);
    }

    buildUriWithDynamicValues(
        uri: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
        params: any,
        vizConfigs: VisualizationConfigs,
        queryPeriodTypeName: string | undefined,
    ): string {
        if (/{([^{}]+)}/g.test(uri)) {
            return this.resolveUriPlaceholders(uri, params);
        }

        return this.addQueryParams(uri, params, vizConfigs, queryPeriodTypeName);
    }

    private resolveUriPlaceholders(
        uri: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: any,
    ): string {
        return uri.replace(/{([^{}]+)}/g, (match: string, key: string): string => {
            if (key in params.data) {
                return encodeURIComponent(params.data[key]);
            }
            return match;
        });
    }

    private addQueryParams(
        uri: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: any,
        vizConfigs: VisualizationConfigs,
        queryPeriodTypeName: string | undefined,
    ): string {
        const dashboardQueryMapper: { [key: string]: string } = {
            queryPeriodType: this.getQueryPeriodType(queryPeriodTypeName),
            clients: this.getQueryClients(params, vizConfigs),
            dates: this.getQueryDates(),
            funds: this.getQueryFunds(),
            filters: this.getQueryFilters(),
        };

        const queryParamPairs = Object.entries(dashboardQueryMapper).map(([query, queryFnResponse]: [string, string]): string => `${query}=${queryFnResponse}`);
        return `${uri}?${queryParamPairs.join('&')}`;
    }

    private getQueryPeriodType(queryPeriodTypeName: string | undefined): string {
        return encodeURIComponent(JSON.stringify(queryPeriodTypeName));
    }

    private getQueryClients(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: any,
        vizConfigs: VisualizationConfigs,
    ): string {
        const isClientCodeSupplied = vizConfigs.configs?.values.map((c) => c.name).indexOf('clientCode') !== -1;
        const clients = (params.data.clientCode && isClientCodeSupplied ?
            [{
                clientId: params.data.clientCode,
                clientName: params.data.clientCode,
            }] :
            this.dashboardQueryParams?.clients) ?? [];

        return encodeURIComponent(JSON.stringify(clients));
    }

    private getQueryDates(): string {
        const dates = {
            startDate: this.dashboardQueryParams?.startDate,
            endDate: this.dashboardQueryParams?.endDate,
            activeDate: this.dashboardQueryParams?.activeDate,
        } ?? [];

        return encodeURIComponent(JSON.stringify(dates));
    }

    private getQueryFunds(): string {
        const funds = this.dashboardQueryParams?.funds?.map((f) => ({
            fundId: f.fundId,
            fundName: f.fundName,
        })) ?? [];

        return encodeURIComponent(JSON.stringify(funds));
    }

    private getQueryFilters(): string {
        const filters = this.dashboardQueryParams?.filters?.map((f) => ({
            criteria: f.criteria,
            name: f.name,
            displayName: f.displayName,
            values: f.values,
        })) ?? [];

        return encodeURIComponent(JSON.stringify(filters));
    }
}
