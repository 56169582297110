import { AppWidgetState, Mode, MODE, Widget } from '@ddv/models';
import { WidgetConfigurationManager } from '@ddv/widgets';

// this small method exists to break a nasty circular dep on Manager service that actually breaks webpack
export function appWidgetStateToConfig(
    widgets: AppWidgetState[] | undefined,
    workspaceMode: Mode,
): { widget: Widget, state: AppWidgetState }[] | undefined {
    if (!widgets) {
        return;
    }

    return widgets.map((state) => {
        const position = (workspaceMode === MODE.EDIT_WORKSPACE && state.maximized) ? undefined : state.widgetPosition;
        const widget: Widget = WidgetConfigurationManager.getWidgetConfigForWorkspace(state, position);
        widget.styleClasses = state.enableWidgetHeader ? '' : 'hide-header';
        widget.updateState(state);

        return { widget, state };
    });
}
