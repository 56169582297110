import { AgGridSortDirection } from '../data-grid/sort-direction';
import { NumberFormat, NumberUnit } from '../numbers';
import { IAxis } from './axis';
import { TableSortType } from './table-sort-type';
import { VisualizationType } from './visualization-type';
import { VizConfigs } from './viz-configs';
import { IZoomLevelInfo } from './zoom-level-info';

export class VisualizationConfigs {
    id?: number;
    isDefault?: boolean;
    visualizationType: VisualizationType;
    enableTotal?: boolean;
    enableTooltip?: boolean;
    enableLabels?: boolean | null;
    labelStyle?: 'showLines' | 'hideLines' | 'inline' | null;
    enableInsideLabel?: boolean;
    labelOrientation?: 'horizontal' | 'vertical' | null;
    enableTotalLabel?: boolean | null;
    highlightSlice?: boolean;
    inChartLabel?: boolean;
    showBrush?: boolean;
    zoomLevels?: IZoomLevelInfo[];
    showGridLines?: boolean;
    gridLineStyle?: string;
    slicer?: string | null;
    stacked?: boolean;
    xAxis?: string | null;
    yAxis?: string | null;
    axisConfigurations: IAxis[];
    showHoverLines?: boolean;
    numberUnits?: NumberUnit;
    numberFormat?: NumberFormat;
    decimalPlaces?: number;
    enableXAxisLabels?: boolean;
    enableYAxisLabels?: boolean;
    enableLegend?: boolean;
    legendPosition?: string;
    displaySeparator?: boolean;
    displayWidgetName?: boolean;
    enableSliceManagement?: boolean;
    sliceManagementType?: string;
    groupByPercent?: number;
    groupByMaxCount?: number;
    showOthers?: boolean;
    colorRange?: string[];
    bottomXAxisOrientation?: string;
    leftYAxisLabels?: boolean;
    configs?: VizConfigs;
    sortTableBy?: TableSortType;
    tableSortDirection?: string;
    sortOrderSlicer?: string;

    // Used for Grid only
    showGrandTotal?: boolean | null;
    showTotalAtTop?: boolean | null;
    showSubTotal?: boolean | null;
    showSubTotalAtTop?: boolean | null;
    alternateRowShading?: boolean | null;
    rowHeight?: number | null;
    showHyperlinks?: boolean | null;
    repeatGroupData?: boolean | null;
    autoSizeColumns?: boolean | null;
    showColumnHeaderToolTip?: boolean | null;
    isPivotMode?: boolean | null;
    hideGridHeaders?: boolean;
    showQuickSearch?: boolean;
    distinctRowsOnly?: boolean;
    groupDefaultExpanded?: number | null;
    groupSort?: AgGridSortDirection | null;
    groupSortIndex?: number | null;
    customWidthWhenGroup?: number;
    displayTotal?: boolean | null;
    displayTotalLabel?: string;
    showGrouperCount?: boolean | null;
    autoAddDynamicColumns?: boolean | null;
    useCustomHeaderNames?: boolean | null;

    // this is being returned by the API but was not previously on the type
    customName?: string | null;
    // this is being returned by the API but was not previously on the type
    showCustomName?: string | null;

    constructor(init?: Partial<VisualizationConfigs>) {
        this.id = init?.id;
        this.isDefault = init?.isDefault;
        this.visualizationType = init?.visualizationType ?? 'SIMPLE_GRID';
        this.enableTotal = init?.enableTotal;
        this.enableTooltip = init?.enableTooltip;
        this.enableLabels = init?.enableLabels;
        this.labelStyle = init?.labelStyle;
        this.enableInsideLabel = init?.enableInsideLabel;
        this.labelOrientation = init?.labelOrientation;
        this.enableTotalLabel = init?.enableTotalLabel;
        this.highlightSlice = init?.highlightSlice;
        this.inChartLabel = init?.inChartLabel;
        this.showBrush = init?.showBrush;
        this.zoomLevels = init?.zoomLevels ?? [];
        this.showGridLines = init?.showGridLines;
        this.gridLineStyle = init?.gridLineStyle;
        this.slicer = init?.slicer;
        this.stacked = init?.stacked;
        this.xAxis = init?.xAxis;
        this.yAxis = init?.yAxis;
        this.axisConfigurations = init?.axisConfigurations ?? [];
        this.showHoverLines = init?.showHoverLines;
        this.numberUnits = init?.numberUnits;
        this.numberFormat = init?.numberFormat;
        this.decimalPlaces = init?.decimalPlaces;
        this.enableXAxisLabels = init?.enableXAxisLabels;
        this.enableYAxisLabels = init?.enableYAxisLabels;
        this.enableLegend = init?.enableLegend;
        this.legendPosition = init?.legendPosition;
        this.displaySeparator = init?.displaySeparator;
        this.displayWidgetName = init?.displayWidgetName;
        this.enableSliceManagement = init?.enableSliceManagement;
        this.sliceManagementType = init?.sliceManagementType;
        this.groupByPercent = init?.groupByPercent;
        this.groupByMaxCount = init?.groupByMaxCount;
        this.showOthers = init?.showOthers ?? true;
        this.colorRange = init?.colorRange ?? [];
        this.bottomXAxisOrientation = init?.bottomXAxisOrientation;
        this.leftYAxisLabels = init?.leftYAxisLabels;
        this.configs = init?.configs;
        this.sortTableBy = init?.sortTableBy;
        this.tableSortDirection = init?.tableSortDirection;
        this.sortOrderSlicer = init?.sortOrderSlicer;
        this.showGrandTotal = init?.showGrandTotal;
        this.showTotalAtTop = init?.showTotalAtTop;
        this.showSubTotal = init?.showSubTotal;
        this.showSubTotalAtTop = init?.showSubTotalAtTop;
        this.alternateRowShading = init?.alternateRowShading;
        this.rowHeight = init?.rowHeight;
        this.showHyperlinks = init?.showHyperlinks;
        this.repeatGroupData = init?.repeatGroupData;
        this.autoSizeColumns = init?.autoSizeColumns;
        this.showColumnHeaderToolTip = init?.showColumnHeaderToolTip;
        this.isPivotMode = init?.isPivotMode;
        this.hideGridHeaders = init?.hideGridHeaders;
        this.showQuickSearch = init?.showQuickSearch;
        this.distinctRowsOnly = init?.distinctRowsOnly;
        this.groupDefaultExpanded = init?.groupDefaultExpanded;
        this.groupSort = init?.groupSort;
        this.groupSortIndex = init?.groupSortIndex;
        this.customWidthWhenGroup = init?.customWidthWhenGroup;
        this.displayTotal = init?.displayTotal;
        this.displayTotalLabel = init?.displayTotalLabel;
        this.showGrouperCount = init?.showGrouperCount;
        this.autoAddDynamicColumns = init?.autoAddDynamicColumns;
        this.useCustomHeaderNames = init?.useCustomHeaderNames;
        this.customName = init?.customName;
        this.showCustomName = init?.showCustomName;

        this.configs = new VizConfigs(this.configs);
    }

    // the ? is here so that we can pass things that aren't actually classes of type around as
    // though they are.  for example, something like { visualizationType: 'foo' }
    // doing this is, of course, incredibly stupid
    isAdvancedGrid?(): boolean {
        return this.visualizationType === 'ADVANCED_GRID';
    }
}
