import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { DatasetDefinitionDetails, FuzzyDate, FuzzyDates, NamedQuery, QueryTypeName } from '@ddv/models';
import { NamedQueriesService } from '@ddv/named-queries';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FuzzyDatesService {
    private fuzzyDates$: Observable<FuzzyDates> | undefined;
    private readonly adhocFuzzyDatesSubject: Subject<FuzzyDateInit[]> = new BehaviorSubject([] as FuzzyDateInit[]);
    private readonly investorFuzzyDatesSubject: Subject<FuzzyDateInit[]> = new BehaviorSubject([] as FuzzyDateInit[]);

    constructor(
        private readonly currentState: CurrentStateService,
        private readonly errorHandler: ErrorHandler,
        private readonly datasetDefinitionService: DatasetDefinitionsService,
        private readonly namedQueriesService: NamedQueriesService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
    ) {}

    private get fuzzyDatesEndpoint(): Observable<{ clientCode: string, route: string }> {
        return this.currentState.clientCode$
            .pipe(map((clientCode) => {
                return {
                    clientCode,
                    route: '/reference-data/fuzzy-dates',
                };
            }));
    }

    private get hiFuzzyDatesEndpoint(): Observable<{ clientCode: string, route: string }> {
        return this.currentState.clientCode$
            .pipe(map((clientCode) => {
                return {
                    clientCode,
                    route: '/investors/fuzzy_dates',
                };
            }));
    }

    private getAdhocFuzzyDatesEndpoint(rootDir: string): Observable<{ clientCode: string, route: string }> {
        return this.currentState.clientCode$
            .pipe(map((clientCode) => {
                return {
                    clientCode,
                    route: `/adhoc/fuzzy_dates/${rootDir}`,
                };
            }));
    }

    fuzzyDates(): Observable<FuzzyDates> {
        if (!this.fuzzyDates$) {
            const fetches: [Observable<FuzzyDates>, Observable<FuzzyDateInit[]>, Observable<FuzzyDateInit[]>] = [
                this.getFuzzyDates(),
                this.adhocFuzzyDatesSubject.asObservable(),
                this.investorFuzzyDatesSubject.asObservable(),
            ];
            this.fuzzyDates$ = combineLatest(fetches)
                .pipe(map(([fuzzyDates, adhocFuzzyDates, investorFuzzyDates]) => (
                    this.updateFuzzyDatesList(fuzzyDates, adhocFuzzyDates, investorFuzzyDates))),
                )
                .pipe(shareReplay(1)); // replays only most recent
        }
        return this.fuzzyDates$;
    }

    private getFuzzyDates(): Observable<FuzzyDates> {
        return this.fuzzyDatesEndpoint
            .pipe(mergeMap(({ clientCode, route }) => this.trebekApiExecutor.invokeServiceWithParams<FuzzyDatesInit>(clientCode, route)))
            .pipe(map((data) => this.mapFuzzyDates(data)));
    }

    private mapFuzzyDates(data: FuzzyDatesInit): FuzzyDates {
        let fromList: FuzzyDate[] = [];
        let toList: FuzzyDate[] = [];

        if (data?.to && data?.from) {
            fromList = data.from.map((fuzzyDate) => (new FuzzyDate(fuzzyDate.name, fuzzyDate.currentValue)));
            toList = data.to.map((fuzzyDate) => (new FuzzyDate(fuzzyDate.name, fuzzyDate.currentValue)));
        } else {
            this.errorHandler.handleError(new Error(`Failed to load fuzzy dates from ${JSON.stringify(data)}`));
        }

        return new FuzzyDates(toList, fromList);
    }

    private updateFuzzyDatesList(
        fuzzyDates: FuzzyDates,
        adHocFuzzyDates: FuzzyDateInit[],
        investorFuzzyDates: FuzzyDateInit[],
    ): FuzzyDates {
        let result = new FuzzyDates([...fuzzyDates.to], [...fuzzyDates.to]);

        if (adHocFuzzyDates.length) {
            result = this.mergeAdHocFuzzyDates(result, adHocFuzzyDates);
        }

        if (investorFuzzyDates.length) {
            result = this.mergeInvestorFuzzyDates(result, investorFuzzyDates);
        }

        return result;
    }

    private mergeAdHocFuzzyDates(fuzzyDates: FuzzyDates, adHocFuzzyDates: FuzzyDateInit[]): FuzzyDates {
        adHocFuzzyDates.forEach((fd) => {
            if (fuzzyDates.to.findIndex((fuzzy) => fuzzy.name === fd.name) === -1 &&
                fuzzyDates.from.findIndex((fuzzy) => fuzzy.name === fd.name) === -1) {
                fuzzyDates.to.push(new FuzzyDate(fd.name, fd.currentValue));
                fuzzyDates.from.push(new FuzzyDate(fd.name, fd.currentValue));
            }
        });
        return fuzzyDates;
    }

    private mergeInvestorFuzzyDates(fuzzyDates: FuzzyDates, investorFuzzyDates: FuzzyDateInit[]): FuzzyDates {
        investorFuzzyDates.forEach((fd) => {
            if (fuzzyDates.to.findIndex((fuzzy) => fuzzy.name === fd.name) === -1) {
                fuzzyDates.to.push(new FuzzyDate(fd.name, fd.currentValue));
            }
        });
        return fuzzyDates;
    }

    pushMostRecentFuzzyDatesForAdhocDataset(
        datasetDefinitionDetails: DatasetDefinitionDetails | NamedQuery,
    ): Observable<void> {
        return this.getAdhocFuzzyDatesEndpoint(datasetDefinitionDetails.getRootDir())
            .pipe(mergeMap(({ clientCode, route }) => {
                return this.trebekApiExecutor.invokeServiceWithParams<FuzzyDateInit[]>(clientCode, route)
                    .pipe(map((fuzzyDates: FuzzyDateInit[]) => this.adhocFuzzyDatesSubject.next(fuzzyDates)));
            }));
    }

    pushMostRecentFuzzyDatesForInvestorDataset(funds: { code: string }[], dsdIds: (number | string | undefined)[]): Observable<void> {
        const body = { includeFunds: funds };
        const dsdObservables = dsdIds.map((dsdId) =>
            typeof dsdId === 'number' ?
                this.datasetDefinitionService.fetchDatasetDefinitionDetails(dsdId) :
                this.namedQueriesService.fetchNamedQuery(dsdId!));

        return combineLatest(dsdObservables)
            .pipe(mergeMap((dsds: (NamedQuery | DatasetDefinitionDetails)[]) => {
                const activities = dsds.map((dsd) =>
                    dsd.normalizeQueryTypeName() === QueryTypeName.INVESTORS_ACTIVITY);

                return activities.includes(false) ? of(undefined) : this.hiFuzzyDatesEndpoint;
            }))
            .pipe(mergeMap((endpoint) => {
                if (endpoint) {
                    return this.trebekApiExecutor.invokeServiceWithBody<FuzzyDateInit[]>(endpoint.clientCode, endpoint.route, 'POST', body, { catchError: false })
                        .pipe(map((fuzzyDates: FuzzyDateInit[]) => this.investorFuzzyDatesSubject.next(fuzzyDates)));
                }
                return of(endpoint)
                    .pipe(map(() => this.investorFuzzyDatesSubject.next([])));
            }));
    }

    emptyAdHocFuzzyDatesList(): void {
        this.adhocFuzzyDatesSubject.next([]);
    }

    emptyInvestorFuzzyDatesList(): void {
        this.investorFuzzyDatesSubject.next([]);
    }
}

export interface FuzzyDateInit {
    name: string;
    currentValue: string;
}

export interface FuzzyDatesInit {
    to: FuzzyDateInit[];
    from: FuzzyDateInit[];
}
