import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { ModalDialogService } from '@ddv/common-components';
import { DashboardFiltersService } from '@ddv/dashboards';
import { MetadataService } from '@ddv/datasets';
import {
    DataConfigSummary,
    FieldMetadata,
    MetadataFormatUtils,
    SummaryColumnConfig,
    SummaryColumnFilter,
    SummaryConfig,
    Summary,
    ValueFilterOption,
    MANAGE_WIDGET_ID,
} from '@ddv/models';
import { clone, deepExtend } from '@ddv/utils';

import { FieldMetadataService } from '../../../services/field-metadata.service';
import { ColumnFormattingSummaryComponent } from '../column-formatting-summary/column-formatting-summary.component';
import { ConfigureComponent, DropZoneConfig } from '../configure/configure.component';
import * as dropZoneConfig from '../drop-zone-config';

@Component({
    selector: 'app-mw-summary-configure',
    templateUrl: './configure-summary.component.html',
    styleUrls: ['../configure/configure.component.scss'],
})
export class ConfigureSummaryComponent extends ConfigureComponent {
    readonly maxSummaries = 10;
    override dataConfigs: { summary: DataConfigSummary, filters: SummaryColumnFilter[] }[] = [];
    filterValues: ValueFilterOption[] = [];
    filterSearchValue: string | undefined;

    constructor(
        private readonly modalService: ModalDialogService,
        public dashboardFiltersService: DashboardFiltersService,
        metadataService: MetadataService,
        fieldMetadataService: FieldMetadataService,
    ) {
        super(metadataService, fieldMetadataService);

        this.initDashboardFilter();
    }

    override initDataConfigs(): void {
        const isRendered = this.hasRequiredFields();
        if (this.dataConfigs.length < this.maxSummaries) {
            this.dataConfigs.push({ summary: { isEmpty: true }, filters: [] });
            if (isRendered) {
                this.renderPreview();
            }
        }
    }

    getDropZones(): DropZoneConfig[] {
        return dropZoneConfig.summary;
    }

    showColumnFormatters(summaryConfig: DataConfigSummary): void {
        const dialogRef = this.modalService.open(ColumnFormattingSummaryComponent, { windowClass: 'column-formatter-popup' });
        dialogRef.componentInstance.parentData = {
            isAttribute: summaryConfig.isAttribute,
            columnName: summaryConfig.summaryLabel,
            displayType: summaryConfig.displayType,
            columnConfiguration: summaryConfig.summaryWidgetColumnConfiguration,
        };
        dialogRef.result.then(
            (response: SummaryColumnConfig | 'cancel') => {
                if (response !== 'cancel') {
                    if (response.aggregationType === 'count' || response.aggregationType === 'countDistinct' ||
                        response.aggregationType === 'countExcludingZeros' || response.aggregationType === 'countDistinctExcludingZeros') {
                        response.numberFormat = 'XX';
                    }
                    summaryConfig.summaryWidgetColumnConfiguration = response;
                    this.renderPreview();
                }
            },
            (dismissData) => dismissData);
    }

    toggleFilterValues(filterValuesList: HTMLElement, summaryColFilter: SummaryColumnFilter, configIndex: number, index: number): void {
        if (filterValuesList.hidden) {
            this.showFiltersValues(filterValuesList, summaryColFilter.filterField, configIndex, index);
        } else {
            this.hideFiltersValues(filterValuesList, configIndex, index);
        }
    }

    showFiltersValues(filterValuesList: HTMLElement, attribute: string, configIndex: number, index: number): void {
        filterValuesList.hidden = false;
        this.filterValues = this.dashboardFiltersService.getValueOptionsByAttribute(attribute);
        const summaryWidgetFilterValues = this.dataConfigs[configIndex].filters[index].summaryWidgetFilterValues;

        summaryWidgetFilterValues.forEach((summaryWidgetFilterValue) => {
            const summaryValue = summaryWidgetFilterValue.filterValue == null ? '' : summaryWidgetFilterValue.filterValue.toString();
            const summaryFilterValue = this.filterValues.find((filterWidgetValue) => {
                const filterValue = filterWidgetValue.value == null ? '' : filterWidgetValue.value.toString();

                return filterValue.toLocaleLowerCase() === summaryValue.toLocaleLowerCase();
            });

            if (summaryFilterValue) {
                summaryFilterValue.checked = true;
            } else {
                this.insertIntoFilterValues(summaryWidgetFilterValue.filterValue);
            }
        });
    }

    hideFiltersValues(filterValuesList: HTMLElement, configIndex: number, index: number): void {
        if (filterValuesList.hidden) {
            return;
        }
        filterValuesList.hidden = true;
        const colFilterConfig = this.dataConfigs[configIndex].filters;
        colFilterConfig[index].summaryWidgetFilterValues = [];
        this.filterValues.forEach((filterValue) => {
            if (filterValue.checked) {
                colFilterConfig[index].summaryWidgetFilterValues.push({ filterValue: filterValue.value });
            }
        });
        this.renderPreview();
    }

    uncheckFilterValues(): void {
        this.filterValues.forEach((filterValue) => filterValue.checked = false);
    }

    addToFilterValues(): void {
        if (this.filterSearchValue?.trim() &&
            !this.filterValues.some((filterValue) => filterValue.value.toLowerCase() === this.filterSearchValue?.toLowerCase())) {
            this.insertIntoFilterValues(this.filterSearchValue);
        }
        this.filterSearchValue = '';
    }

    dragStarted(metadata: FieldMetadata): void {
        this.dropZones?.forEach((item) => {
            item.allowed = !!((item.configKey === 'summary') ?
                this.dataConfigs.some((config) => config.summary.isEmpty) :
                metadata.isAttribute);
        });
    }

    onCdkDropSuccess(
        data: CdkDragDrop<{ summary: DataConfigSummary, filters: SummaryColumnFilter[] }, unknown>,
        zone: DropZoneConfig,
        index: number,
    ): void {
        if (!zone.allowed) {
            return;
        }
        let isDropSuccess = false;
        if (zone.configKey === 'summary' && this.dataConfigs[index].summary.isEmpty) {
            const isDate = data.item.data.datatype === 'date';
            this.dataConfigs[index].summary = {
                isAttribute: data.item.data.isAttribute,
                summaryLabel: data.item.data.displayName,
                summaryField: data.item.data.value,
                displayType: data.item.data.displayType,
                summaryWidgetColumnConfiguration: clone(MetadataFormatUtils
                    .getDefaultSummaryColFormatter(data.item.data.isAttribute, isDate)),
                isEmpty: false,
            };
            isDropSuccess = true;
        } else if (zone.configKey === 'filters' &&
            !this.dataConfigs[index].filters.some((dataConfig) => dataConfig.filterField === data.item.data.value)) {
            this.dataConfigs[index].filters.push({
                filterLabel: data.item.data.displayName,
                filterField: data.item.data.value,
                summaryWidgetFilterValues: [],
            });
            isDropSuccess = true;
        }
        if (isDropSuccess) {
            this.renderPreview();
        }
        this.activelyDragging = false;
    }

    removeConfigItemAtIndex(configKey: 'summary' | 'filters', configIndex: number, index?: number): void {
        const summaryConfig = this.dataConfigs[configIndex];
        if (configKey === 'filters' && index !== undefined) {
            summaryConfig.filters.splice(index, 1);
        } else {
            summaryConfig.summary = { isEmpty: true };
        }
        if (this.dataConfigs.length > 1 && summaryConfig.summary.isEmpty && !summaryConfig.filters.length) {
            this.dataConfigs.splice(configIndex, 1);
        }
        this.renderPreview();
    }

    override getSelectedConfigs(): SummaryConfig {
        return {
            summaries: this.dataConfigs.reduce((accum, item) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accum.push(deepExtend({}, [item.summary, { summaryWidgetFilters: item.filters }]) as any);
                return accum;
            }, [] as Summary[]),
        };
    }

    /** For Edit/Find Widget flow ***/
    setDataConfigs(summaryConfig: SummaryConfig): void {
        summaryConfig.summaries.forEach((summary) => {
            this.dataConfigs.push({
                summary: {
                    id: summary.id,
                    summaryLabel: summary.summaryLabel,
                    summaryField: summary.summaryField,
                    summaryWidgetColumnConfiguration: summary.summaryWidgetColumnConfiguration,
                    isEmpty: false,
                },
                filters: summary.summaryWidgetFilters.map((summaryWidgetFilter) => ({
                    filterLabel: summaryWidgetFilter.filterLabel,
                    filterField: summaryWidgetFilter.filterField,
                    summaryWidgetFilterValues: summaryWidgetFilter.summaryWidgetFilterValues,
                })),
            });
        });
    }

    override hasRequiredFields(): boolean {
        return !this.dataConfigs.some((dataConfig) => !dataConfig.filters.length || dataConfig.summary.isEmpty);
    }

    afterMetadataUpdate(): void {
        if (this.dataConfigs.some((dataConfig) => typeof dataConfig.summary.id === 'number')) {
            this.dataConfigs.forEach((dataConfig) => {
                const metaDatum = this.metadata.find((metadatum) => metadatum.value === dataConfig.summary.summaryField);
                dataConfig.summary.displayType = metaDatum?.displayType;
                dataConfig.summary.isAttribute = metaDatum?.isAttribute;
            });
        }
    }

    getFilterChecked(filter: ValueFilterOption): boolean {
        return !!filter.checked;
    }

    setFilterChecked(event: boolean, filter: ValueFilterOption): void {
        filter.checked = event;
    }

    private initDashboardFilter(): void {
        this.dashboardFiltersService.setFilterType('coreWidget');
        this.dashboardFiltersService.setSlicerValues(MANAGE_WIDGET_ID);
    }

    private insertIntoFilterValues(filterValue: string): void {
        this.filterValues.unshift({
            value: filterValue,
            text: filterValue,
            checked: true,
        });
    }
}
