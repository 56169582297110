import { DatasetFetchRuntimeParams } from '../query';
import { QueryTemplateHolder } from '../query-template-holder';
import { QueryType } from './query-type';

export type RuntimeParameterType = 'string' | 'fund-list' | 'date' | 'date-range' | 'boolean';

export interface RuntimeParameter {
    name: string;
    displayName: string;
    type: RuntimeParameterType;
    required: boolean;
}

export interface NamedQueryInit {
    readonly template: string;
    readonly periodType: QueryPeriodType;
    readonly type: QueryType;
    readonly crosstalkOptions?: CrosstalkOptions;
    readonly clientAllocationIsLocked?: boolean;
    readonly isOptIn: boolean;
    readonly clientCodes: string[];
    readonly name: string;
    readonly tags: string;
    id: string;
    createdDate: Date;
    createdBy: string;
    updatedDate?: Date;
    updatedBy?: string;
    datasetDefinitionId?: string;
    parameters?: RuntimeParameter[];
}

export interface WidgetInNamedQuery {
    globalClients: string[];
    id: number;
    isGlobal: boolean;
    name: string;
    dashboards: {
        globalClients: string[];
        id: number;
        isGlobal: boolean;
        name: string;
    }[];
}

export class NamedQuery extends QueryTemplateHolder {
    name: string;
    tags: string;
    template: string;
    periodType: QueryPeriodType;
    type: QueryType;
    isOptIn: boolean;
    clientCodes: string[];
    createdDate?: Date;
    createdBy?: string;
    updatedDate?: Date | null;
    updatedBy?: string | null;
    crosstalkOptions?: CrosstalkOptions;
    datasetDefinitionId?: string;
    parameters?: RuntimeParameter[];

    widgets?: WidgetInNamedQuery[];

    constructor(init: NamedQueryInit, config: MetadataAndQueryURLsConfig) {
        super(init.id);

        this.name = init.name ?? '';
        this.tags = init.tags ?? '';
        this.template = init.template ?? '';
        this.periodType = init.periodType;
        this.type = init.type;
        this.isOptIn = init.isOptIn;
        this.clientCodes = init.clientCodes ?? [];
        this.createdDate = init.createdDate;
        this.createdBy = init.createdBy;
        this.updatedDate = init.updatedDate;
        this.updatedBy = init.updatedBy;
        this.crosstalkOptions = init.crosstalkOptions;
        this.datasetDefinitionId = init.datasetDefinitionId;
        this.parameters = init.parameters;
        this.widgets = [];

        this.periodType = init.periodType;
        this.type = this.getQueryType(init.type, config);
    }

    getRootDir(): string {
        const dummyParams: DatasetFetchRuntimeParams = {
            timeseriesGranularity: '',
            dateRange: { from: '', to: '' },
            fundCodes: [],
            includeComments: true,
            includeLinks: true,
            clientCodeList: [],
            specialCaseParams: {},
        };
        return this.populateQueryTemplate(dummyParams).rootFMPath ?? 'NO ROOT FM PATH';
    }

    normalizeConversableType(): string | undefined {
        return this.crosstalkOptions?.conversableType;
    }

    normalizeQueryTypeName(): string {
        return this.type.name;
    }

    normalizeQueryTemplate(): string {
        return this.template;
    }

    normalizeQueryEndPoint(): string {
        return this.type.dataEndpoint;
    }

    normalizeQueryIsStacked(): boolean {
        return this.template.indexOf('@@UNION@@') !== -1;
    }

    private getQueryEndpoint(replaceHostInURLs: boolean, apiLocation: string): string {
        const dataEndpoint = this.type.dataEndpoint;
        if (replaceHostInURLs) { // since we are getting a URL to Trebek Edge for localhost, we need to adjust
            const pathname = new URL(dataEndpoint).pathname;
            const dataEndpointPath = pathname.substring(pathname.indexOf('rest'), pathname.length);
            return `${apiLocation}/public-api/${dataEndpointPath}`;
        }
        return dataEndpoint;
    }

    private getMetadataEndpoint(replaceHostInURLs: boolean, apiLocation: string): string {
        const metadataEndpoint = this.type.metadataEndpoint;
        if (replaceHostInURLs) { // since we are getting a URL to NQS RND for localhost, we need to adjust
            const metadataEndpointPath = new URL(metadataEndpoint).pathname;
            return `${apiLocation}${metadataEndpointPath}`;
        }
        return metadataEndpoint;
    }

    private getQueryType(queryType: QueryType, config: MetadataAndQueryURLsConfig): QueryType {
        return {
            ...queryType,
            dataEndpoint: this.getQueryEndpoint(config.replaceHostInURLs, config.trebekApiLocation),
            metadataEndpoint: this.getMetadataEndpoint(config.replaceHostInURLs, config.cfbApiLocation),
        };
    }
}

export interface MetadataAndQueryURLsConfig {
    replaceHostInURLs: boolean;
    trebekApiLocation: string;
    cfbApiLocation: string;
}

interface CrosstalkOptions {
    readonly conversableType: string;
    readonly showDuplicateRowsWarning: boolean;
}

type QueryPeriodType = 'No Calendar' | 'Point in Time' | 'Range' | 'Range with Active Date';
