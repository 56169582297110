import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ButtonLabel, DashboardNote } from '../../models/dashboard-note';

@Component({
    selector: 'app-dashboard-notes',
    templateUrl: 'dashboard-notes.component.html',
    styleUrls: ['dashboard-notes.component.scss'],
})
export class DashboardNotesComponent implements OnInit {
    @Input() inPresentationMode = false;
    @Input() dashboardNote: DashboardNote | undefined;
    @Input() isGlobal = false;
    @Input() isGlobalEditPartial = false;

    @Output() closeDashboardNotesModal: EventEmitter<void> = new EventEmitter();
    @Output() openConfigureDashboardNotesModal: EventEmitter<void> = new EventEmitter();

    protected listIconHighlight = false;
    protected editIconHighlight = false;
    protected closeButtonText: 'Got It' | ' Edit Note' | undefined;
    protected buttonLabel: ButtonLabel = 'View Notes';
    protected title: string | undefined;
    protected notes: string[] = [];

    ngOnInit(): void {
        this.populateDashboardNote();
    }

    protected onClose(): void {
        this.closeDashboardNotesModal.emit();

        if (this.inEditMode()) {
            this.openConfigureDashboardNotesModal.emit();
        }
    }

    private populateDashboardNote(): void {
        this.closeButtonText = this.inEditMode() ? ' Edit Note' : 'Got It';

        if (this.dashboardNote) {
            this.buttonLabel = this.dashboardNote.buttonLabel;
            this.title = this.dashboardNote.title;
            this.notes = this.dashboardNote.message.split('\n');
        }
    }

    private inEditMode(): boolean {
        return !this.inPresentationMode && (!this.isGlobal || !this.isGlobalEditPartial);
    }
}
