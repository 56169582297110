import { Mode } from '../application-mode';
import { Client } from '../client';
import { DatasetDefinition } from '../dataset-definitions/dataset-definition';
import { DateRangeString } from '../date-range-string';
import { DdvDate } from '../ddv-date';
import { DashboardFilter } from '../filters/dashboard-filter';
import { FilterQueryParam } from '../filters/filter-query-param';
import { Fund } from '../fund';
import { QueryPeriodTypeName } from '../named-queries/query-type';
import { AppWidgetState } from '../widgets/app-widget-state';
import { CompareMode } from './compare-mode';

export interface MinimumFilterParams {
    startDate?: string;
    endDate?: string;
    fundsCodes: string[];
    clientsCodes: string[];
}

// this should probably come out of dashboard so that there isn't
// a circular import path between dashboard and widget model
export interface DashboardPreference {
    id?: number;
    funds?: Fund[];
    filters?: DashboardFilter[];
    startDate?: string;
    endDate?: string;
    activeDate?: string | null;
    dashboardPreferenceMode?: Mode;
    highlight?: string | null;
    version?: number;
    isPreferenceChangedExplicitly?: boolean;
    areFiltersAppliedByMaster?: boolean;
    updatedDate?: string;
    isComparing?: boolean;
    compareDates?: DateRangeString;
    comparing?: CompareMode;
    compareWidgetID?: number;
    isPreferenceChangedOnRefresh?: boolean;
    clients?: Client[];
    acknowledged?: boolean;
    includeManuallyReleased?: boolean;
}

export function toDefaultQueryParams(
    fundOptions: Fund[],
    clientOptions: Client[],
    extraParametersForWorkspace: { widgets: AppWidgetState[] } | undefined,
): DashboardPreference {
    const widgetDatasets: (DatasetDefinition | undefined)[] =
        extraParametersForWorkspace?.widgets?.map((w: AppWidgetState) => w.datasetDefinition) ?? [];
    const isCalendarVisible = !widgetDatasets.length ||
        widgetDatasets.some((dsd) => dsd?.queryPeriodType?.name !== QueryPeriodTypeName.NO_CALENDAR);
    const yesterdayDate: string = DdvDate.today.addDays(-1).toUSPaddedFormat();
    return {
        funds: fundOptions,
        startDate: isCalendarVisible ? yesterdayDate : '',
        endDate: isCalendarVisible ? yesterdayDate : '',
        filters: [],
        clients: clientOptions,
    };
}

export type QueryParamsBuilder = (
    fundOptions: Fund[],
    clientOptions: Client[],
    extraParametersForWorkspace: { widgets: AppWidgetState[] } | undefined,
    userPreferences?: MinimumFilterParams,
) => FilterQueryParam;

export function toPrioritizedFilterParams(
    fundOptions: Fund[],
    clientOptions: Client[],
    extraParametersForWorkspace: { widgets: AppWidgetState[] } | undefined,
    userPreferences?: MinimumFilterParams,
): FilterQueryParam {
    const filterParam = toDefaultQueryParams(fundOptions, clientOptions, extraParametersForWorkspace);
    if (userPreferences?.startDate && userPreferences?.endDate) {
        filterParam.startDate = userPreferences.startDate;
        filterParam.endDate = userPreferences.endDate;
    }

    filterParam.activeDate = filterParam.endDate;
    filterParam.funds = userPreferences?.fundsCodes.length ?
        (fundOptions?.filter((f) => userPreferences?.fundsCodes.includes(f.fundId)) || []) :
        filterParam.funds;
    filterParam.clients = userPreferences?.clientsCodes.length ?
        (clientOptions?.filter((c) => userPreferences?.clientsCodes.includes(c.clientId)) || []) :
        filterParam.clients;

    return filterParam;
}
