// don't split these models into separate files.  it REALLY angers webpack

import { Mode } from '../application-mode';
import { MODE } from '../constants/dashboard-constants';
import { DatasetDefinition } from '../dataset-definitions/dataset-definition';
import { UserPermission } from '../entitlements/user-permission';
import { isInvestorQueryType } from '../named-queries/query-type';
import { Tag } from '../tag';
import { AppWidgetState } from '../widgets/app-widget-state';
import { DashboardPreference } from './dashboard-preference';
import { DeviceMode } from './device-mode';
import { WidgetOnBoard } from './widget-on-board';

export class DashboardSnapshot {
    id: string | undefined;
    name: string | undefined;
    description: string | undefined;
    isShared: boolean | undefined;
    dashboardTags: Tag[];
    isDefault: boolean | undefined;
    isGlobal: boolean | undefined;
    createdBy: string | undefined;
    createdDate: string | undefined;
    abbreviation: string | undefined;
    allowInfiniteVScroll: boolean | undefined;
    allowInfiniteHScroll: boolean | undefined;
    privilege: string | undefined;
    clientId: string | undefined;
    iconClass: string | undefined;
    updatedDate: string | undefined;

    constructor(init?: Partial<DashboardSnapshot>) {
        this.id = init?.id;
        this.name = init?.name;
        this.description = init?.description;
        this.isShared = init?.isShared;
        this.dashboardTags = init?.dashboardTags ?? [];
        this.isDefault = init?.isDefault;
        this.isGlobal = init?.isGlobal;
        this.createdBy = init?.createdBy;
        this.createdDate = init?.createdDate;
        this.abbreviation = init?.abbreviation;
        this.allowInfiniteVScroll = init?.allowInfiniteVScroll;
        this.allowInfiniteHScroll = init?.allowInfiniteHScroll;
        this.privilege = init?.privilege;
        this.clientId = init?.clientId;
        this.iconClass = init?.iconClass;
        this.updatedDate = init?.updatedDate;

        this.iconClass = this.deriveIconClass();
    }

    deriveIconClass(): string {
        if (this.isGlobal) {
            return 'global';
        }

        if (this.isShared) {
            return 'shared';
        }

        return 'private';
    }

    cloneForCopy(): DashboardModel {
        const clone = new DashboardModel(this);
        clone.isGlobal = false;
        clone.dashboardPermissions = [];
        clone.dashboardTags = clone.dashboardTags.filter((tags) => tags.type === 'USER');
        clone.name += ' copy';
        return clone;
    }
}

export class DashboardModel extends DashboardSnapshot {
    widgets: AppWidgetState[];
    dashboardPreferences: DashboardPreference[];
    dashboardPermissions: UserPermission[];
    mode: Mode = 'view';
    deviceMode: DeviceMode = 'DESKTOP';
    version: number = Number.MIN_VALUE;

    constructor(init?: Partial<DashboardModel>) {
        super(init);

        this.dashboardPreferences = init?.dashboardPreferences ?? [];
        this.dashboardPermissions = init?.dashboardPermissions ?? [];
        this.mode = init?.mode ?? 'view';
        this.deviceMode = init?.deviceMode ?? 'DESKTOP';
        this.version = init?.version ?? Number.MIN_VALUE;

        this.widgets = init?.widgets ?
            init.widgets.map((widget) => new AppWidgetState(widget)) :
            [];
    }

    getQueryParamVersion(): number {
        return this.dashboardPreferences.find((preference) => {
            return preference.dashboardPreferenceMode === MODE.EDIT_WORKSPACE;
        })?.version ?? Number.MIN_VALUE;
    }
}

export class DashboardDetails extends DashboardSnapshot {
    widgets: AppWidgetState[];
    widgetOnBoards: WidgetOnBoard[];
    dashboardPreferences: DashboardPreference[];
    dashboardPermissions: UserPermission[];
    layoutType?: string;
    parentDashboardId?: string;
    deviceMode?: DeviceMode;
    version?: number;

    constructor(init?: Partial<DashboardDetails>) {
        super(init);

        this.dashboardPreferences = init?.dashboardPreferences ?? [];
        this.dashboardPermissions = init?.dashboardPermissions ?? [];
        this.layoutType = init?.layoutType;
        this.parentDashboardId = init?.parentDashboardId;
        this.deviceMode = init?.deviceMode;
        this.version = init?.version;

        // This is because sometimes they come in as numbers
        this.id = this.id?.toString();
        this.widgetOnBoards = init?.widgetOnBoards ?
            init.widgetOnBoards.map((json) => new WidgetOnBoard(json)) :
            [];
        this.widgets = this.widgetOnBoards.map((wob) => new AppWidgetState(wob.widget));
    }

    isSingleDataset(): boolean {
        return this.widgetOnBoards.reduce((distinctDSDIds: (number | string)[], wob) => {
            const dsdId = wob.widget?.datasetDefinition?.id;
            if (dsdId && !distinctDSDIds.includes(dsdId)) {
                distinctDSDIds.push(dsdId);
            }

            return distinctDSDIds;
        }, []).length === 1;
    }

    isInvestorOnly(): boolean {
        return !!this.widgets.length && this.areAllWidgetsInvestor();
    }

    firstDatasetDefinition(): DatasetDefinition | undefined {
        const firstWidget = this.widgetOnBoards[0].widget;
        if (firstWidget?.namedQueryId && firstWidget.datasetDefinition) {
            firstWidget.datasetDefinition.namedQueryId = firstWidget.namedQueryId;
        }
        return firstWidget?.datasetDefinition;
    }

    getAllFundCodes(): { code: string }[] {
        return this.dashboardPreferences?.[0]?.funds?.map((fund) => ({ code: fund.fundId })) ?? [];
    }

    getAllDSDAndNamedQueryIds(): (number | string | undefined)[] {
        return this.widgets?.map((w) => {
            if (w?.namedQueryId) {
                return w.namedQueryId;
            }
            return w?.datasetDefinition?.id ?? 0;
        }) ?? [];
    }

    private areAllWidgetsInvestor(): boolean {
        const widgetsWithDSDs = this.getAllWidgetsWithDSDs();
        const widgetsWithNamedQueries = this.getAllWidgetsWithNamedQueries();

        return widgetsWithDSDs.every((w) => w?.datasetDefinition?.dataType === 'Investor') &&
            widgetsWithNamedQueries.every((w) => isInvestorQueryType(w?.datasetDefinition?.queryType?.name));
    }

    private getAllWidgetsWithDSDs(): AppWidgetState[] {
        return this.widgets.filter((w) => w?.datasetDefinition?.id !== -1);
    }

    private getAllWidgetsWithNamedQueries(): AppWidgetState[] {
        return this.widgets.filter((w) => w?.namedQueryId);
    }
}
