<div class="dropdown-settings" [class.activesmallscreen]="isSmallDevice">
    <div class="user-detail-block">
        <span>{{ fullName }}</span>

        <span>{{ roleName }}</span>
    </div>

    <div class="app-version">
        <label class="option-label text-label">DDV UI version</label>
        <span class="option">{{ versions?.ui }}</span>

        <label class="option-label text-label">DDV MW version</label>
        <span class="option">{{ versions?.mw }}</span>

        <label class="option-label text-label">DDV API version</label>
        <span class="option">{{ versions?.api }}</span>
    </div>

    <div class="auto-dismiss-error">
        <label class="option-label">Auto Dismiss Alerts</label>

        @if (userPreference) {
            <cc-option-toggle
                id="auto-dismiss-error"
                [(value)]="userPreference.autoDismissErrors"
                [labels]="['Yes', 'No']"
                (valueChange)="updateErrorAcknowledgementPreference($event)"
            >
            </cc-option-toggle>
        }
    </div>

    @if (hasBothFundAvailability) {
        <div class="fund-names-source">
            <label class="option-label">Fund Names</label>

            <cc-option-toggle
                id="fund-names-source"
                [(value)]="areHIFunds"
                [labels]="['Investors', 'HSMain']"
                (valueChange)="updateFundNamesSourcePreference($event)"
            >
            </cc-option-toggle>
        </div>
    }

    <div class="default-dates-funds">
        @if (errorMessage) {
            <div class="errormessage">{{ errorMessage }}</div>
        }

        <label class="option-label">Default From Date</label>
        <div class="option">
            <app-dropdown
                [options]="fuzzyDatesFromOptions"
                [(selected)]="selectedDefaultFromDate"
                (selectedChange)="validateDateRangeAndSave()"
            >
            </app-dropdown>
        </div>

        <label class="option-label">Default To Date</label>
        <div class="option">
            <app-dropdown
                [options]="fuzzyDatesToOptions"
                [(selected)]="selectedDefaultToDate"
                (selectedChange)="validateDateRangeAndSave()"
            >
            </app-dropdown>
        </div>

        <label class="option-label">{{ isMultiClient ? 'Default Clients' : 'Default Funds' }}</label>
        <div class="option funds-dropdown">
            <span>{{ isMultiClient ? userSelectedClients.length : userSelectedFunds.length }} selected</span>

            @if (!isMultiClient) {
                <div class="dropdown-wrapper">
                    <span
                        class="icon-filterarrow-close"
                        (click)="toggleDashboardFunds()"
                        [class.smalldevicehight]="isSmallDevice"
                        zommeddevice="landscape"
                    >
                    </span>

                    @if (showDashboardFunds) {
                        <div class="dropdown right-position smalld">
                            <app-funds-form
                                [selectedFunds]="userSelectedFunds"
                                (fundsApply)="applySelectedFunds($event)"
                            >
                            </app-funds-form>
                        </div>
                    }
                </div>
            }

            @if (isMultiClient) {
                <div class="dropdown-wrapper">
                    <span
                        class="icon-filterarrow-close"
                        (click)="toggleDashboardClients()"
                        [class.smalldevicehight]="isSmallDevice"
                        zommeddevice="landscape"
                    >
                    </span>

                    @if (showDashboardClients) {
                        <div class="dropdown right-position smalld">
                            <app-clients-form
                                [selectedClients]="userSelectedClients"
                                (clientsApply)="applySelectedClients($event)"
                            >
                            </app-clients-form>
                        </div>
                    }
                </div>
            }
        </div>
    </div>

    @if (showManageDatasetsMenu) {
        <div
            class="one-line-option manage-datasets"
            [routerLink]="'../../../dataset-definitions/edit/none'"
            [queryParams]="{from: 'preferences'}"
            (click)="userPreferencesClosed.emit()"
        >
            Manage Datasets
        </div>
    }
</div>
