<div class="live-demo-widget">
    <app-widget-header
        [headers]="headers"
        [dashboardId]="dashboardId"
        [widgetId]="id"
        [dataLoadTime]="dataLoadTime"
        (updateFilter)="onFilterChanged($event)"
        (headerInit)="onHeaderInitialized()"
        (headerMenuSelect)="onHeaderMenuSelect($event)"
    >
    </app-widget-header>
    <div class="widget-content">
        <div class="no-data" [class.error]="isErrorOccurred" *ngIf="isErrorOccurred">
            <span class="icon-notification-warning" (click)="showErrorMessage()"></span>
        </div>
        <div class="no-data" *ngIf="shouldDisplayMessage()">
            <span>{{getErrorMessage()}}</span>
        </div>

        <div class="advanced-grid-info-box" [hidden]="!showMessage">
            <h1> Configure Group, Pivot &amp; Columns in Tool Panel (
                <i class="icon-bigw"></i>)
            </h1>
        </div>
        <div #content>
        </div>
    </div>
</div>
