import { dummyQueryType, QueryPeriodType, QueryType } from '../named-queries/query-type';
import { DatasetFetchRuntimeParams } from '../query';
import { QueryTemplateHolder } from '../query-template-holder';
import { VisualizationTypeReference } from '../visualizations/visualization-type-reference';
import { DatasetDefinition } from './dataset-definition';

export class DatasetDefinitionDetails extends QueryTemplateHolder implements DatasetDefinition {
    conversableType: string;
    queryType: QueryType;
    queryTemplate: string;
    queryPeriodType?: QueryPeriodType;
    datasetDefinitionVisualizations: { id: number, visualizationMaster: VisualizationTypeReference }[];
    isGlobal: boolean;
    isHSSupported: boolean;
    name: string;
    dataType: string;
    showDuplicateRowsWarning: boolean;
    private isQueryTemplateStacked: boolean;

    constructor(init?: Partial<DatasetDefinitionDetails>) {
        super(init?.id ?? 0);

        this.conversableType = init?.conversableType ?? '';
        this.queryType = init?.queryType ?? dummyQueryType;
        this.queryTemplate = init?.queryTemplate ?? '';
        this.queryPeriodType = init?.queryPeriodType;
        this.datasetDefinitionVisualizations = init?.datasetDefinitionVisualizations ?? [];
        this.isGlobal = !!init?.isGlobal;
        this.isHSSupported = !!init?.isHSSupported;
        this.isQueryTemplateStacked = false;
        this.name = init?.name ?? '';
        this.dataType = init?.dataType ?? '';
        this.showDuplicateRowsWarning = !!init?.showDuplicateRowsWarning;

        if (this.queryTemplate) {
            this.setIsQueryTemplateStacked();
        }
    }

    getRootDir(): string {
        const dummyParams: DatasetFetchRuntimeParams = {
            timeseriesGranularity: '',
            dateRange: { from: '', to: '' },
            fundCodes: [],
            includeComments: true,
            includeLinks: true,
            clientCodeList: [],
            specialCaseParams: {},
        };
        return this.populateQueryTemplate(dummyParams).rootFMPath ?? 'NO ROOT FM PATH';
    }

    metadataEndpoint(clientCode: string): string {
        return this.queryType.metadataEndpoint.replace('{clientCode}', clientCode);
    }

    normalizeConversableType(): string | undefined {
        return this.conversableType;
    }

    normalizeQueryTypeName(): string {
        return this.queryType.name;
    }

    normalizeQueryTemplate(): string {
        return this.queryTemplate;
    }

    normalizeQueryEndPoint(clientCode: string): string {
        return this.queryType.dataEndpoint.replace('{clientCode}', clientCode);
    }

    normalizeQueryIsStacked(): boolean {
        return this.isQueryTemplateStacked;
    }

    private setIsQueryTemplateStacked(): void {
        this.isQueryTemplateStacked = this.queryTemplate.indexOf('@@UNION@@') !== -1;
    }
}
