import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CurrentStateService, DirtyFlagService } from '@ddv/behaviors';
import { ModalDialogActive } from '@ddv/common-components';
import { DashboardFiltersComponent, WidgetQueryparamService } from '@ddv/dashboards';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { QueryParamsService } from '@ddv/filters';
import { LayoutService, ManagerService } from '@ddv/layout';
import {
    Client,
    MODE,
    CompareMode,
    QueryTypeName,
    FilterPreference,
    Fund,
    FilterQueryParam,
    FuzzyDates,
    AdditionalFilter,
    AppWidgetState,
    DatasetDefinitionDetails,
    NamedQuery,
} from '@ddv/models';
import { NamedQueriesService } from '@ddv/named-queries';
import { WidgetService } from '@ddv/widgets';
import { Observable, Subscription } from 'rxjs';

// only dynamically instantiated and only by FiltersBarComponent, which is only used in the views feature
@Component({
    selector: 'app-widget-filter',
    templateUrl: 'widget-filter.component.html',
})
export class WidgetFilterComponent implements OnInit {
    @Input() widgetId: number | undefined;
    @Input() isSubscribedToDashboardFilters = false;
    filterType = 'widget';
    reducer = 'widgetQueryParamReducer';
    clientCode: string | undefined;
    isMultiClient = false;
    private widgetFilters: FilterPreference = { funds: [], filters: [] };

    @ViewChild('filtersComponent', { static: true }) private readonly filtersComponent: DashboardFiltersComponent | undefined;

    private isStackedQuery = false;
    private dsdDetailsSubscription: Subscription | undefined;

    constructor(
        private readonly manager: ManagerService,
        private readonly widgetService: WidgetService,
        // this seems completely unused.  consider getting rid of it
        public modalDialogActive: ModalDialogActive,
        private readonly dirtyFlagService: DirtyFlagService,
        private readonly widgetQueryparamService: WidgetQueryparamService,
        private readonly layoutService: LayoutService,
        private readonly queryParamsService: QueryParamsService,
        private readonly currentStateService: CurrentStateService,
        private readonly dsdService: DatasetDefinitionsService,
        private readonly namedQueriesService: NamedQueriesService) {}

    ngOnInit(): void {
        this.currentStateService.isMultiClient$.subscribe((isMultiClient: boolean) => {
            this.isMultiClient = isMultiClient;
        });

        const widgetPrefs = this.manager.getWidgetPreferences(this.widgetId ?? 0);
        if (widgetPrefs) {
            this.clientCode = widgetPrefs.clientCode;

            if (widgetPrefs?.datasetDefinition?.id) {
                if (this.dsdDetailsSubscription) {
                    this.dsdDetailsSubscription.unsubscribe();
                }

                this.setDsdDetailsSubscription(widgetPrefs);
            }
        }

        this.widgetFilters = this.widgetQueryparamService.widgetFilterParams.get(this.widgetId ?? 0) ?? { funds: [], filters: [] };
    }

    onApplySelectedFunds(funds: Fund[]): void {
        this.widgetFilters.funds = funds;
        if (this.widgetFilters.isComparing) {
            this.widgetFilters.comparing = CompareMode.BOTH;
        }
        this.updateWidgetQueryParams();
    }

    onApplySelectedClients(clients: Client[]): void {
        this.widgetFilters.clients = clients;
        if (this.widgetFilters.isComparing) {
            this.widgetFilters.comparing = CompareMode.BOTH;
        }
        this.updateWidgetQueryParams();
    }

    onApplyDateChanges(filterDate: FilterQueryParam | FuzzyDates): void {
        Object.keys(filterDate).forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.widgetFilters as any)[key] = this.isStackedQuery ? undefined : (filterDate as any)[key];
        });
        if (this.widgetFilters.isComparing) {
            this.widgetFilters.comparing = CompareMode.ORIGINAL;
        }
        this.updateWidgetQueryParams();
    }

    onDbFiltersApplied(additionalFilters: AdditionalFilter[]): void {
        this.widgetFilters.filters = additionalFilters;
        if (this.widgetFilters.isComparing) {
            this.widgetFilters.comparing = CompareMode.BOTH;
        }
        this.updateWidgetQueryParams();
    }

    updateWidgetQueryParams(): void {
        if (this.layoutService.getWorkspaceMode() === MODE.EDIT_WORKSPACE) {
            this.dirtyFlagService.enterDirtyState(this.manager.getCurrentDashboardId() ?? '');
        }
        this.filtersComponent?.setQueryParams(this.widgetFilters);
        const preference = this.manager.getWidgetPreferences(this.widgetId ?? 0);
        if (preference) {
            preference.widgetFilters = this.widgetFilters;
            preference.isActiveDate = this.widgetFilters.activeDate != null;
            this.manager.setWidgetExtraPreferences(preference.id, preference);
        }
        this.queryParamsService.addWidgetQueryParam(this.widgetId ?? 0, this.widgetFilters);
    }

    onClearHighlight(): void {
        this.manager.sendMessageToAllWidgetsOnWorkspace(0, {
            action: 'VISUALIZATION_SELECTED',
            dataSource: [],
            selectedItem: null,
        });
    }

    onRestoreCurrentWidget(): void {
        const preference = this.manager.getWidgetPreferences(this.widgetId ?? 0);
        if (!preference) {
            return;
        }
        this.widgetService.getWidgetPreferences(preference.coreWidgetId ?? 0, preference.clientCode ?? '').subscribe((widgetPrefs) => {
            preference.widgetFilters = widgetPrefs.widgetFilters;
            this.widgetFilters = preference.widgetFilters ?? { funds: [], filters: [] };
            this.updateWidgetQueryParams();
        });
    }

    onRefreshCurrentWidget(): void {
        this.updateWidgetQueryParams();
    }

    isQueryTypeRecon(): boolean {
        const preferences = this.manager.getWidgetPreferences(this.widgetId ?? 0);
        return preferences?.datasetDefinition?.queryType?.name === QueryTypeName.RECON;
    }

    private setDsdDetailsSubscription(widgetPrefs: AppWidgetState): void {
        const fetch$: Observable<DatasetDefinitionDetails | NamedQuery> = widgetPrefs.namedQueryId ?
            this.namedQueriesService.fetchNamedQuery(widgetPrefs.namedQueryId) :
            this.dsdService.fetchDatasetDefinitionDetails(Number(widgetPrefs.datasetDefinition?.id ?? 0));

        this.dsdDetailsSubscription = fetch$.subscribe({
            next: (namedQuery) => this.isStackedQuery = namedQuery.normalizeQueryIsStacked(),
        });
    }
}
