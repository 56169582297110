import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { DropdownOption, ModalDialogActive, MultiSubscriptionComponent } from '@ddv/common-components';
import { CompareModeService } from '@ddv/datasets';
import { UserService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    COMPARABLE_VIZ_LIST,
    MODE,
    VIZ_STYLE_CLASS,
    DashboardPreference,
    toDefaultQueryParams,
    QueryPeriodTypeName,
    QueryTypeName,
    DateRangeString,
    UserPreferences,
    VisualizationConfigs,
    AppWidgetState,
    Widget,
    WidgetSettingsOverrides,
    isTradeFileDetails,
    DisplayNameType,
    VisualizationType,
    TableSortType,
} from '@ddv/models';
import { apm, Transaction } from '@elastic/apm-rum';
import { CommonDate, DateRange, FuzzyDate } from '@hs/ui-core-date';

import { WidgetsStateRelayService } from '../../../services/widgets-state-relay.service';

// this is dynamically launched as a modal, so you won't see any references to the selector
// it is only launched by the WidgetHeaderComponent
@Component({
    selector: 'app-widget-settings-overrides',
    templateUrl: './widget-settings-overrides.component.html',
    styleUrls: ['./widget-settings-overrides.component.scss'],
})
export class WidgetSettingsOverridesComponent extends MultiSubscriptionComponent implements OnInit {
    @Input() widgetState: AppWidgetState | undefined;
    @Input() overrides: WidgetSettingsOverrides | undefined;
    @Input() dataLoadTime = '';
    @Input() allowJumpToManageWidgets = false;
    @Input() inFilterOnlyMode = false;
    @Input() fuzzyDates: FuzzyDate[] = [];
    @Input() canManageCrosstalk = false;
    @Input() crosstalkManagementPageURL = '';
    @Input() dashboardPreferences: DashboardPreference[] | undefined;

    @Output() saveClicked = new EventEmitter<WidgetSettingsOverrides>();
    @Output() manageWidgetClicked = new EventEmitter();

    effectiveState: WidgetSettingsOverrides | undefined;

    visualizationOptions: DropdownOption[] | undefined;
    initialVisualization: DropdownOption | undefined;

    slicerOptions: DropdownOption[] | undefined;
    initialSlicer: DropdownOption | undefined;

    sortOptions: DropdownOption[] | undefined;
    sortOn: DropdownOption | undefined;

    sortDirectionOptions: DropdownOption[] | undefined;
    sortDirection: DropdownOption | undefined;

    isMultiClient = false;
    isViewMode = false;
    isCompareModeChecked = false;
    showDatePicker = false;
    widgetConfig: Widget | undefined;
    compareDates: DateRange | CommonDate | undefined;
    isDateRangeSupported = false;
    isCompareModeToggled = false;
    isSubscribedOrUnsubscribedToggled = false;
    manualUpdates = true;
    isStackedQuery = false;
    hasRealtimeUpdateOption = false;
    visualizationIconClass: string | undefined;
    isCrosstalkGrid = false;
    isCompareModeOptionEnabled = false;

    private currentDashboardQueryParams: DashboardPreference | undefined;
    private isTFLDetailsDataset = false;
    private clientCode: string | undefined;

    constructor(
        readonly modalDialogActive: ModalDialogActive,
        private readonly currentStateService: CurrentStateService,
        private readonly managerService: ManagerService,
        private readonly manageWidgetsCommService: WidgetsStateRelayService,
        private readonly queryParamsService: QueryParamsService,
        private readonly userService: UserService,
        private readonly compareModeService: CompareModeService,
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.overrides?.subscribedToDashboardFilters) {
            this.setSubscriptionState();
        }

        this.subscribeTo(this.currentStateService.isMultiClient$, (isMultiClient) => {
            this.isMultiClient = isMultiClient;
        });

        this.subscribeTo(this.currentStateService.clientCode$, (clientCode) => {
            this.clientCode = clientCode;
        });

        this.subscribeTo(this.currentStateService.dashboardModeAndId$, ({ mode }) => {
            this.isViewMode = mode === MODE.VIEW;
        });

        this.subscribeTo(this.queryParamsService.dashboardQueryParams, (queryParams: DashboardPreference) => {
            this.currentDashboardQueryParams = queryParams;
        });

        this.widgetConfig = this.managerService.getWidgetById(this.widgetState?.id ?? 0);

        const queryPeriodType = this.widgetConfig?.extraParameters?.preferences?.datasetDefinition?.queryPeriodType?.name;
        this.isDateRangeSupported = !queryPeriodType ||
            queryPeriodType === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE ||
            queryPeriodType === QueryPeriodTypeName.RANGE;

        this.compareModeService.widgetsCompareModeStatus.subscribe((widgetsData) => {
            const widgetData = widgetsData.get(this.widgetConfig?.id ?? 0);
            if (widgetData) {
                this.isCompareModeChecked = widgetData.isInCompareMode;
                this.showDatePicker = widgetData.isInCompareMode;
                this.compareDates = widgetData.compareDates;
            }
        });

        this.isTFLDetailsDataset = isTradeFileDetails(this.widgetConfig?.extraParameters?.preferences?.datasetDefinition?.queryType?.name);

        this.recomputeState();

        this.setVisualizationIcon();

        this.setHasRealtimeUpdate();

        this.setIsCrosstalkGrid();

        this.setIsCompareModeOptionEnabled();
    }

    protected applyDisplayNameType(value: DisplayNameType): void {
        this.overrides?.applyDisplayNameType(value);
        this.recomputeState();
    }

    protected applyCustomName(value: string): void {
        this.overrides?.applyCustomName(value);
        this.recomputeState();
    }

    protected applyIsSubscribedToDashboardFilters(value: boolean): void {
        this.overrides?.applyIsSubscribedToDashboardFilters(value);

        if (this.isCompareModeOptionEnabled) {
            this.isSubscribedOrUnsubscribedToggled = true;
            this.setCompareDatesAndMode();
        }

        this.recomputeState();
    }

    protected applyMasterWidget(value: boolean): void {
        this.overrides?.applyMasterWidget(value);
        this.recomputeState();
    }

    protected applyRealTimeUpdates(value: boolean): void {
        this.overrides?.applyRealtimeUpdates(value);

        if (this.isCompareModeOptionEnabled) {
            this.setCompareDatesAndMode();
        }

        this.recomputeState();
    }

    protected applyCompareMode(value: boolean): void {
        this.overrides?.applyCompareMode(value);

        if (this.isCompareModeOptionEnabled) {
            if (!this.compareDates) {
                this.getDefaultCompareDates();
            }

            this.isCompareModeToggled = true;
            this.isCompareModeChecked = value;
            this.showDatePicker = value;
            this.applyPickerSelectedDates(this.compareDates);

            if (this.isUnsubscribedWidget()) {
                this.managerService.emitWhenDateComparerIsToggled(this.isCompareModeChecked);
            }

            if (this.isAdvancedGrid()) {
                const widgetFilters = this.widgetConfig?.extraParameters?.preferences?.widgetFilters;
                if (widgetFilters) {
                    widgetFilters.agGridShowDatePicker = this.showDatePicker;
                }
                this.manageWidgetsCommService.setCurrentQueryParams(widgetFilters);
            }

            if (!this.isCompareModeChecked) {
                this.getDefaultCompareDates();
                this.applyPickerSelectedDates(this.compareDates);
            }
        }

        this.recomputeState();
    }

    protected applyDefaultVisualizationType(value: VisualizationType | null): void {
        this.overrides?.applyDefaultVisualizationType(value);

        this.recomputeState();

        this.setVisualizationIcon();
    }

    protected applyDefaultSlicerForDefaultVisualization(value: string | null): void {
        this.overrides?.applyDefaultSlicerForDefaultVisualization(value);
        this.recomputeState();
    }

    protected applyDefaultSortOnForDefaultVisualization(value: TableSortType | null): void {
        this.overrides?.applyDefaultSortOnForDefaultVisualization(value);
        this.recomputeState();
    }

    protected applyDefaultSortDirectionForDefaultVisualization(value: string | null): void {
        this.overrides?.applyDefaultSortDirectionForDefaultVisualization(value);
        this.recomputeState();
    }

    protected applyPickerSelectedDates(selectedDate: DateRange | FuzzyDate | CommonDate | undefined): void {
        if (selectedDate === undefined) {
            this.isCompareModeChecked = false;
        }

        if (selectedDate instanceof DateRange) {
            this.compareDates = selectedDate;
        }

        if (selectedDate instanceof CommonDate) {
            this.compareDates = CommonDate.fromYearMonthDay(selectedDate.year, selectedDate.month, selectedDate.day);
        }

        if (selectedDate instanceof FuzzyDate) {
            this.compareDates =
                CommonDate.fromYearMonthDay(selectedDate.commonDate.year, selectedDate.commonDate.month, selectedDate.commonDate.day);
        }

        if (!this.isCompareModeChecked) {
            this.showDatePicker = false;
        }

        if (this.isCompareModeChecked) {
            this.overrides?.applyCompareDates(this.compareDates);
            this.overrides?.applyCurrentDashboardQueryParams(this.currentDashboardQueryParams);
        }
        this.overrides?.applyCompareMode(this.isCompareModeChecked);
    }

    protected applyOverrides(): void {
        if (this.overrides?.compareMode) {
            this.trackCompareModeUsage();
        }
        this.saveClicked.emit(this.overrides);
    }

    private trackCompareModeUsage(): void {
        const compareModeTransaction: Transaction | undefined = apm.startTransaction('Compare Mode On', 'user-interaction');
        const timestamp = Date.now();
        if (compareModeTransaction) {
            compareModeTransaction.addLabels({ client_code: this.clientCode ?? '' });
            compareModeTransaction.end(timestamp + 1);
        }
    }

    private setIsCompareModeOptionEnabled(): void {
        const preferences = this.widgetConfig?.extraParameters?.preferences;
        if (preferences) {
            const currentVisualization = preferences.currentVisualization!;
            const enableCompareMode = preferences.enableCompareMode;
            const visualizationConfig = preferences.visualizationConfigs
                ?.find((vizConfig) => vizConfig.visualizationType === currentVisualization);
            const numericColumnsPresent = this.checkForNumericColumns(currentVisualization, visualizationConfig);
            this.isCompareModeOptionEnabled = !!(COMPARABLE_VIZ_LIST.includes(currentVisualization) &&
                enableCompareMode && numericColumnsPresent && this.isViewMode && !this.isTFLDetailsDataset);
            return;
        }

        this.isCompareModeOptionEnabled = false;
    }

    private setIsCrosstalkGrid(): void {
        this.isCrosstalkGrid =
            this.isAdvancedGrid() && !!this.widgetConfig?.extraParameters?.preferences?.datasetDefinition?.conversableType;
    }

    private setCompareDatesAndMode(): void {
        this.isCompareModeChecked = false;
        this.showDatePicker = false;
        this.getDefaultCompareDates();
        this.applyPickerSelectedDates(this.compareDates);
        this.overrides?.applyCompareMode(this.isCompareModeChecked);
    }

    private setSubscriptionState(): void {
        this.overrides?.applyIsSubscribedToDashboardFilters(!!this.widgetState?.isSubscribedToDashboardFilters);
    }

    private recomputeState(): void {
        if (!this.widgetState) {
            return;
        }

        const state = this.overrides?.deriveEffectiveStateFrom(this.widgetState);
        if (!state) {
            return;
        }

        this.effectiveState = state;

        if (this.isMultiClient) {
            state.subscribedToDashboardFilters = true;
        }

        this.visualizationOptions = this.widgetState.configuredVisualizationTypes.map((t) => ({ text: t, key: t, value: t }));
        const defaultVisType = state.defaultVisualizationType ?? '';
        this.initialVisualization = { text: defaultVisType, key: defaultVisType, value: defaultVisType };

        this.slicerOptions = this.widgetState.getSlicersForVisualizationType(state.defaultVisualizationType!)
            .map((t) => ({ text: t ?? '', key: t, value: t }));
        const defaultSlicer = state.defaultSlicerForDefaultVisualization;
        this.initialSlicer = defaultSlicer ?
            { text: defaultSlicer, key: defaultSlicer, value: defaultSlicer } :
            this.slicerOptions[0];

        this.sortOptions = this.widgetState.getSortOnChoicesForVisualizationType(state.defaultVisualizationType!)
            .map((t) => ({ text: t.label, key: t.value, value: t.value }));
        this.sortOn = this.sortOptions.find((s) => s.value === state.defaultSortOnForDefaultVisualization);

        this.sortDirectionOptions = this.widgetState
            .getDefaultSortDirectionOptionsForDefaultVisualization(state.defaultSortOnForDefaultVisualization!)
            .map((s) => ({ text: s.value, key: s.label, value: s.value }));
        this.sortDirection = this.sortDirectionOptions
            .find((s) => s.value === state.defaultSortDirectionForDefaultVisualization);
    }

    private setVisualizationIcon(): void {
        this.visualizationIconClass = VIZ_STYLE_CLASS[this.effectiveState!.defaultVisualizationType!];
    }

    private setHasRealtimeUpdate(): void {
        const queryTypeName = this.widgetState?.datasetDefinition?.queryType?.name;
        this.hasRealtimeUpdateOption = queryTypeName === QueryTypeName.EOD ||
            queryTypeName === QueryTypeName.TRADE_FILE_LOADER ||
            queryTypeName === QueryTypeName.TRADE_FILE_SUMMARY;
    }

    private getDefaultCompareDates(): void {
        // eslint-disable-next-line complexity
        this.subscribeTo(this.userService.userPreferences$, (userPreferences: UserPreferences) => {
            let defaultCompareDates: DateRangeString;
            const hsDefaults = toDefaultQueryParams([], [], this.managerService.getExtraParametersForWorkspace());
            if (this.isUnsubscribedWidget()) {
                if (this.widgetConfig?.extraParameters?.preferences?.widgetFilters) {
                    defaultCompareDates = {
                        dateFrom: this.widgetConfig.extraParameters.preferences.widgetFilters.startDate ??
                            userPreferences.startDate ??
                            hsDefaults.startDate,
                        dateTo: this.widgetConfig.extraParameters.preferences.widgetFilters.endDate ??
                            userPreferences.endDate ??
                            hsDefaults.endDate,
                    };
                } else {
                    defaultCompareDates = {
                        dateFrom: userPreferences.startDate ?? hsDefaults.startDate,
                        dateTo: userPreferences.endDate ?? hsDefaults.endDate,
                    };
                }
            } else {
                defaultCompareDates = {
                    dateFrom: this.currentDashboardQueryParams?.startDate ||
                        this.dashboardPreferences?.length &&
                        this.dashboardPreferences[0].startDate ||
                        userPreferences.startDate ||
                        hsDefaults.startDate,
                    dateTo: this.currentDashboardQueryParams?.endDate ||
                        this.dashboardPreferences?.length &&
                        this.dashboardPreferences[0].endDate ||
                        userPreferences.endDate ||
                        hsDefaults.endDate,
                };
            }

            const startDateFuzzyDate = this.fuzzyDates?.find((date) => date.name === defaultCompareDates.dateFrom);
            const endDateFuzzyDate = this.fuzzyDates?.find((date) => date.name === defaultCompareDates.dateTo);

            const startDate = startDateFuzzyDate ?
                CommonDate.fromISOFormat(startDateFuzzyDate.value) :
                CommonDate.fromUSFormat(defaultCompareDates.dateFrom ?? '');
            const endDate = endDateFuzzyDate ?
                CommonDate.fromISOFormat(endDateFuzzyDate.value) :
                CommonDate.fromUSFormat(defaultCompareDates.dateTo ?? '');
            this.compareDates = this.isDateRangeSupported ? new DateRange(startDate, endDate) : startDate;
        });
    }

    private isUnsubscribedWidget(): boolean {
        return !this.overrides?.subscribedToDashboardFilters;
    }

    private isAdvancedGrid(): boolean {
        return this.widgetConfig?.extraParameters?.preferences?.currentVisualization === 'ADVANCED_GRID';
    }

    private checkForNumericColumns(currentVisualization: string, visualizationConfig: VisualizationConfigs | undefined): boolean {
        return !!(currentVisualization !== COMPARABLE_VIZ_LIST[0] ||
            visualizationConfig?.configs?.values.some((value) => value.displayType === 'value' || value.displayType === 'bar'));
    }
}
