import { Inject, NgModule, Optional } from '@angular/core';
import { CrosstalkModule } from '@ddv/crosstalk';
import { DatasetDefinitionsModule } from '@ddv/dataset-definitions';
import { EntitlementsModule } from '@ddv/entitlements';
import { FiltersModule } from '@ddv/filters';
import { ApiConfiguration, HttpModule } from '@ddv/http';
import { LayoutModule } from '@ddv/layout';
import { ReferenceDataModule } from '@ddv/reference-data';
import { UsageTrackingModule } from '@ddv/usage-tracking';

import { SlowDataApprovalComponent } from './components/slow-data-approval/slow-data-approval.component';
import { ActionHandlerBodyBuilderService } from './services/action-handler-body-builder.service';
import { ActionHandlerService } from './services/action-handler.service';
import { CompareModeService } from './services/compare-mode.service';
import { CrosstalkDataService } from './services/crosstalk-data.service';
import { DataFastnessService } from './services/data-fastness.service';
import { DatasetDatasetFetcherService } from './services/dataset-dataset-fetcher.service';
import { DatasetManagerService } from './services/dataset-manager.service';
import { DatasetQueryParamsDifferService } from './services/dataset-query-params-differ.service';
import { DatasetQueryRunnerService } from './services/dataset-query-runner.service';
import { DatasetQueryToRuntimeParamsConverterService } from './services/dataset-query-to-runtime-params-converter.service';
import { DatasetRefresherService } from './services/dataset-refresher.service';
import { DatasetRuntimeParamsBuilderService } from './services/dataset-runtime-params-builder.service';
import { DatasetStateManagementService } from './services/dataset-state-management.service';
import { DatasetTrebekQueryRunnerService } from './services/dataset-trebek-query-runner.service';
import { DefaultQueryParamsService } from './services/default-query-params.service';
import { MetadataService, requestMetadataFromNQSToken } from './services/metadata.service';
import { SlowDataApprovalService } from './services/slow-data-approval.service';
import { WidgetDataSourceService } from './services/widget-datasource.service';

@NgModule({
    imports: [
        CrosstalkModule,
        DatasetDefinitionsModule,
        EntitlementsModule,
        FiltersModule,
        HttpModule,
        LayoutModule,
        ReferenceDataModule,
        UsageTrackingModule,
    ],
    providers: [
        ActionHandlerBodyBuilderService,
        ActionHandlerService,
        CompareModeService,
        CrosstalkDataService,
        DataFastnessService,
        DatasetDatasetFetcherService,
        DatasetManagerService,
        DatasetQueryParamsDifferService,
        DatasetQueryRunnerService,
        DatasetQueryToRuntimeParamsConverterService,
        DatasetRefresherService,
        DatasetRuntimeParamsBuilderService,
        DatasetStateManagementService,
        DatasetTrebekQueryRunnerService,
        DefaultQueryParamsService,
        MetadataService,
        SlowDataApprovalService,
        WidgetDataSourceService,
        {
            provide: requestMetadataFromNQSToken,
            useFactory: (c: ApiConfiguration): boolean => c.requestMetadataFromNQS,
            deps: [[new Inject('apiConfig'), new Optional()]],
        },
    ],
    declarations: [
        SlowDataApprovalComponent,
    ],
    exports: [
        SlowDataApprovalComponent,
    ],
})
export class DatasetsModule {}
