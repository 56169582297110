import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CurrentStateService, DirtyFlagService } from '@ddv/behaviors';
import { ModalDialogService, MultiSubscriptionComponent } from '@ddv/common-components';
import { DashboardFiltersService, DdvWidgetService } from '@ddv/dashboards';
import { Datasource, DataWrapper, MetadataService, WidgetDataSourceService } from '@ddv/datasets';
import { QueryParamsService } from '@ddv/filters';
import { ManagerService, SelectedWidgetAction } from '@ddv/layout';
import {
    AdditionalFilter,
    AppWidgetState,
    BoardWidgetDefinitionIds,
    CompareMode,
    DashboardFilter,
    EXCLUDE,
    FILTER_TYPE,
    FilterCriteria,
    FilterPreference,
    getAvailableCriteriaOptions,
    getAvailableOperators,
    MetadataLookup,
    WidgetData,
} from '@ddv/models';
import { FuzzyDatesServiceV2 } from '@ddv/reference-data';
import { compareSortOptions, deepClone, deepCompare } from '@ddv/utils';
import { RANK_SUFFIX } from '@ddv/visualizations';
import { FilterableField, FilterSelection } from '@hs/ui-core-additional-filters';
import { DropdownOption, FieldDataType } from '@hs/ui-core-common';
import { FuzzyDate } from '@hs/ui-core-date';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { WidgetFilterComponent } from '../widget-filter/widget-filter.component';

interface Filter {
    name: string;
    value: string | number | boolean | undefined;
    isMasterWidgetFilter: boolean;
    isDetailWidgetFilter?: boolean;
}

@Component({
    selector: 'app-filters-bar',
    templateUrl: './filters-bar.component.html',
    styleUrls: ['./filters-bar.component.scss'],
})
export class FiltersBarComponent extends MultiSubscriptionComponent implements OnInit, OnChanges, OnDestroy {
    @Input() dashboardId: string | undefined;
    @Input() inPresentationMode = false;
    @Input() widgetId: number | undefined;
    @Output() filtersApplied = new EventEmitter();

    subscriptionState: 'All Subscribed' | 'Selected Widget' | undefined;
    filters: Filter[] = [];
    selectedWidgetFilters: FilterPreference | undefined;
    filterAttributeList: FilterableField[] = [];

    protected selectedFilterAttributes: FilterSelection[] | undefined;
    protected noFiltersSelected = 'Add Filter';
    protected fuzzyDates: FuzzyDate[] = [];

    private deltas: AdditionalFilter[] | undefined;
    private paramsSubscription: Subscription | undefined;
    private widgetPrefs: AppWidgetState | undefined;
    private metadataSubscription: Subscription | undefined;
    private isMultiClient = false;
    private currentDatasources: DataWrapper[] = [];
    private selectedBubbleFilter: { [key: string]: DashboardFilter | AdditionalFilter } = {};
    private selectedOperators: { [key: string]: FilterCriteria | undefined } = {};
    private currentId: string | undefined;
    private selectedWidgetId: number | undefined;
    private dashboardParamsChangedOnView = false;
    private noFiltersAvailable = true;
    private masterFilters: FilterableField[] = [];

    private readonly availableOperators = getAvailableOperators();
    private readonly availableCriteriaOptions: FilterCriteria[] = getAvailableCriteriaOptions();
    private readonly appliedUrlQueryParams: FilterPreference = {};
    private readonly unsubscribeNotifier$ = new Subject<void>();

    constructor(
        private readonly queryParamsService: QueryParamsService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly dirtyFlagCheck: DirtyFlagService,
        private readonly dashboardFiltersService: DashboardFiltersService,
        private readonly metadataService: MetadataService,
        private readonly manager: ManagerService,
        private readonly modalService: ModalDialogService,
        private readonly cdr: ChangeDetectorRef,
        private readonly activatedRoute: ActivatedRoute,
        private readonly currentStateService: CurrentStateService,
        private readonly ddvWidgetService: DdvWidgetService,
        private readonly fuzzyDatesService: FuzzyDatesServiceV2,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeTo(
            combineLatest([
                this.manager.selectedWidget$,
                this.widgetDataSourceService.dataSource$.pipe(filter((ds) => ds.datasources.length !== 0)),
                this.activatedRoute.queryParams,
                this.ddvWidgetService.currentOpenedDetailWidgetId,
            ]), (values: [SelectedWidgetAction, Datasource, Params, number | undefined]) => {
                const [ws, ds, queryParams, detailWidgetId] = values;
                this.currentId = this.dashboardId;

                if (ws.config) {
                    this.selectedWidgetId = ws.config.id;
                    this.widgetPrefs = ws.config.extraParameters?.preferences;

                    this.selectedWidgetFilters = this.widgetPrefs?.widgetFilters;
                    this.subscriptionState = this.widgetPrefs?.isSubscribedToDashboardFilters ? 'All Subscribed' : 'Selected Widget';
                } else {
                    this.selectedWidgetId = undefined;
                    this.selectedWidgetFilters = undefined;
                    this.subscriptionState = 'All Subscribed';
                }

                if (queryParams && Object.keys(queryParams).length &&
                    (!this.appliedUrlQueryParams || !deepCompare(this.appliedUrlQueryParams.filters, queryParams.filters))
                ) {
                    this.queryParamsService.dispatchUpdatedQueryParams({
                        filters: JSON.parse(decodeURIComponent(queryParams.filters)) || [],
                    });
                    Object.assign(this.appliedUrlQueryParams, queryParams);
                }

                this.mapFilters(ds);

                if (this.subscriptionState === 'All Subscribed') {
                    this.dashboardFiltersService.setFilterType(FILTER_TYPE.DASHBOARD);
                }

                const contexts = this.getContexts();
                this.dashboardFiltersService.setSlicerValues(this.selectedWidgetId);

                this.noFiltersAvailable = contexts.length === 0;

                if (!deepCompare(this.currentDatasources, ds.datasources)) {
                    this.currentDatasources = deepClone(ds.datasources);

                    if (!this.noFiltersAvailable) {
                        this.onFetchMetadata(this.setContexts(contexts, detailWidgetId));
                    }
                }
            });

        this.subscribeTo(this.currentStateService.isMultiClient$, (isMultiClient) => this.isMultiClient = isMultiClient);

        this.subscribeTo(this.fuzzyDatesService.getFuzzyDatesCC(), (fuzzyDates) => this.fuzzyDates = fuzzyDates);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dashboardId) {
            this.onDashboardChanges();
        }

        if (changes.inPresentationMode) {
            this.onModeChanges();
        }
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        this.manager.selectWidget(false, undefined);

        this.unsubscribeNotifier$.next();
        this.unsubscribeNotifier$.complete();

        this.unsubscribeFromMetadata();
    }

    openFilterDialog(): void {
        const widgetFilterModeDialogRef = this.modalService.open(WidgetFilterComponent, { windowClass: 'filter-popup' });
        widgetFilterModeDialogRef.componentInstance.widgetId = this.selectedWidgetId;
        widgetFilterModeDialogRef.componentInstance.isSubscribedToDashboardFilters = false;
    }

    onSelectedChange(selectedFilterAttributes: FilterSelection[]): void {
        let filters = this.getSelectedAdditionalFilters(selectedFilterAttributes);

        if (this.isChangeAnOverride(selectedFilterAttributes)) {
            filters = this.getUpdatedFiltersOnOverride(filters);
        }

        this.dashboardFiltersService.setDashboardFilters(filters);
        this.selectedFilterAttributes = this.getSelectedFilterAttributes();
        this.setFilterAttributeProperties();
        this.setMasterFilters();

        this.cdr.detectChanges();

        this.queryParamsService.dispatchUpdatedQueryParams({
            filters,
            areFiltersAppliedByMaster: false,
            comparing: this.widgetPrefs?.widgetFilters?.isComparing ? CompareMode.BOTH : undefined,
        });

        this.onEnterDirtyState(filters);
    }

    removeFilter(filterToRemove: Filter): void {
        const filters = this.dashboardFiltersService.getDashboardFilters().filter((df) => df.displayName !== filterToRemove.name);
        if (this.selectedWidgetFilters) {
            this.selectedWidgetFilters.filters = filters;
        }
        if (this.selectedWidgetFilters?.isComparing) {
            this.selectedWidgetFilters.comparing = CompareMode.BOTH;
        }
        this.queryParamsService.addWidgetQueryParam(this.selectedWidgetId ?? 0, this.selectedWidgetFilters);
        this.onEnterDirtyState(undefined);
    }

    private getUpdatedFiltersOnOverride(dashboardFilters: DashboardFilter[]): DashboardFilter[] {
        const filters = dashboardFilters.filter((f) => f.isMasterWidgetFilter);
        this.manager.getCurrentDashboardFilters().forEach((dashboardFilter) => {
            if (!filters.find((f) => f.name === dashboardFilter.name)) {
                filters.push(dashboardFilter);
            }
        });
        return filters;
    }

    private onEnterDirtyState(deltas: AdditionalFilter[] | undefined): void {
        if (!this.inPresentationMode) {
            this.dirtyFlagCheck.enterDirtyState(this.dashboardId ?? '');
        } else {
            this.dashboardParamsChangedOnView = true;
            if (deltas) {
                this.deltas = deltas;
            }
        }
    }

    private onFetchMetadata(contextsByDefinitionId: BoardWidgetDefinitionIds[]): void {
        let fetchedMetadata: MetadataLookup = {};
        let observable: Observable<MetadataLookup | { [widgetId: number]: MetadataLookup }>;
        if (!this.isMultiClient) {
            observable = this.metadataService.fetchMetadataUniqueMetadata(contextsByDefinitionId[0]);
        } else {
            observable = this.metadataService.fetchMetadataAllMetadata(contextsByDefinitionId);
        }

        this.metadataSubscription = observable
            .subscribe({
                next: (uniqueMetadata: MetadataLookup | { [widgetId: number]: MetadataLookup }) => {
                    if (Number(Object.keys(uniqueMetadata)[0])) {
                        const metadataLookup = uniqueMetadata as { [widgetId: number]: MetadataLookup };
                        contextsByDefinitionId.forEach((context) => {
                            const metadata: MetadataLookup = metadataLookup[context.widgetId!];
                            Object.entries(metadata).forEach(([key, value]) => {
                                value.name = key;
                            });
                            this.metadataService.addMetadataToState(context.widgetId!, metadata);
                        });
                        Object.keys(uniqueMetadata).forEach((key) => {
                            fetchedMetadata = { ...fetchedMetadata, ...metadataLookup[Number(key)] };
                        });
                    } else {
                        Object.entries(uniqueMetadata).forEach(([key, value]) => {
                            value.name = key;
                        });
                        this.metadataService.addMetadataToState(contextsByDefinitionId[0].widgetId!, uniqueMetadata as MetadataLookup);
                        fetchedMetadata = uniqueMetadata as MetadataLookup;
                    }

                    this.dashboardFiltersService.updateMetadata(fetchedMetadata);

                    if (this.subscriptionState === 'All Subscribed') {
                        this.setFilterAttributeList(fetchedMetadata);
                        this.selectedFilterAttributes = this.getSelectedFilterAttributes();
                        this.setFilterAttributeProperties();
                        this.setMasterFilters();
                    }
                },
            });
    }

    private setFilterAttributeList(metadata: MetadataLookup): void {
        this.filterAttributeList = this.getFilterableAttributeList();
        this.setFilterableAttributeMetadata(metadata);
        this.setFilterableAttributeChoices();
        this.setFilterableAttributeOperators();
        this.filterAttributeList.sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1);
    }

    private getFilterableAttributeList(): FilterableField[] {
        return [
            ...this.dashboardFiltersService.getDashboardFilters().map((dashboardFilter) => ({
                label: dashboardFilter.displayName ?? '',
                fieldId: dashboardFilter.name ?? '',
                choices: dashboardFilter.values?.map((value) => ({ text: value.toString(), value: value.toString() })) ?? [],
            })) ?? [],
            ...this.dashboardFiltersService.getMetadataList().map((md) => ({
                label: md.text,
                fieldId: md.value,
            })) ?? [],
        ];
    }

    private setFilterableAttributeMetadata(metadata: MetadataLookup): void {
        this.filterAttributeList.forEach((filterAttribute) => {
            if (filterAttribute.fieldId.endsWith(RANK_SUFFIX)) {
                filterAttribute.fieldMetadata = {
                    name: filterAttribute.fieldId,
                    displayName: filterAttribute.label,
                    description: '',
                    agBand: null,
                    alignment: 'right',
                    canAggregate: true,
                    canPivotOn: false,
                    datatype: 'decimal',
                    displayType: 'value',
                    editable: false,
                    format: null,
                    hierarchy: [''],
                    isAttribute: false,
                    isDynamic: false,
                    isOrdinal: false,
                    isMultiValued: false,
                    zeroIfNone: true,
                };
            } else {
                filterAttribute.fieldMetadata = {
                    name: metadata[filterAttribute.fieldId].name ?? '',
                    displayName: metadata[filterAttribute.fieldId].displayName ?? '',
                    description: metadata[filterAttribute.fieldId].description ?? '',
                    agBand: metadata[filterAttribute.fieldId].agBand ?? '',
                    alignment: metadata[filterAttribute.fieldId].alignment ?? '',
                    canAggregate: !!metadata[filterAttribute.fieldId].canAggregate,
                    canPivotOn: !!metadata[filterAttribute.fieldId].canPivotOn,
                    datatype: metadata[filterAttribute.fieldId].datatype as FieldDataType ?? '',
                    displayType: metadata[filterAttribute.fieldId].displayType ?? 'string',
                    editable: !!metadata[filterAttribute.fieldId].editable,
                    format: metadata[filterAttribute.fieldId].format ?? '',
                    hierarchy: [metadata[filterAttribute.fieldId].hierarchy ?? ''],
                    isAttribute: !!metadata[filterAttribute.fieldId].isAttribute,
                    isDynamic: !!metadata[filterAttribute.fieldId].isDynamic,
                    isOrdinal: !!metadata[filterAttribute.fieldId].isOrdinal,
                    isMultiValued: false,
                    zeroIfNone: !!metadata[filterAttribute.fieldId].zeroIfNone,
                };
            }
        });
    }

    private setFilterableAttributeChoices(): void {
        this.filterAttributeList.forEach((attribute) => {
            const choices: Set<string> = new Set();

            this.dashboardFiltersService.getAllValueOptions().forEach((option) => {
                if (option[attribute.fieldId] != null) {
                    choices.add(option[attribute.fieldId].toString());
                }
            });

            if (attribute.choices) {
                attribute.choices.forEach((choice) => {
                    choices.add(choice.value);
                });
            }

            attribute.choices = [...choices]
                .sort((a, b) => compareSortOptions(a, b))
                .map((choice) => ({ text: choice, value: choice }));
        });
    }

    private setFilterableAttributeOperators(): void {
        this.filterAttributeList.forEach((attribute) => {
            if (this.selectedOperators[attribute.fieldId]) {
                attribute.operatorSelected = this.getSelectedOperator(this.selectedOperators[attribute.fieldId]);
            }
        });
    }

    private getSelectedFilterAttributes(): FilterSelection[] {
        return this.dashboardFiltersService.getDashboardFilters().map((dashboardFilter) => {
            const values = dashboardFilter.values?.filter((value) => value !== '') ?? [];
            const areBlanksSelected = dashboardFilter.values?.some((value) => value === '');
            const fuzzyNames = dashboardFilter.fuzzies;
            const valuesWithFuzzies = this.generateDropdownOptions(values, fuzzyNames);

            if (valuesWithFuzzies.length) {
                return {
                    fieldId: dashboardFilter.name ?? '',
                    choices: valuesWithFuzzies,
                    areBlanksSelected,
                };
            }

            return {
                fieldId: dashboardFilter.name ?? '',
                choices: values.map((value, index) => ({
                    text: value.toString(),
                    value: value.toString(),
                    fuzzyName: fuzzyNames?.[index],
                })),
                areBlanksSelected,
            };
        }) ?? [];
    }

    private onDashboardChanges(): void {
        this.manager.selectWidget(false, undefined);
        this.dashboardParamsChangedOnView = false;
        this.deltas = undefined;
        this.noFiltersAvailable = true;
        this.unsubscribeFromMetadata();
    }

    private onModeChanges(): void {
        const newDashboardCreated = !(this.currentId === this.dashboardId);
        if (!newDashboardCreated && !this.inPresentationMode && this.dashboardParamsChangedOnView) {
            if (this.deltas) {
                this.queryParamsService.dispatchUpdatedQueryParams({ filters: this.deltas, areFiltersAppliedByMaster: false });
            }
            this.dirtyFlagCheck.enterDirtyState(this.dashboardId ?? '');
            this.dashboardParamsChangedOnView = false;
            this.deltas = undefined;
        } else {
            this.currentId = this.dashboardId;
        }
    }

    private unsubscribeFromMetadata(): void {
        if (this.metadataSubscription) {
            this.metadataSubscription.unsubscribe();
        }
    }

    private getContexts(): BoardWidgetDefinitionIds[] {
        if (this.subscriptionState === 'All Subscribed') {
            return this.dashboardFiltersService.initFilterDashboard();
        }
        return this.dashboardFiltersService.initFilterWidget(this.selectedWidgetId);
    }

    private setContexts(contexts: BoardWidgetDefinitionIds[], detailWidgetId: number | undefined): BoardWidgetDefinitionIds[] {
        if (detailWidgetId) {
            return contexts.filter((context) => context.widgetId === detailWidgetId);
        }

        const definitionIds = new Set<number | string>();
        return contexts.filter((context) => {
            if (!definitionIds.has(context.definitionId!)) {
                definitionIds.add(context.definitionId!);
                return context;
            }
        });
    }

    private getSelectedAdditionalFilters(selectedFilterAttributes: FilterSelection[]): DashboardFilter[] {
        const filters = selectedFilterAttributes.map((attribute) => {
            const fuzzies = getFuzzyDatesFilterValues(attribute);

            return {
                name: attribute.fieldId,
                displayName: this.filterAttributeList.find((fa) => fa.fieldId === attribute.fieldId)?.label ?? '',
                criteria: this.getCriteria(this.getOperator(attribute)),
                values: this.getAdditionalFilterValues(attribute, fuzzies),
                isMasterWidgetFilter: !!this.masterFilters.find((mf) => mf.fieldId === attribute.fieldId),
                filterValuesType: this.getFilterValuesType(attribute.choices.map((choice) => choice.value)),
                ...(!!fuzzies && { fuzzies }),
            };
        });

        return filters;
    }

    private getCriteria(operator: string | undefined): FilterCriteria {
        return this.availableCriteriaOptions[this.availableOperators.indexOf(operator ?? 'Includes')];
    }

    private getSelectedOperator(criteria: FilterCriteria | undefined): string {
        return this.availableOperators[this.availableCriteriaOptions.indexOf(criteria ?? 'INCLUDE')];
    }

    private getOperator(attribute: FilterSelection): string | undefined {
        return attribute.operator ?? this.filterAttributeList.find((fa) => fa.fieldId === attribute.fieldId)?.operatorSelected;
    }

    private getFilterValuesType(filterValues: (string | number | boolean)[]): string {
        const allValuesTypes = [...new Set(filterValues?.map((value) => value == null ? 'null' : typeof value))];

        if (allValuesTypes.length === 1) {
            return allValuesTypes[0];
        }

        return allValuesTypes.length === 2 ? allValuesTypes.find((valuesType) => valuesType !== 'null') ?? '' : '';
    }

    private mapFilters(data: Datasource): void {
        const params = this.getQueryParams();

        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }

        this.paramsSubscription = params.subscribe({
            next: (p) => {
                // Remove saved UDF filters until we start fetching the data for UDF from Trebek
                const updatedFilters = p?.filter((f) => !f.name?.startsWith('udf_')) ?? [];
                this.dashboardFiltersService.clearDashboardFilters();
                this.onMapFilters(updatedFilters, data);
            },
        });
    }

    private getQueryParams(): Observable<(DashboardFilter | AdditionalFilter)[] | undefined> {
        if (this.subscriptionState === 'All Subscribed') {
            return this.queryParamsService.dashboardQueryParams.pipe(map((qp) => qp.filters));
        }

        return this.queryParamsService.widgetQueryParams.pipe(
            filter((qp) => !!qp),
            map((qp) => qp?.widgetFilters.get(this.selectedWidgetId ?? 0)?.filters),
            takeUntil(this.unsubscribeNotifier$),
        );
    }

    private onMapFilters(paramFilters: (DashboardFilter | AdditionalFilter)[], datasource: Datasource): void {
        paramFilters.forEach((paramFilter) => {
            if (paramFilter.values?.every((v) => typeof v === 'string')) {
                paramFilter.values = this.generateDropdownOptions(paramFilter.values ?? []).map((v) => v.value);
            }
        });

        if (this.subscriptionState === 'All Subscribed') {
            paramFilters.forEach((f) => {
                this.dashboardFiltersService.addDashboardFilter(f);
                this.selectedOperators[f.name ?? ''] = f.criteria;
            });
            this.setFilterAttributeProperties();
            this.setMasterFilters();
            this.selectedFilterAttributes = this.getSelectedFilterAttributes();
        }

        if (this.subscriptionState === 'Selected Widget') {
            this.filters = paramFilters.map((f) => {
                const name = f.displayName ?? '';

                this.selectedBubbleFilter[name] = f;
                this.dashboardFiltersService.addDashboardFilter(f);

                const value = this.getFilterValue(f, datasource);

                return { name, value, isMasterWidgetFilter: false };
            });

            this.filters.sort((a, b) => a.name > b.name ? 1 : -1);
        }
    }

    private setFilterAttributeProperties(): void {
        this.filterAttributeList.forEach((fa) => {
            const dashboardFilter = this.dashboardFiltersService.getDashboardFilters().find((f) => f.name === fa.fieldId);

            fa.icon = dashboardFilter?.isMasterWidgetFilter ? 'notification-m' : undefined;
            fa.hideChevron = dashboardFilter?.isMasterWidgetFilter;
        });
    }

    private getFilterValue(paramFilter: DashboardFilter | AdditionalFilter, datasource: Datasource): string | number | boolean | undefined {
        const { criteria, values } = paramFilter;
        const displayName = paramFilter.displayName ?? '';
        const filterName = paramFilter.name ?? '';

        if (criteria === EXCLUDE && values?.length) {
            return `EX: ${values.length.toString()} OF ${this.getFilterOptionsCount(datasource, filterName, this.selectedBubbleFilter[displayName].values)}`;
        }
        if (values?.length === 1) {
            return values[0];
        }
        if (Number(values?.length) > 1) {
            return `${values?.length.toString()} OF ${this.getFilterOptionsCount(datasource, filterName, this.selectedBubbleFilter[displayName].values)}`;
        }
        return undefined;
    }

    private getFilterOptionsCount(ds: Datasource, filterName: string, selectedValues: (string | number | boolean)[] | undefined): number {
        const slicerValues = ([] as WidgetData[]).concat(...ds.datasources
            .filter((dw) => dw.uniqueKey.sourceType === 'widget' && dw.uniqueKey.sourceId === this.selectedWidgetId)
            .map((dw) => dw.data));

        const filterOptions = new Set();

        slicerValues.forEach((value) => {
            if (value[filterName] != null) {
                filterOptions.add(value[filterName].toString());
            }
        });

        selectedValues?.forEach((selectedValue) => filterOptions.add(selectedValue));

        return filterOptions.size;
    }

    private generateDropdownOptions(values: (string | number | boolean)[], fuzzies?: string[]): DropdownOption[] {
        const areAllValuesStrings = values.every((v) => typeof v === 'string');
        const doFuzziesContainNames = fuzzies?.some((f) => f !== '');
        const areAllValuesFuzzyDates = values.every((v) => this.isFuzzyDate(v.toString()));
        const dropdownOptions: DropdownOption[] = [];
        if (areAllValuesStrings && (doFuzziesContainNames ?? areAllValuesFuzzyDates)) {
            values.forEach((value, index) => {
                const fuzzyName = fuzzies?.length ? fuzzies[index] : value;
                const fuzzyValue = this.getFuzzyDateValue(fuzzyName);

                dropdownOptions.push({
                    text: value,
                    value: fuzzyValue ? fuzzyValue : value,
                    fuzzyName: fuzzies?.[index] ?? value,
                });
            });

            return dropdownOptions;
        }
        return values.map((value) => ({ text: value.toString(), value: value.toString() }));
    }

    private isFuzzyDate(value: string): boolean {
        return this.fuzzyDates.some((fuzzy) => fuzzy.name === value);
    }

    private getFuzzyDateValue(value: string): string {
        const fuzzyDate = this.fuzzyDates.find((fuzzy) => fuzzy.name === value);

        return fuzzyDate?.commonDate.toDashFormat() ?? value;
    }

    private getAdditionalFilterValues(selectedFilterAttribute: FilterSelection, fuzzies?: string[]): string[] {
        let values = selectedFilterAttribute.choices.map((choice) => choice.fuzzyName ?? choice.value) ?? [];

        if (selectedFilterAttribute.areBlanksSelected) {
            values.push('');
        }

        values = values?.map((value, index) => {
            if (!!fuzzies?.[index] && fuzzies[index] !== '') {
                return this.getFuzzyDateValue(fuzzies[index]);
            }
            return value;
        });

        return values;
    }

    private isChangeAnOverride(selectedFilterAttributes: FilterSelection[]): boolean {
        const masterFilterAttributesIds = this.masterFilters.map((fa) => fa.fieldId);
        const removedMasterFiltersIds: string[] = [];

        masterFilterAttributesIds.forEach((id) => {
            if (!selectedFilterAttributes.find((sfa) => sfa.fieldId === id)) {
                removedMasterFiltersIds.push(id);
            }
        });

        return removedMasterFiltersIds
            .some((id) => this.manager.getCurrentDashboardFilters()
                .find((f) => f.name === id));
    }

    private setMasterFilters(): void {
        this.masterFilters = this.filterAttributeList.filter((fa) => fa.icon === 'notification-m');
    }
}

function getFuzzyDatesFilterValues(selectedFilterAttribute: FilterSelection): string[] | undefined {
    const fuzzyDates = selectedFilterAttribute.choices.map((c) => c.fuzzyName ?? '');
    return !fuzzyDates.every((fd) => fd === '') ? fuzzyDates : undefined;
}
