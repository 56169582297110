import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentStateService } from '@ddv/behaviors';
import {
    AlertService,
    ConfirmationPopupService,
    ModalDialogRef,
    ModalDialogService,
    MultiSubscriptionComponent,
} from '@ddv/common-components';
import { DashboardFiltersComponent } from '@ddv/dashboards';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { UserEntitlementService, UserService } from '@ddv/entitlements';
import { ManagerService } from '@ddv/layout';
import {
    AppWidgetState,
    Client,
    COMPARABLE_VIZ_LIST,
    DashboardFilter,
    DatasetDefinition,
    DatasetDefinitionDetails,
    FILTER_TYPE,
    FilterQueryParam,
    Fund,
    getGlobalSaveDialogOptions,
    isInvestorQueryType,
    isTradeFileDetails,
    MANAGE_WIDGET_ID,
    NamedQuery,
    QueryTypeName,
    UserPermission,
    UserPreferences,
    VisualizationConfigs,
    VisualizationType,
} from '@ddv/models';
import { isNamedQuery, NamedQueriesService } from '@ddv/named-queries';
import { FuzzyDatesService } from '@ddv/reference-data';
import { cloneArray, deepClone } from '@ddv/utils';
import { SaveWidgetFormComponent, WidgetService, WidgetsStateRelayService, WidgetsService } from '@ddv/widgets';
import { Observable, Subscription } from 'rxjs';

import { MWLeftNavComponent } from '../left-nav/manage-widgets-left-nav/manage-widgets-left-nav.component';

@Component({
    selector: 'app-mw-dashboard',
    templateUrl: './manage-widgets-dashboard.component.html',
    styleUrls: ['./manage-widgets-dashboard.component.scss'],
})
export class ManageWidgetsDashboardComponent extends MultiSubscriptionComponent implements OnInit, OnChanges {
    @Input() navRef: MWLeftNavComponent | undefined;
    @Input() filterType = FILTER_TYPE.CORE_WIDGET;
    @Input() datasetDefinition: DatasetDefinition | NamedQuery | undefined;
    @Input() autoAddDynamicColumnsVisualizations: Map<string, boolean> | undefined;
    @ViewChild('dashboardFilters', { static: true }) queryParamsRef: DashboardFiltersComponent | undefined;
    @Output() revertChanges = new EventEmitter();
    reducer = 'widgetQueryParamReducer';
    widgetInfo: AppWidgetState | undefined;
    manageWidgetId = MANAGE_WIDGET_ID;
    enableSave = false;
    saveWidgetDialogRef: ModalDialogRef | undefined;
    userPreferences: UserPreferences | undefined;
    clientCode = '';
    isMultiClient = false;
    userSelectedDate: { dateFrom: string, dateTo: string } | undefined;
    isGlobalEditPartial = false;
    private isStackedQuery = false;
    private queryTypeName: string | undefined;
    private namedQuerySubscription: Subscription | undefined;

    constructor(
        private readonly alertService: AlertService,
        private readonly cdr: ChangeDetectorRef,
        private readonly communicationService: WidgetsStateRelayService,
        private readonly currentStateService: CurrentStateService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        private readonly managerService: ManagerService,
        private readonly widgetsService: WidgetsService,
        private readonly modalService: ModalDialogService,
        private readonly widgetService: WidgetService,
        private readonly userService: UserService,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly userEntitlementService: UserEntitlementService,
        private readonly dsdService: DatasetDefinitionsService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly location: Location,
        private readonly namedQueriesService: NamedQueriesService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initInfo();

        this.subscribeTo(this.currentStateService.clientCode$, (clientCode: string) => {
            this.clientCode = clientCode;
        });

        this.subscribeTo(this.currentStateService.isMultiClient$, (isMultiClient: boolean) => {
            this.isMultiClient = isMultiClient;
        });

        this.communicationService.enableWidgetSaveObs.subscribe({
            next: (status) => this.updateSaveBtnStatus(status),
        });
        this.communicationService.onWidgetInfoClearedObs.subscribe({
            next: (isWidgetFromFinder: boolean) => this.clearAll(isWidgetFromFinder),
        });
        this.communicationService.updateWidgetInfoObs.subscribe({
            next: (info) => this.updateInfo(info),
        });
        this.communicationService.widgetDimensionObs.subscribe({
            next: (info) => {
                if (this.widgetInfo) {
                    this.widgetInfo.defaultHeight = info.widgetDimension.height;
                    this.widgetInfo.defaultWidth = info.widgetDimension.width;
                    this.widgetInfo.widgetPosition = info.widgetPosition;
                }
            },
        });
        this.communicationService.updateVizConfigObs.subscribe({
            next: (config) => this.updateVizConfig(config),
        });

        this.userService.userPreferences$.subscribe({
            next: (preferences) => {
                this.userPreferences = preferences;
                this.userSelectedDate = { dateFrom: this.userPreferences.startDate ?? '', dateTo: this.userPreferences.endDate ?? '' };
            },
        });

        this.userEntitlementService.entitlementsForClientCode$.subscribe({
            next: (entitlements) => {
                this.isGlobalEditPartial = entitlements.haveGlobalEditPartial;
            },
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.datasetDefinition?.currentValue?.id) {
            if (this.namedQuerySubscription) {
                this.namedQuerySubscription.unsubscribe();
            }

            this.subscribeToNamedQuery();
        }

        if (changes.autoAddDynamicColumnsVisualizations && !changes.autoAddDynamicColumnsVisualizations.firstChange) {
            this.updateSaveBtnStatus(true);
        }
    }

    setNavRef(mwNavComponent: MWLeftNavComponent): void {
        this.navRef = mwNavComponent;
    }

    saveWidget(): void {
        if (this.validateUserConfiguration()) {
            if (this.widgetInfo?.isGlobal && !this.isGlobalEditPartial) {
                const confirmDialogOptions = getGlobalSaveDialogOptions();
                this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
                    next: (action) => {
                        if (action === 'confirm') {
                            this.saveWidgetInfo(this.widgetInfo);
                        }
                    },
                });
            } else if (this.widgetInfo?.id) {
                this.saveWidgetInfo(this.widgetInfo);
            } else {
                this.openSaveDialog({ isActiveDate: this.widgetInfo?.isActiveDate, clientCode: this.clientCode });
            }
        }
    }

    showSaveDialog(isCopy = false): void {
        if (this.validateUserConfiguration()) {
            const info = deepClone(this.widgetInfo);
            if (isCopy && info) {
                info.name = `${this.widgetInfo?.name} Copy`;
                info.id = undefined;
                info.widgetPermissions = [];
                info.isShared = false;
                info.description = '';
                info.isGlobal = false;
            }
            this.openSaveDialog(info, isCopy, info?.isGlobal);
        }
    }

    onApplySelectedFunds(funds: Fund[]): void {
        if (this.widgetInfo?.widgetFilters) {
            this.widgetInfo.widgetFilters.funds = funds;
        }
        this.updateCoreWidgetQueryParams();
        this.updateFuzzyDatesForInvestorDataset(funds);
    }

    onApplySelectedClients(clients: Client[]): void {
        if (this.widgetInfo?.widgetFilters) {
            this.widgetInfo.widgetFilters.clients = clients;
        }
        this.updateCoreWidgetQueryParams();
    }

    onApplyAdditionalFilters(filters: DashboardFilter[]): void {
        if (this.widgetInfo?.widgetFilters) {
            this.widgetInfo.widgetFilters.filters = filters;
        }
        this.updateCoreWidgetQueryParams();
    }

    onApplyDateChanges(filterDate: FilterQueryParam): void {
        Object.keys(filterDate).forEach((key) => {
            if (this.widgetInfo?.widgetFilters) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.widgetInfo.widgetFilters as any)[key] = this.isStackedQuery ? undefined : (filterDate as any)[key];
            }
        });

        if (this.widgetInfo) {
            // eslint-disable-next-line eqeqeq
            this.widgetInfo.isActiveDate = filterDate.activeDate !== null;
        }
        this.updateCoreWidgetQueryParams();
    }

    updateCoreWidgetQueryParams(isWidgetFromFinder?: boolean): void {
        // This updates the widget filters in the live-demo-widget
        this.communicationService.setCurrentQueryParams(this.widgetInfo?.widgetFilters);
        if (!isWidgetFromFinder) {
            this.managerService.sendMessageToAllWidgetsOnWorkspace(0, {
                action: 'UPDATE_WIDGET_FILTERS',
                filters: this.widgetInfo?.widgetFilters,
            });
        }
        this.queryParamsRef?.setQueryParams(this.widgetInfo?.widgetFilters);
        if (this.navRef && this.navRef.selectedWidgets.length !== 0) {
            this.updateSaveBtnStatus(true);
        }
    }

    updateWidgetName(): void {
        this.managerService.sendMessageToAllWidgetsOnWorkspace(0, {
            action: 'UPDATE_WIDGET_TITLE',
            titleInfo: {
                name: this.widgetInfo?.name,
                displayNameType: this.widgetInfo?.displayNameType,
                customDisplayName: this.widgetInfo?.customDisplayName,
            },
        });
    }

    updateEnableCompareMode(): void {
        this.managerService.sendMessageToAllWidgetsOnWorkspace(0, {
            action: 'UPDATE_ENABLE_COMPARE_MODE',
            info: {
                enableCompareMode: this.widgetInfo?.enableCompareMode,
            },
        });
    }

    clearAll(isWidgetFromFinder: boolean): void {
        this.initInfo();
        this.updateCoreWidgetQueryParams(isWidgetFromFinder);
        this.updateWidgetName();
        this.updateSaveBtnStatus(false);
    }

    isQueryTypeRecon(): boolean {
        return this.widgetInfo?.datasetDefinition?.queryType?.name === QueryTypeName.RECON;
    }

    updateVizConfig(config: VisualizationConfigs): void {
        // eslint-disable-next-line eqeqeq
        if (this.widgetInfo?.isActiveDate === null) {
            this.updateIsActiveDate(config.visualizationType);
        }
    }

    updateIsActiveDate(vizType: VisualizationType): void {
        if (this.widgetInfo) {
            this.widgetInfo.isActiveDate = this.widgetService.getDefaultIsActiveDate(vizType);
        }
    }

    openDashboardFilter(): void {
        const dashboardFilterRef = this.modalService.open(DashboardFiltersComponent, { windowClass: 'filter-popup' });
        dashboardFilterRef.componentInstance.filterTitle = 'Widget Filters';
        dashboardFilterRef.componentInstance.reducer = this.reducer;
        dashboardFilterRef.componentInstance.filterType = this.filterType;
        dashboardFilterRef.componentInstance.widgetId = this.manageWidgetId;
        dashboardFilterRef.componentInstance.isMultiClient = this.isMultiClient;
        this.queryParamsRef = dashboardFilterRef.componentInstance;
        dashboardFilterRef.componentInstance.fundsApply.subscribe({
            next: (funds: Fund[]) => {
                this.onApplySelectedFunds(funds);
            },
        });
        dashboardFilterRef.componentInstance.clientsApply.subscribe({
            next: (clients: Client[]) => {
                this.onApplySelectedClients(clients);
            },
        });
        dashboardFilterRef.componentInstance.dateFilterApply.subscribe({
            next: (dates: FilterQueryParam) => {
                this.onApplyDateChanges(dates);
            },
        });
        dashboardFilterRef.componentInstance.filterApply.subscribe({
            next: (widgetFilters: DashboardFilter[]) => {
                this.onApplyAdditionalFilters(widgetFilters);
            },
        });
    }

    refreshCoreWidget(coreWidgetId: string): void {
        this.widgetsService.fetchWidgetInfo(parseInt(coreWidgetId, 10)).subscribe({
            next: (widgetState: AppWidgetState) => {
                this.navRef?.onEditBaseWidget(widgetState);
            },
        });
    }

    validateUserConfiguration(): boolean {
        let allValid = true;
        let msg = 'Please complete the configuration for -';
        this.navRef?.validateWidgetConfigs().forEach((item) => {
            if (!item.isValid) {
                allValid = false;
                msg += ` ${item.name} to save the widget.`;
            }
        });
        if (!allValid) {
            this.alertService.warn(msg);
        }
        return allValid;
    }

    private updateInfo(info: AppWidgetState): void {
        this.widgetInfo = info;
        this.updateVizConfig(info.visualizationConfigs[0]);
        this.communicationService.setCurrentQueryParams(this.widgetInfo.widgetFilters);

        this.queryParamsRef?.setQueryParams(this.widgetInfo.widgetFilters);
        if (this.navRef && this.navRef.selectedWidgets.length !== 0) {
            this.updateSaveBtnStatus(true);
        }
        this.updateWidgetName();
        this.updateEnableCompareMode();
        this.updateSaveBtnStatus(false);
    }

    private initInfo(): void {
        this.widgetInfo = this.widgetService.getDefaultWidgetState();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private openSaveDialog(info: any, isCopy = false, isGlobal = false): void {
        const queryTypeName = isNamedQuery(this.datasetDefinition) ?
            this.datasetDefinition.type.name :
            this.datasetDefinition?.queryType?.name ?? '';

        this.saveWidgetDialogRef = this.modalService.open(SaveWidgetFormComponent, { windowClass: 'save-dialog' });
        const componentInstance: SaveWidgetFormComponent = this.saveWidgetDialogRef.componentInstance;
        componentInstance.formData = info;
        componentInstance.isCopy = isCopy;
        componentInstance.isGlobal = isGlobal;
        componentInstance.isComparableWidget = !!(this.navRef?.getUserSelectedConfigs()
            .visualizationConfigs.some((config) => COMPARABLE_VIZ_LIST.includes(config.visualizationType)) &&
                !isTradeFileDetails(queryTypeName));
        componentInstance.coreWidgetType = this.navRef?.getUserSelectedConfigs().coreWidgetType;
        componentInstance.userWidgetPermissions = info.widgetPermissions ? cloneArray(info.widgetPermissions) : [];
        componentInstance.userPreferences = this.userPreferences;

        if (this.isStackedQuery) {
            componentInstance.isStackedQuery = this.isStackedQuery;
            if (componentInstance.formData) {
                componentInstance.formData.enableCompareMode = false;
            }
        }

        componentInstance.widgetSaveFormSubmit.subscribe({
            next: (widgetData: AppWidgetState) => this.saveWidgetInfo(widgetData),
        });
    }

    private removeSaveDialog(): void {
        if (!this.saveWidgetDialogRef) {
            return;
        }
        this.saveWidgetDialogRef.close();
        this.saveWidgetDialogRef = undefined;
    }

    private saveWidgetInfo(info?: AppWidgetState): void {
        this.updateSaveBtnStatus(false);

        const widgetInfo = this.getWidgetInfo(info);
        const coreWidgetConfig = this.getCoreWidgetConfig(widgetInfo);
        this.setCoreWidgetConfigFilters(coreWidgetConfig);
        coreWidgetConfig.visualizationConfigs = this.getCoreWidgetConfigVisualizationConfigs(coreWidgetConfig);
        if (coreWidgetConfig.visualizationConfigs.length) {
            this.setCoreWidgetConfigSort(coreWidgetConfig);
        }

        // "-1" is the dummy dataset used for Named Queries
        if (typeof coreWidgetConfig.datasetDefinition?.id === 'string') {
            coreWidgetConfig.namedQueryId = coreWidgetConfig.datasetDefinition?.id;
            coreWidgetConfig.datasetDefinition.id = -1;
        }
        this.widgetsService.saveCoreWidget(coreWidgetConfig, this.isMultiClient).subscribe({
            next: (response) => this.onSaveCoreWidgetSuccess(response),
            error: (error) => this.onSaveCoreWidgetError(error._body, coreWidgetConfig),
        });
    }

    private updateSaveBtnStatus(status = true): void {
        // For a new widget privilege is undefined till it is saved
        const userPermission = this.widgetInfo?.widgetPermissions?.some((permission) => this.hasEditPermission(permission));

        this.enableSave = !!(this.widgetInfo?.privilege ?
            ((this.widgetInfo.privilege === 'EDIT' || userPermission) && status) :
            (status));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(this.cdr as any).destroyed) {
            this.cdr.detectChanges();
        }
    }

    private hasEditPermission(permission: UserPermission): boolean {
        const { privilege, name } = permission;
        return privilege === 'EDIT' && name.toLocaleLowerCase() === this.userPreferences?.username.toLocaleLowerCase();
    }

    private getWidgetInfo(widgetInfo?: AppWidgetState): AppWidgetState {
        return Object.assign(this.widgetService.getDefaultWidgetState(), widgetInfo ?? this.widgetInfo);
    }

    private getCoreWidgetConfig(widgetInfo: AppWidgetState): AppWidgetState {
        return new AppWidgetState(deepClone({
            ...widgetInfo,
            id: widgetInfo.id,
            widgetFilters: this.queryParamsRef?.queryParams,
            defaultHeight: this.widgetInfo?.defaultHeight,
            defaultWidth: this.widgetInfo?.defaultWidth,
            widgetPosition: this.widgetInfo?.widgetPosition,
            isActiveDate: this.widgetInfo?.isActiveDate,
            version: widgetInfo.version,
            ...this.navRef?.getUserSelectedConfigs(),
        } as AppWidgetState));
    }

    private setCoreWidgetConfigFilters(coreWidgetConfig: AppWidgetState): void {
        if (!coreWidgetConfig.widgetFilters) {
            return;
        }
        coreWidgetConfig.widgetFilters.id = this.widgetInfo?.widgetFilters?.id;
        if (coreWidgetConfig.widgetFilters.filters) {
            coreWidgetConfig.widgetFilters.filters.forEach((filter) => {
                filter.filterValuesType = this.getFilterValuesType(filter.values ?? []);
            });
        }
    }

    private getCoreWidgetConfigVisualizationConfigs(coreWidgetConfig: AppWidgetState): VisualizationConfigs[] {
        const vizIds = this.widgetInfo?.visualizationConfigs.reduce((accum, config) => {
            accum[config.visualizationType] = config;
            return accum;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, {} as any);

        return coreWidgetConfig.visualizationConfigs.reduce((updatedConfigs, vs) => {
            let updatedVizConfig = vs;
            // The first config is default
            updatedVizConfig.isDefault = !updatedConfigs.length;

            if (vizIds[updatedVizConfig.visualizationType]) {
                updatedVizConfig.id = vizIds[updatedVizConfig.visualizationType].id;
                // Get values from the visualization configs
                updatedVizConfig = Object.assign(vizIds[updatedVizConfig.visualizationType], vs);
            }

            if (updatedVizConfig.configs?.slicers) {
                updatedVizConfig.configs.slicers.forEach((slicer) => slicer.isDefault = false);
            }

            if (updatedVizConfig.visualizationType === 'SIMPLE_GRID' || updatedVizConfig.visualizationType === 'ADVANCED_GRID') {
                this.setAutoAddDynamicColumns(updatedVizConfig);
            }

            updatedConfigs.push(updatedVizConfig);

            return updatedConfigs;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, [] as any[]);
    }

    private setAutoAddDynamicColumns(updatedVizConfig: VisualizationConfigs): void {
        if (this.autoAddDynamicColumnsVisualizations?.size) {
            const autoAddDynamicColumns = this.autoAddDynamicColumnsVisualizations.get(updatedVizConfig.visualizationType);
            if (autoAddDynamicColumns !== updatedVizConfig.autoAddDynamicColumns) {
                updatedVizConfig.autoAddDynamicColumns = autoAddDynamicColumns;
            }
        } else {
            if (updatedVizConfig.autoAddDynamicColumns == null) {
                updatedVizConfig.autoAddDynamicColumns = false;
            }
        }
    }

    private setCoreWidgetConfigSort(coreWidgetConfig: AppWidgetState): void {
        coreWidgetConfig.tableSortDirection = coreWidgetConfig.visualizationConfigs[0].tableSortDirection;
        coreWidgetConfig.sortTableBy = coreWidgetConfig.visualizationConfigs[0].sortTableBy;

        coreWidgetConfig.visualizationConfigs.forEach((vc) => {
            if (vc.configs?.slicers?.length) {
                vc.configs.slicers.forEach((slicer) => {
                    if (slicer.configCustomSortOrder && typeof slicer.configCustomSortOrder !== 'string') {
                        slicer.configCustomSortOrder =
                            JSON.stringify(slicer.configCustomSortOrder.length ? slicer.configCustomSortOrder : []);
                    }

                    if (slicer.colorType !== 'MONOCHROMATIC' || vc.tableSortDirection === 'CUSTOM') {
                        slicer.colorSortBy = undefined;
                        slicer.colorSortDirection = undefined;
                    }
                });
            }
        });
    }

    private updateFuzzyDatesForInvestorDataset(funds: Fund[]): void {
        if (this.isInvestor()) {
            const updatedFunds = funds.map((fund) => ({ code: fund.fundId }));
            const queriesIds = [this.widgetInfo?.namedQueryId ?? this.widgetInfo?.datasetDefinition?.id];
            this.fuzzyDatesService.pushMostRecentFuzzyDatesForInvestorDataset(updatedFunds, queriesIds).subscribe();
        }
    }

    private getFilterValuesType(filterValues: (string | number | boolean)[]): string | undefined {
        const allValuesTypes: string[] = filterValues?.map((value) => {
            if (typeof value === 'object' && value == null) {
                return 'null';
            }

            return typeof value;
        });

        const valuesTypes = Array.from(new Set(allValuesTypes));
        const type = valuesTypes.length === 1 ?
            valuesTypes[0] :
            valuesTypes.length === 2 ? valuesTypes.find((valuesType) => valuesType !== 'null') : '';

        return type;
    }

    private onSaveCoreWidgetSuccess(response: AppWidgetState): void {
        this.widgetInfo = response;
        this.updateWidgetName();
        this.updateEnableCompareMode();
        this.removeSaveDialog();
        const widgets = this.managerService.getWidgetIdsForWorkspace();
        widgets.forEach((widgetId) => {
            const prefs = this.managerService.getWidgetPreferences(widgetId);
            if (prefs && prefs.id === response.id) {
                this.managerService.setWidgetExtraPreferences(widgetId, prefs);
                this.managerService.setWidgetExtraReload(widgetId, true);
            }
        });
        const url = this.router.createUrlTree(['..', this.widgetInfo.id], { relativeTo: this.activatedRoute }).toString();
        this.location.replaceState(url);
    }

    private onSaveCoreWidgetError(error: { message: string } | string, coreWidgetConfig: AppWidgetState): void {
        if (error) {
            const errorBody = typeof error === 'string' ? JSON.parse(error) : error;

            if (errorBody.message === 'Widget name is already used') {
                this.saveWidgetDialogRef?.componentInstance.saveWidgetForm.get('name').setErrors({ isDuplicate: true });
                if (this.saveWidgetDialogRef) {
                    this.saveWidgetDialogRef.componentInstance.isSaveInvoked = false;
                }
                this.updateSaveBtnStatus(true);
            } else if (errorBody.message === 'Widget version is stale') {
                this.handleStaleWidgetVersion(errorBody.message, coreWidgetConfig.id ?? 0);
            }
        }
    }

    private handleStaleWidgetVersion(message: string, id: number): void {
        this.managerService.sendMessageToAllWidgetsOnWorkspace(0, { action: 'RESET_VIEW' });
        this.removeSaveDialog();
        this.confirmationService.showConfirmationPopup({ message }, true).subscribe({
            next: (action) => {
                if (action === 'confirm') {
                    this.refreshCoreWidget(id.toString());
                }
            },
        });
    }

    private subscribeToNamedQuery(): void {
        if (!this.datasetDefinition?.id) {
            return;
        }

        const query$: Observable<DatasetDefinitionDetails | NamedQuery> = typeof this.datasetDefinition.id === 'number' ?
            this.dsdService.fetchDatasetDefinitionDetails(this.datasetDefinition.id) :
            this.namedQueriesService.fetchNamedQuery(this.datasetDefinition.id);

        this.namedQuerySubscription = query$.subscribe({
            next: (namedQuery) => {
                this.queryTypeName = namedQuery.normalizeQueryTypeName();
                this.isStackedQuery = namedQuery.normalizeQueryIsStacked();
            },
        });
    }

    private isInvestor(): boolean {
        if (this.widgetInfo?.namedQueryId) {
            return isInvestorQueryType(this.queryTypeName);
        }
        return this.widgetInfo?.datasetDefinition?.dataType === 'Investor';
    }
}
