import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CurrentStateService, RealtimeActiveService } from '@ddv/behaviors';
import { ModalDialogService } from '@ddv/common-components';
import {
    DatasetManagerService,
    DatasetRefresherService,
    DefaultQueryParamsService,
    MetadataService,
    SlowDataApprovalService,
} from '@ddv/datasets';
import { UserService } from '@ddv/entitlements';
import { ClientDatasetFilterService, QueryParamsService } from '@ddv/filters';
import { LayoutService, ManagerService } from '@ddv/layout';
import {
    MANAGE_WIDGET_ID,
    WIDGET_LIFECYCLE_EVENT,
    ExportFilteredData,
    SummaryConfig,
    SummaryField,
    AppWidgetState,
    DataUpdateBody,
    MetadataLookup,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    DashboardClientQueryParam,
} from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { deepClone } from '@ddv/utils';
import { Subscription } from 'rxjs';

import { ApplicationBaseWidgetComponent } from '../../application-base-widget.component';
import { ExportableData } from '../../models/exportable-data';
import { FilteredDataService } from '../../services/filtered-data.service';
import { SummaryService } from '../../services/summary.service';
import { WidgetExportService } from '../../services/widget-export.service';
import { WidgetService } from '../../services/widget.service';

@Component({
    selector: 'app-summary-widget',
    templateUrl: 'summary-widget.component.html',
    styleUrls: ['summary-widget.component.scss'],
})
export class SummaryWidgetComponent extends ApplicationBaseWidgetComponent implements OnInit, OnDestroy {
    preferences: AppWidgetState | undefined;
    summaryConfiguration: SummaryConfig | undefined;
    summaries: SummaryField[] = [];
    isInitialized = false;
    private summaryData: { [key: string]: string | number }[] = [];
    private readonly widgetPreferenceSubscription: Subscription | undefined;
    private metadata: MetadataLookup | undefined;
    private metadataSubscription: Subscription | undefined;

    constructor(
        cdr: ChangeDetectorRef,
        clientDatasetFilterService: ClientDatasetFilterService,
        datasetManagerService: DatasetManagerService,
        layoutService: LayoutService,
        metadataService: MetadataService,
        modalService: ModalDialogService,
        widgetService: WidgetService,
        workspaceManager: ManagerService,
        queryParamsService: QueryParamsService,
        private readonly filteredDataService: FilteredDataService,
        private readonly summaryService: SummaryService,
        private readonly exportService: WidgetExportService,
        realtimeActiveService: RealtimeActiveService,
        currentStateService: CurrentStateService,
        slowDataApprovalService: SlowDataApprovalService,
        fuzzyDateService: FuzzyDatesService,
        userService: UserService,
        datasetRefresherService: DatasetRefresherService,
        defaultQueryParamsService: DefaultQueryParamsService,
    ) {
        super(
            cdr,
            clientDatasetFilterService,
            datasetManagerService,
            layoutService,
            metadataService,
            modalService,
            widgetService,
            workspaceManager,
            queryParamsService,
            slowDataApprovalService,
            realtimeActiveService,
            fuzzyDateService,
            defaultQueryParamsService,
            currentStateService,
            userService,
            datasetRefresherService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.preferences) {
            this.widgetPrefs = this.preferences;
            this.id = this.preferences.id ?? 0;
            this.initSummary();
        } else {
            this.headers = this.getHeaders({ hasCustomFilter: false, hasViz: false, settingsOverridesMenu: true });
            super.displayIcons();
            this.fetchWidgetPreferences();
        }

        this.metadataSubscription = this.metadataService.metadataState.subscribe((metadata) => {
            this.metadata = metadata.metadata.get(this.widgetPrefs?.id ?? 0);
            if (this.metadata) {
                this.updateSummaries();
            }
        });

        this.workspaceManager
            .isStackedQuery((this.widgetPrefs?.namedQueryId ?? this.widgetPrefs?.datasetDefinition?.id) ?? 0)
            .subscribe({
                next: (isStackedQuery) => this.isStackedQuery = isStackedQuery,
            });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.isInitialized = false;
        if (this.id !== MANAGE_WIDGET_ID) {
            if (this.widgetPreferenceSubscription) {
                this.widgetPreferenceSubscription.unsubscribe();
            }
            this.removeDataset();
        }
        if (this.metadataSubscription) {
            this.metadataSubscription.unsubscribe();
        }
    }

    initSummary(): void {
        this.isInitialized = true;
        this.summaryConfiguration = this.widgetPrefs?.summaryConfig;
        this.updateSummaries();
    }

    override widgetLifeCycleCallBack(
        eventName: WIDGET_LIFECYCLE_EVENT.WIDGET_PASSIVE_MODE |
        WIDGET_LIFECYCLE_EVENT.WIDGET_ACTIVE_MODE |
        WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
        data: any,
    ): void;
    override widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    override widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, param: WidgetLifeCycleData): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.WIDGET_PASSIVE_MODE:
                if (this.widgetPreferenceSubscription) {
                    this.widgetPreferenceSubscription.unsubscribe();
                }
                this.removeDataset();
                break;
            case WIDGET_LIFECYCLE_EVENT.WIDGET_ACTIVE_MODE:
                if (this.shouldWidgetReload()) {
                    this.fetchWidgetPreferences();
                    this.setWidgetReloaded();
                } else if (this.isWidgetPreferenceFetched) {
                    this.fetchMetaData();
                    this.fetchDataset();
                } else {
                    this.fetchWidgetPreferences();
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION:
                this.onInterWidgetCommunication(param as WidgetLifecycleEvent);
                break;
            case WIDGET_LIFECYCLE_EVENT.DATA_UPDATE:
                this.setSummaryData((param as DataUpdateBody).data, deepClone((param as DataUpdateBody).filters));
                if (this.isInitialized) {
                    this.updateSummaries();
                    this.enableMenuIcons();
                }
                this.isDataLoaded = true;
        }
    }

    override onInterWidgetCommunication(data: WidgetLifecycleEvent): void {
        super.onInterWidgetCommunication(data);
        if (data === WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_DATA) {
            const exportFilteredData = this.getExportFilteredData();
            const filteredData = this.setExportData(exportFilteredData);
            this.filteredDataService.updateFilteredData(filteredData ?? {});
        } else if (data === WIDGET_LIFECYCLE_EVENT.EXPORT_FULL_DATA) {
            const exportFullData = this.getExportFullData();
            const fullData = this.setExportData(exportFullData);
            this.filteredDataService.updateFullData(fullData ?? {});
        } else if (data === WIDGET_LIFECYCLE_EVENT.LOADING_DATA) {
            this.onDataLoading();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    override widgetLifeCyclePostProcess(eventName: WidgetLifecycleEvent, data: any): void {
        super.widgetLifeCyclePostProcess(eventName, data);
    }

    getExportFilteredData(): ExportFilteredData {
        const exportData = this.summaries.map((field) => ({
            columnValue: field.columnValue.replace(/,/g, ''),
            columnName: field.columnName,
        }));
        return { data: exportData, summary: {} };
    }

    getExportFullData(): ExportFilteredData {
        const exportData = this.summaries.map((field) => ({
            columnValue: field.columnValue.replace(/,/g, ''),
            columnName: field.columnName,
        }));
        return { data: exportData, summary: {} };
    }

    exportWidget(): void {
        this.onInterWidgetCommunication(WIDGET_LIFECYCLE_EVENT.EXPORT_FILTERED_DATA);
        if (this.metadata) {
            this.exportService.exportWidgetFilteredCSV(this.widgetOnBoard?.id ?? 0, this.metadata);
        }
    }

    private fetchWidgetPreferences(): void {
        this.widgetPrefs = this.workspaceManager.getWidgetPreferences(this.id) ?? undefined;
        this.isWidgetPreferenceFetched = true;

        if (!this.widgetPrefs) {
            return;
        }

        Object.assign(this.widgetPrefs, this.widgetService.getWidgetSetting(this.widgetPrefs));
        this.widgetPrefs.name = this.getWidgetDisplayName(this.widgetPrefs);
        this.widgetPrefs.widgetPosition = this.layoutService.getWidgetPositionById(this.id);
        this.workspaceManager.setWidgetExtraPreferences(this.id, this.widgetPrefs);
        this.initSummary();
        this.fetchMetaData();
        this.fetchDataset();
        this.updateWidgetTitle();
    }

    private setExportData(exportData: ExportFilteredData): Record<string, ExportableData> | undefined {
        if (!this.widgetPrefs) {
            return;
        }

        return {
            [this.getWidgetId()]: {
                datasetId: this.widgetPrefs.datasetDefinition?.id ?? 0,
                widgetName: this.widgetPrefs.displayNameType === 'CUSTOM' ? this.widgetPrefs.customDisplayName : this.widgetPrefs.name,
                data: exportData.data,
                summary: exportData.summary,
                datasetType: this.widgetPrefs.datasetDefinition?.dataType,
                startDate: this.filters.startDate,
                endDate: this.filters.endDate,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private setSummaryData(data: any[], filters?: DashboardClientQueryParam): void {
        if (!filters) {
            return;
        }

        if (this.isStackedQuery) {
            filters.activeDate = null;
        }
        this.summaryData = this.clientDatasetFilterService.filterData(data, filters);
    }

    private updateSummaries(): void {
        this.updateSummariesDatatypes();
        this.summaries = this.summaryService.getSummaries(this.summaryData, this.summaryConfiguration?.summaries ?? []);
    }

    private updateSummariesDatatypes(): void {
        this.summaryConfiguration?.summaries.forEach((summary) => {
            if (this.metadata?.[summary.summaryField]) {
                summary.summaryDatatype = this.metadata[summary.summaryField].datatype;
            }
        });
    }
}
