import { DashboardSnapshot } from '@ddv/models';

export class DashboardGroup {
    id: number;
    name: string;
    sortOrder: number;
    dashboards: DashboardSnapshot[];
    lastOpened?: string;
    isDefault?: boolean;

    constructor(init?: Partial<DashboardGroup>) {
        this.id = init?.id ?? 0;
        this.name = init?.name ?? '';
        this.sortOrder = init?.sortOrder ?? -1;
        this.lastOpened = init?.lastOpened;
        this.isDefault = init?.isDefault;

        this.dashboards = init?.dashboards ?
            init.dashboards.map((dashboard) => new DashboardSnapshot(dashboard)) :
            [];
    }
}
