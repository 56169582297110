<div class="view-notes-container">
    @if (!!dashboardNote) {
        <div class="view-notes" (mouseenter)="listIconHighlight = true" (mouseleave)="listIconHighlight = false" (click)="openDashboardNotesModal()">
            <cc-icon
                [icon]="'list'"
                [size]="'medium'"
                [color]="'var(--grid-action-color)'"
                [class.highlighted]="listIconHighlight"
                (click)="openDashboardNotesModal()"
            >
            </cc-icon>
            <span>{{ dashboardNote.buttonLabel }}</span>
        </div>
    } @else if (!inPresentationMode && (!isGlobal || !isGlobalEditPartial)) {
        <div class="add-note" (mouseenter)="plusIconHighlight = true" (mouseleave)="plusIconHighlight = false" (click)="openConfigureDashboardNotesModal()">
            <cc-icon
                [icon]="'plus-circle'"
                [size]="'medium'"
                [color]="'var(--grid-action-color)'"
                [class.highlighted]="plusIconHighlight"
                (click)="openConfigureDashboardNotesModal()"
            >
            </cc-icon>
            <span>Add Note</span>
        </div>
    }
</div>

<app-filters-bar
    [dashboardId]="dashboardId"
    [inPresentationMode]="inPresentationMode"
>
</app-filters-bar>

@if (isDashboardNotesModalOpen) {
    <app-dashboard-notes
        ccModal
        [inPresentationMode]="inPresentationMode"
        [dashboardNote]="dashboardNote"
        [isGlobal]="isGlobal"
        [isGlobalEditPartial]="isGlobalEditPartial"
        (closeDashboardNotesModal)="closeDashboardNotesModal()"
        (openConfigureDashboardNotesModal)="openConfigureDashboardNotesModal()"
    >
    </app-dashboard-notes>
}

@if (isConfigureDashboardNotesModalOpen) {
    <app-configure-dashboard-notes
        ccModal
        [dashboardNote]="dashboardNote"
        (closeConfigureDashboardNotesModal)="closeConfigureDashboardNotesModal()"
        (deleteDashboardNote)="onDeleteDashboardNote()"
        (saveDashboardNote)="onSaveDashboardNote($event)"
    >
    </app-configure-dashboard-notes>
}
