import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { DataType, DatasetDefinitionDetails, QueryPeriodType, QueryType, DatasetDefinition } from '@ddv/models';
import { delay, Observable, of, ReplaySubject, share, take } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DatasetDefinitionsService {
    private readonly dsDetailsCache: Map<number, Observable<DatasetDefinitionDetails>> = new Map();
    private clientCode = '';

    constructor(
        private readonly currentStateService: CurrentStateService,
        @Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService,
    ) {
        this.currentStateService.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
    }

    fetchDataTypes(all = false): Observable<DataType[]> {
        return this.ddvApiService.invokeServiceWithParams(this.clientCode, 'data-types', { all });
    }

    createDataType(dataType: DataType): Observable<DataType> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, 'data-types', 'POST', dataType);
    }

    fetchQueryTypes(): Observable<QueryType[]> {
        return this.ddvApiService.invokeServiceWithParams(this.clientCode, 'query-types');
    }

    fetchQueryPeriodTypes(): Observable<QueryPeriodType[]> {
        return this.ddvApiService.invokeServiceWithParams(this.clientCode, 'query-period-types');
    }

    fetchDatasets(type: string, searchTerm = ''): Observable<DatasetDefinition[]> {
        return this.ddvApiService.invokeServiceWithParams(
            this.clientCode,
            `datatypes/${type}/datasets`,
            { dataSetSearchString: searchTerm });
    }

    fetchDatasetDefinitionDetails(datasetId: number): Observable<DatasetDefinitionDetails> {
        let load: Observable<DatasetDefinitionDetails> | undefined = this.dsDetailsCache.get(datasetId);
        if (load) {
            return load;
        }

        load = this.ddvApiService.invokeServiceWithParams<DatasetDefinition>(this.clientCode, `dataset-definitions/${datasetId}`)
            .pipe(share({
                connector: () => new ReplaySubject(1),
                resetOnError: false,
                resetOnComplete: () => of(true).pipe(delay(30_000)), // caches the data for 30 seconds
                resetOnRefCountZero: false,
            }),
            take(1),
            map((init) => {
                const id = Number(init.id);
                return new DatasetDefinitionDetails({ ...init, id });
            }));
        this.dsDetailsCache.set(datasetId, load);
        return load;
    }

    saveDatasetDefinition(datasetRequest: DatasetRequest): Observable<DatasetDefinition> {
        // in order to make using DSDs easier later, we changed the id to not be optional
        // but the MW will fail if you pass the 0 on create, so we're jumping through a few hoops here for create
        const isCreate = datasetRequest.datasetDefinition.id === 0;
        const id = isCreate ? undefined : datasetRequest.datasetDefinition.id;
        if (isCreate) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (datasetRequest.datasetDefinition as any).id = undefined;
        }

        const method = id ? 'PUT' : 'POST';
        this.dsDetailsCache.delete(Number(id) ?? 0);
        return this.ddvApiService.invokeServiceWithBody<DatasetDefinition>(
            this.clientCode,
            `dataset-definitions/${id ?? ''}`,
            method,
            datasetRequest);
    }

    deleteDatasetDefinition(id: number): Observable<{success?: boolean}> {
        this.dsDetailsCache.delete(id);
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `dataset-definitions/${id}`, 'DELETE', null);
    }

    fetchAllVisualizations(): Observable<{ id: number, name: string, widgetType: string }[]> {
        return this.ddvApiService.invokeServiceWithParams(this.clientCode, 'visualizations');
    }
}

export interface DatasetRequest {
    datasetDefinition: DatasetDefinitionDetails;
    supportedVisualizationNames: string[];
}
