import { DdvDate } from '@ddv/models';

export enum HiDateStatus { ALL, PARTIAL, NONE }

export class HiDate {
    date: DdvDate;
    numPosted: number | undefined;
    numTotal: number | undefined;

    constructor(init?: Partial<HiDate>) {
        this.date = init?.date ?? DdvDate.empty;
        this.numPosted = init?.numPosted;
        this.numTotal = init?.numTotal;
    }

    get status(): HiDateStatus {
        return this.numPosted === 0 ? HiDateStatus.NONE : this.numPosted === this.numTotal ? HiDateStatus.ALL : HiDateStatus.PARTIAL;
    }
}
