import { DashboardPreference } from '../dashboards/dashboard-preference';
import { FilterPreference } from './filter-preference';
import { FilterQueryParam } from './filter-query-param';

export function toFilterQueryParams(queryParam: DashboardPreference | FilterPreference | undefined): FilterQueryParam {
    return {
        startDate: queryParam?.startDate,
        endDate: queryParam?.endDate,
        activeDate: queryParam?.activeDate,
        funds: queryParam?.funds,
        comparing: queryParam?.comparing,
        isComparing: queryParam?.isComparing,
        compareDates: queryParam?.compareDates,
        isPreferenceChangedOnRefresh: (queryParam as DashboardPreference)?.isPreferenceChangedOnRefresh, // only on DashboardPreference
        timestamp: (queryParam as FilterPreference)?.timestamp, // only on FilterPreference
        clients: queryParam?.clients,
        acknowledged: (queryParam as DashboardPreference)?.acknowledged, // only on DashboardPreference
        // for net-settlement, we have no reason to stash this on the dashboard preference as we never save the state
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        includeManuallyReleased: (queryParam as any)?.includeManuallyReleased, // only relevant for net-settlement breaks
    };
}
