import { ElementRef, Injectable } from '@angular/core';
import { DEVICE_MODE, DeviceMode, Mode, MODE, Widget, WidgetPosition } from '@ddv/models';
import { ThemeService } from '@hs/ui-core-presentation';

import { LayoutConfig } from '../models/layout-config';
import { CustomLayoutHandler } from './custom-layout';
import { ManagerService } from './manager.service';

export interface TopLevelWorkspaceComponent {
    gridcanvas: ElementRef | undefined;
    dashboardContainer: ElementRef | undefined;
    dummyDashboardDiv: ElementRef | undefined;
}

@Injectable({ providedIn: 'root' })
export class LayoutService {
    // TODO: public state
    public bigwTableScroll: IScrollPosition = { top: 0, left: 0, right: 0, bottom: 0 };

    private gridCanvas: HTMLCanvasElement | undefined;
    private dashboardContainer: HTMLElement | undefined;
    private dummyDashboardDiv: HTMLElement | undefined;
    private vizDataTableWrapper: Element | undefined;
    private layoutHandler: CustomLayoutHandler | undefined;

    constructor(
        private readonly managerService: ManagerService,
        private readonly themeService: ThemeService,
    ) { }

    initializeLayoutManager(): void {
        const layoutConfig: LayoutConfig = {
            minWidth: 800,
            gridSize: 10,
            referenceWidth: 1600,
            tabletModeWidth: 953,
        };
        const layoutHandler = new CustomLayoutHandler(this.managerService, layoutConfig, this.themeService);

        this.managerService.register({
            layoutHandlers: { custom: layoutHandler },
            workspace: undefined,
        });
        this.managerService.configureWidgetContentVisibilityWhileDrag(false);
        this.setLayoutHandler(layoutHandler);
    }

    setTopLevelWorkspaceComponent(component: TopLevelWorkspaceComponent): void {
        this.setGridCanvas(component.gridcanvas?.nativeElement);
        this.setDashboardContainer(component.dashboardContainer?.nativeElement);
        this.setDummyDashboardDiv(component.dummyDashboardDiv?.nativeElement);
    }

    get vizDataTableElementWrapper(): Element | undefined {
        return this.vizDataTableWrapper;
    }

    private setLayoutHandler(layoutHandler: CustomLayoutHandler): void {
        this.layoutHandler = layoutHandler;

        this.layoutHandler.setGridCanvas(this.gridCanvas);
        this.layoutHandler.setDummyDashboardDiv(this.dummyDashboardDiv);
        this.layoutHandler.dashboardContainer = this.dashboardContainer;
        this.layoutHandler.setDashboard();
    }

    addWidgets(widgets: Widget[]): void {
        widgets.forEach((widget) => this.layoutHandler?.updateWidgetPosition(widget, true));
    }

    updateLayout(): void {
        this.layoutHandler?.configureWorkspaceLayout();
    }

    setScrollPositions(element: Element | undefined): void {
        if (!element) {
            return;
        }

        this.vizDataTableWrapper = element;
        this.bigwTableScroll.left = element.scrollLeft;
        this.bigwTableScroll.right = element.scrollWidth - element.clientWidth - element.scrollLeft;
        this.bigwTableScroll.top = element.scrollTop;
        this.bigwTableScroll.bottom = element.scrollHeight - element.clientHeight - element.scrollTop;
    }

    getWorkspaceMode(): Mode {
        if (!this.layoutHandler) {
            return MODE.VIEW;
        }
        return this.layoutHandler.getWorkspaceMode() || MODE.VIEW;
    }

    toggleTabletMode(deviceMode: DeviceMode): void {
        this.layoutHandler?.toggleTabletMode(deviceMode === DEVICE_MODE.TABLET);
    }

    resetLayoutForNewDashboard(deviceMode: DeviceMode): void {
        this.updateLayout();
        this.toggleTabletMode(deviceMode);
        this.setScrollPositions(this.vizDataTableElementWrapper);
    }

    changeWorkspaceMode(mode: Mode): void {
        this.layoutHandler?.setWorkspaceMode(mode);
        const dashboard = this.managerService.getWorkspace();
        if (dashboard) {
            dashboard.updateExtraParameters((extraParams) => {
                extraParams.mode = mode;
            });
            this.gridLines();
        }
    }

    updateWidgetBehaviour(isManagingWidget: boolean): void {
        const widgetIds = this.managerService.getWidgetIdsForWorkspace() || [];
        for (const widgetId of widgetIds) {
            this.layoutHandler?.updateWidgetIcons(isManagingWidget, widgetId);
        }
    }

    gridLines(): void {
        if (this.getWorkspaceMode() !== MODE.VIEW) {
            this.layoutHandler?.drawGrid(true);
        } else {
            this.layoutHandler?.clearGrid();
        }
    }

    removeGridLines(): void {
        this.layoutHandler?.clearGrid();
    }

    cancelWorkspaceChanges(): void {
        this.layoutHandler?.recomputeWorkspaceHeight();
    }

    getWidgetScaledPosition(widgetPosition: WidgetPosition): { width: number, height: number, top: number, left: number } | undefined {
        return this.layoutHandler?.getWidgetScaledPosition(widgetPosition);
    }

    getWidgetPositionById(widgetId: number): WidgetPosition | undefined {
        return this.layoutHandler?.getWidgetPositionById(widgetId);
    }

    getWidgetDefaultPosition(widgetPosition?: WidgetPosition): WidgetPosition | undefined {
        return this.layoutHandler?.getWidgetDefaultPosition(widgetPosition);
    }

    private setGridCanvas(gridCanvas: HTMLCanvasElement): void {
        this.gridCanvas = gridCanvas;

        if (this.layoutHandler) {
            this.layoutHandler.setGridCanvas(gridCanvas);
        }
    }

    private setDashboardContainer(dashboardContainer: HTMLElement): void {
        this.dashboardContainer = dashboardContainer;

        if (this.layoutHandler) {
            this.layoutHandler.dashboardContainer = this.dashboardContainer;
        }
    }

    private setDummyDashboardDiv(dummyDashboardDiv: HTMLElement): void {
        this.dummyDashboardDiv = dummyDashboardDiv;

        if (this.layoutHandler) {
            this.layoutHandler.setDummyDashboardDiv(this.dummyDashboardDiv);
        }
    }
}

export interface IScrollPosition {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
}
