<div class="button-menu">
    <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="showExportOptions = false">
        @if (inPresentationMode && userCanExport) {
            <cc-icon
                class="export-icon"
                title="Export"
                [icon]="'logout'"
                [size]="'medium'"
                (iconClicked)="toggleExportOptions()"
            />
        }

        <div class="dropdown right-position single padd0" [hidden]="!showExportOptions">
            @if (showExportOptions) {
                <app-dashboard-export (exportOptionsClose)="closeDashboardExport($event)"></app-dashboard-export>
            }
        </div>
    </div>

    @if (!inPresentationMode) {
        <span
            class="icon-desktop"
            title="Desktop"
            [class.selected]="currentDashboard?.getExtraParameters().deviceMode === 'DESKTOP'"
            [class.disabled-icon]="isTabletMode"
            (click)="toggleMode('DESKTOP')"
        >
        </span>

        <span
            class="icon-tablet"
            title="Tablet"
            [class.selected]="currentDashboard?.getExtraParameters().deviceMode === 'TABLET'"
            (click)="toggleMode('TABLET')"
        >
        </span>
    }

    <cc-icon
        class="icon-filter-tablet"
        title="Filter"
        [icon]="'filter'"
        [size]="'medium'"
        (iconClicked)="openDashboardFilter()"
    />
</div>
