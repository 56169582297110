import { Injectable } from '@angular/core';
import { RealtimeActiveService } from '@ddv/behaviors';
import { UserService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import { LayoutService, ManagerService } from '@ddv/layout';
import {
    Client,
    DashboardFilter,
    DashboardPreference,
    QueryPeriodTypeName,
    QueryTypeName,
    UserPreferences,
} from '@ddv/models';
import { NamedQueriesService } from '@ddv/named-queries';
import { ClientsService } from '@ddv/reference-data';
import { deepClone } from '@ddv/utils';
import { WidgetConfigurationManager } from '@ddv/widgets';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DashboardService } from './dashboard.service';

@Injectable()
export class DdvWidgetService {
    // TODO: public state
    public readonly detailWidgetId: Observable<number | undefined>;
    public readonly currentOpenedDetailWidgetId: Observable<number | undefined>;
    public dashboardQueryParams: DashboardPreference | undefined;
    public userPreferences: UserPreferences | undefined;

    private viewLevelClients: Client[] | undefined;
    private fullClientList: Client[] | undefined;
    private originalViewDates: { startDate: string, endDate: string, activeDate: string } | undefined;
    private originalViewFilters: DashboardFilter[] | undefined;
    private detailWidgetsOpened: number[] = [];
    private readonly detailWidgetIdSubject: Subject<number | undefined> = new ReplaySubject(1);
    private readonly currentOpenedDetailWidgetIdSubject: Subject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

    constructor(
        private readonly dashboardService: DashboardService,
        private readonly layoutService: LayoutService,
        private readonly manager: ManagerService,
        private readonly queryParamsService: QueryParamsService,
        private readonly userService: UserService,
        private readonly clientsService: ClientsService,
        private readonly namedQueryService: NamedQueriesService,
        private readonly realtimeActiveService: RealtimeActiveService,
    ) {
        this.detailWidgetId = this.detailWidgetIdSubject.asObservable();
        this.currentOpenedDetailWidgetId = this.currentOpenedDetailWidgetIdSubject.asObservable();

        this.queryParamsService.dashboardQueryParams.subscribe((params) => {
            this.dashboardQueryParams = params;
        });

        this.userService.userPreferences$.subscribe((userPreferences) => {
            this.userPreferences = userPreferences;
        });

        this.clientsService.clients().subscribe((clients) => {
            this.fullClientList = clients.map((client) => {
                return { clientId: client, clientName: client };
            });
        });
    }

    openDetailWidgetInView(widgetId: number, clientCode: string): Observable<void> {
        this.realtimeActiveService.pauseRealtimeUpdates();

        return this.dashboardService.addDetailWidgetToBoard(widgetId)
            .pipe(
                switchMap((widgetState) => {
                    if (!widgetState.namedQueryId) {
                        return of({ widgetState, isNetSettlementBreaksQuery: false });
                    }

                    return this.namedQueryService.fetchNamedQuery(widgetState.namedQueryId).pipe(
                        map((namedQueryResponse) => {
                            const isNetSettlementBreaksQuery =
                                namedQueryResponse.type.name === QueryTypeName.SETTLEMENT_BREAKS;
                            return { widgetState, isNetSettlementBreaksQuery };
                        }),
                    );
                }),
                map(({ widgetState, isNetSettlementBreaksQuery }) => {
                    if (clientCode){
                        if (widgetState.displayNameType === 'CUSTOM') {
                            widgetState.customDisplayName = `${clientCode} - ${widgetState.customDisplayName}`;
                        } else {
                            widgetState.name = `${clientCode} - ${widgetState.name}`;
                        }
                    }
                    widgetState.isDetailWidget = true;
                    const widget = WidgetConfigurationManager.getWidgetConfigForWorkspace(
                        widgetState,
                        this.layoutService.getWidgetDefaultPosition(widgetState.widgetPosition));
                    widget.styleClasses = widgetState.enableWidgetHeader ? '' : 'hide-header';
                    widget.isDetailWidget = true;
                    this.manager.createWidget(widget);
                    this.manager.updateIsDetailWidgetOpened(true);
                    this.updateCurrentOpenedDetailWidgetId(widgetId);

                    const isCalendarVisible = !widgetState.datasetDefinition?.queryPeriodType ||
                        widgetState.datasetDefinition.queryPeriodType.name !== QueryPeriodTypeName.NO_CALENDAR;
                    const isDateRangeSupported = !widgetState.datasetDefinition?.queryPeriodType ||
                        widgetState.datasetDefinition.queryPeriodType.name === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE ||
                        widgetState.datasetDefinition.queryPeriodType.name === QueryPeriodTypeName.RANGE;
                    const isActiveDateSupported = !widgetState.datasetDefinition?.queryPeriodType ||
                        widgetState.datasetDefinition.queryPeriodType.name === QueryPeriodTypeName.RANGE_WITH_ACTIVE_DATE;
                    this.dashboardService.updateDatePickerInfo(isCalendarVisible, isDateRangeSupported, isActiveDateSupported);

                    this.originalViewDates = {
                        startDate: this.dashboardQueryParams?.startDate ?? '',
                        endDate: this.dashboardQueryParams?.endDate ?? '',
                        activeDate: this.dashboardQueryParams?.activeDate ?? '',
                    };
                    this.originalViewFilters = this.dashboardQueryParams?.filters;
                    const startDate = widgetState.widgetFilters?.startDate;
                    const endDate = widgetState.widgetFilters?.endDate;
                    const activeDate = widgetState.widgetFilters?.activeDate;
                    const queryParams: DashboardPreference = {
                        startDate,
                        endDate,
                        activeDate,
                        filters: [],
                    };

                    if (clientCode) {
                        const userPreferencesClients: Client[] = this.userPreferences?.clients?.map((client) => {
                            return { clientId: client.clientCode, clientName: client.clientCode };
                        }) ?? [];
                        this.viewLevelClients = deepClone(
                            this.dashboardQueryParams?.clients?.length ?
                                this.dashboardQueryParams.clients :
                                userPreferencesClients?.length ?
                                    userPreferencesClients :
                                    this.fullClientList);
                        queryParams.clients = [{ clientId: clientCode, clientName: clientCode }];
                    }

                    if (isNetSettlementBreaksQuery) {
                        queryParams.includeManuallyReleased = false;
                    }

                    this.queryParamsService.dispatchUpdatedQueryParams(queryParams);
                    this.manager.getWorkspace()?.extraParameters?.widgets.push(widgetState);
                    this.detailWidgetsOpened.push(widgetState.id ?? 0);
                }));
    }

    restoreOriginalViewFilters(): void {
        this.viewLevelClients = undefined;
        this.originalViewFilters = this.dashboardQueryParams?.filters;
    }

    resetDetailWidgetAndViewQueryParams(): void {
        this.detailWidgetsOpened = [];
        this.viewLevelClients = undefined;
        this.originalViewDates = undefined;
        this.originalViewFilters = undefined;
        this.manager.updateIsDetailWidgetOpened(false);
        this.updateDetailWidgetId(undefined);
    }

    removeLastOpenedDetailWidgetFromList(): void {
        this.realtimeActiveService.resumeRealtimeUpdates();

        this.updateDetailWidgetId(undefined);
        this.detailWidgetsOpened.pop();
        const detailWidgetsCount = this.detailWidgetsOpened.length;
        const currentDetailWidgetId = detailWidgetsCount ? this.detailWidgetsOpened[detailWidgetsCount - 1] : undefined;
        this.updateCurrentOpenedDetailWidgetId(currentDetailWidgetId);
    }

    setPreviousViewLevelQueryParams(): void {
        const filters: DashboardPreference = {};
        if (this.viewLevelClients) {
            filters.clients = this.viewLevelClients;
            this.viewLevelClients = undefined;
        }

        if (this.originalViewDates) {
            const { startDate, endDate, activeDate } = this.originalViewDates;
            filters.startDate = startDate;
            filters.endDate = endDate;
            filters.activeDate = activeDate;
            this.originalViewDates = undefined;
        }

        if (this.originalViewFilters) {
            filters.filters = this.originalViewFilters;
            this.originalViewFilters = undefined;
        }

        if (Object.keys(filters).length) {
            this.queryParamsService.dispatchUpdatedQueryParams(filters);
        }
    }

    isDetailWidgetLastOpened(widgetId: number): boolean {
        return this.detailWidgetsOpened[this.detailWidgetsOpened.length - 1] === widgetId;
    }

    updateDetailWidgetId(widgetId: number | undefined): void {
        this.detailWidgetIdSubject.next(widgetId);
    }

    private updateCurrentOpenedDetailWidgetId(widgetId: number | undefined): void {
        this.currentOpenedDetailWidgetIdSubject.next(widgetId);
    }
}
