import { Inject, Injectable } from '@angular/core';
import { ClearAllFilters, NamedQuery, DatasetDefinitionDetails, MetadataAndQueryURLsConfig, NamedQueryInit } from '@ddv/models';
import { cfbApiLocationToken, replaceHostInMetadataAndQueryURLsToken, trebekApiLocationToken } from '@ddv/named-queries';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class SelectedWidgetRelayService {
    public readonly isWidgetGlobal: Observable<boolean>;
    public readonly clearAllFilters$: Observable<ClearAllFilters>;

    private readonly isWidgetGlobalSubject: Subject<boolean> = new ReplaySubject<boolean>(1);
    private currentDatasetDefinition: DatasetDefinitionDetails | NamedQuery | undefined;
    private readonly clearAllFiltersSubject: Subject<ClearAllFilters> = new ReplaySubject<ClearAllFilters>(1);

    private readonly config: MetadataAndQueryURLsConfig;

    constructor(
        @Inject(replaceHostInMetadataAndQueryURLsToken) private readonly replaceHostInURLs: boolean,
        @Inject(trebekApiLocationToken) private readonly trebekApiLocation: string,
        @Inject(cfbApiLocationToken) private readonly cfbApiLocation: string,
    ) {
        this.isWidgetGlobal = this.isWidgetGlobalSubject.asObservable();
        this.clearAllFilters$ = this.clearAllFiltersSubject.asObservable();
        this.config = {
            replaceHostInURLs: this.replaceHostInURLs,
            trebekApiLocation: this.trebekApiLocation,
            cfbApiLocation: this.cfbApiLocation,
        };
    }

    relayIsWidgetGlobal(isWidgetGlobal: boolean): void {
        this.isWidgetGlobalSubject.next(isWidgetGlobal);
    }

    // only called by live demo widget
    updateCurrentDatasetDefinition(datasetDefinition: DatasetDefinitionDetails | NamedQuery): void {
        if (typeof datasetDefinition.id === 'string') {
            this.currentDatasetDefinition = new NamedQuery(datasetDefinition as NamedQueryInit, this.config);
        } else {
            this.currentDatasetDefinition = new DatasetDefinitionDetails(datasetDefinition);
        }
    }

    // only used by grid visualizations
    getCurrentDatasetDefinition(): DatasetDefinitionDetails | NamedQuery | undefined {
        return this.currentDatasetDefinition;
    }

    clearAllFilters(widgetId: number): void {
        this.clearAllFiltersSubject.next({ id: widgetId, clearAllFilters: true });
    }
}
