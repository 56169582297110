import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownOption } from '@hs/ui-core-common';

import { ButtonLabel, DashboardNote, SaveDashboardNote } from '../../models/dashboard-note';

@Component({
    selector: 'app-configure-dashboard-notes',
    templateUrl: 'configure-dashboard-notes.component.html',
    styleUrls: ['configure-dashboard-notes.component.scss'],
})
export class ConfigureDashboardNotesComponent implements OnInit {
    @Input() dashboardNote: DashboardNote | undefined;

    @Output() closeConfigureDashboardNotesModal: EventEmitter<void> = new EventEmitter();
    @Output() deleteDashboardNote: EventEmitter<void> = new EventEmitter();
    @Output() saveDashboardNote: EventEmitter<SaveDashboardNote> = new EventEmitter();

    protected buttonLabelOptions: DropdownOption[] = [{ text: 'Disclaimer', value: 'disclaimer' }, { text: 'View Notes', value: 'notes' }];
    protected buttonLabel: DropdownOption | undefined;
    protected editConfiguration = false;
    protected title: string | undefined;
    protected message: string | undefined;

    ngOnInit(): void {
        this.editConfiguration = !!this.dashboardNote;
        this.populateForm();
    }

    protected onDelete(): void {
        this.deleteDashboardNote.emit();
        this.closeConfigureDashboardNotesModal.emit();
    }

    protected onCancel(): void {
        this.closeConfigureDashboardNotesModal.emit();
    }

    protected onSave(): void {
        if (this.buttonLabel && this.title && this.message) {
            this.saveDashboardNote.emit({
                dashboardNote: {
                    buttonLabel: this.buttonLabel.text as ButtonLabel,
                    title: this.title,
                    message: this.message,
                },
                status: this.editConfiguration ? 'update' : 'new',
            });
        }
        this.closeConfigureDashboardNotesModal.emit();
    }

    private populateForm(): void {
        if (this.dashboardNote) {
            this.buttonLabel = this.buttonLabelOptions.find((option) => option.text === this.dashboardNote?.buttonLabel);
            this.title = this.dashboardNote.title;
            this.message = this.dashboardNote.message;
        }
    }
}
