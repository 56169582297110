import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { SelectedWidgetRelayService } from './base/selected-widget-relay.service';
import { ChartVisualizationsModule } from './charts/chart-visualizations.module';
import {
    DonutChartVisualizationComponent,
} from './charts/components/donut-chart-visualization/donut-chart-visualization.component';
import {
    HorizontalBarChartVisualizationComponent,
} from './charts/components/horizontal-bar-chart-visualization/horizontal-bar-chart-visualization.component';
import {
    HorizontalStackedBarChartVisualizationComponent,
} from './charts/components/horizontal-stacked-bar-chart-visualization/horizontal-stacked-bar-chart-visualization.component';
import {
    LineChartVisualizationComponent,
} from './charts/components/line-chart-visualization/line-chart-visualization.component';
import { PieChartVisualizationComponent } from './charts/components/pie-chart-visualization/pie-chart-visualization.component';
import {
    StackedAreaChartVisualizationComponent,
} from './charts/components/stacked-area-chart-visualization/stacked-area-chart-visualization.component';
import {
    VerticalBarChartVisualizationComponent,
} from './charts/components/vertical-bar-chart-visualization/vertical-bar-chart-visualization.component';
import {
    VerticalMirrorBarChartVisualizationComponent,
} from './charts/components/vertical-mirror-bar-chart-visualization/vertical-mirror-bar-chart-visualization.component';
import {
    VerticalStackedBarChartVisualizationComponent,
} from './charts/components/vertical-stacked-bar-chart-visualization/vertical-stacked-bar-chart-visualization.component';
import { AdvancedGridVisualizationComponent } from './grids/components/advanced-grid-visualization/advanced-grid-visualization.component';
import { SimpleGridVisualizationComponent } from './grids/components/simple-grid-visualization/simple-grid-visualization.component';
import { GridVisualizationsModule } from './grids/grid-visualizations.module';
import { GridUriBuilderService } from './grids/services/grid-uri-builder.service';

@NgModule({
    imports: [
        ChartVisualizationsModule,
        GridVisualizationsModule,
    ],
    exports: [
        AdvancedGridVisualizationComponent,
        DonutChartVisualizationComponent,
        LineChartVisualizationComponent,
        StackedAreaChartVisualizationComponent,
        HorizontalBarChartVisualizationComponent,
        HorizontalStackedBarChartVisualizationComponent,
        PieChartVisualizationComponent,
        SimpleGridVisualizationComponent,
        VerticalBarChartVisualizationComponent,
        VerticalMirrorBarChartVisualizationComponent,
        VerticalStackedBarChartVisualizationComponent,
    ],
    providers: [
        SelectedWidgetRelayService,
        DatePipe,
        GridUriBuilderService,
    ],
})
export class VisualizationsModule {}
