import { Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { initialDashboardPreferences, QueryParamsService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    TREBEK_QUERY_ERROR_MESSAGE,
    WIDGET_LIFECYCLE_EVENT,
    CompareMode,
    DashboardPreference,
    FilterPreference,
    FilterQueryParam,
    QueryParams,
    DatasetFetchKey,
    datasetFetchKeysMatch,
    SpecialCaseQueryParams,
    toFilterQueryParams,
    AppWidgetState,
} from '@ddv/models';
import { deepCompare } from '@ddv/utils';
import { combineLatest, Observable } from 'rxjs';

import { IDataLoad } from '../models/data-load';
import { Datasource, DataWrapper } from '../models/data-source';
import { PublicApiResponseRow } from '../models/trebek';
import { CompareModeService } from './compare-mode.service';
import { DatasetDatasetFetcherService } from './dataset-dataset-fetcher.service';
import { DatasetLoadState } from './dataset-load-state';
import { DatasetQueryParamsDifferService } from './dataset-query-params-differ.service';
import { DatasetStateManagementService } from './dataset-state-management.service';
import { WidgetDataSourceService } from './widget-datasource.service';

@Injectable()
export class DatasetManagerService {
    private readonly stashedWidgetData: Map<number, PublicApiResponseRow[]> = new Map(); // this is only for compare mode

    private sourceDataCopy: PublicApiResponseRow[] | undefined;
    private dashboardPreferences: DashboardPreference = initialDashboardPreferences;
    private dashboardQueryParams: FilterQueryParam | undefined;
    private readonly widgetFilterParam: Map<number, FilterQueryParam> = new Map();
    private widgetQueryParams: FilterPreference | undefined;
    private isMultiClient = false;

    constructor(
        currentStateService: CurrentStateService,
        private readonly managerService: ManagerService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly queryParamsService: QueryParamsService,
        private readonly compareModeService: CompareModeService,
        private readonly datasetFetcher: DatasetDatasetFetcherService,
        private readonly queryParamsDiffer: DatasetQueryParamsDifferService,
        private readonly stateManager: DatasetStateManagementService,
    ) {
        combineLatest([this.widgetDataSourceService.dataSource$, this.compareModeService.currentWidgetInCompareMode])
            .subscribe({
                next: ([datasource, currentWidgetInCompareMode]) => {
                    this.onWidgetDataSourceChanged(datasource, currentWidgetInCompareMode);
                },
            });

        currentStateService.isMultiClient$
            .subscribe({
                next: (isMultiClient) => this.isMultiClient = isMultiClient,
            });

        queryParamsService.dashboardQueryParams.subscribe({
            next: (dashboardPref) => this.onDashboardQueryParamsChanged(dashboardPref),
        });

        queryParamsService.widgetQueryParams.subscribe({
            next: (params) => {
                if (params?.lastChangedWidgetId) {
                    this.onWidgetQueryParamsChanged(params.lastChangedWidgetId, params.widgetFilters.get(params.lastChangedWidgetId));
                }
            },
        });
    }

    // this is only called when the user switches dashboards in view mode
    clearDashboardPreference(): void {
        this.dashboardPreferences = initialDashboardPreferences;
        this.dashboardQueryParams = undefined;
    }

    getUniqueKey(
        clientCode: string,
        dashboardId: string | number,
        widgetId: number,
        namedQueryId: string | number,
        isSubscribedToDashboardFilters: boolean | undefined,
    ): DatasetFetchKey {
        const state = this.stateManager.getState(widgetId);
        if (state) {
            return state.key;
        }

        let key = this.findSharedKey(namedQueryId, isSubscribedToDashboardFilters);
        if (!key) {
            key = {
                namedQueryId,
                dashboardId,
                sourceType: isSubscribedToDashboardFilters ? 'dashboard' : 'widget',
                sourceId: isSubscribedToDashboardFilters ? namedQueryId : widgetId,
            };
        }

        this.stateManager.createState(widgetId, clientCode, key);
        return key;
    }

    // this method looks super dodgy.  how could it have only a single set of rows?  this class loads many row-sets
    getSourceDataCopy(): PublicApiResponseRow[] | undefined {
        return this.sourceDataCopy;
    }

    getDataset(
        clientCode: string,
        dashboardId: string | number,
        widgetId: number, // i dont think this is the widgetId.  i think it's actually the widgetPreferencesId which is something else
        namedQueryId: number | string,
        isSubscribedToDashboardFilters: boolean | undefined,
    ): Observable<IDataLoad> {
        const state = this.getOrCreateState(clientCode, dashboardId, widgetId, namedQueryId, isSubscribedToDashboardFilters);

        // this is unfortunately still necessary
        // given the way DSM has evolved over time, there are many race conditions between:
        //  - the widget query params changing
        //  - the dashboard query params changing
        //  - SOMETHING in the parent widget changing and it deciding to call getDataset again
        // when realtime/refresh was added a timer pipe was used.  that does not emit for the first time until "the next tick"
        // so without this set timeout, some of the race conditions finish in a different order and that causes problems
        setTimeout(() => {
            if (!isSubscribedToDashboardFilters) {
                return;
            }

            if (state.hasData()) {
                this.emitExistingData(state);
            } else if (!state.isFetching()) {
                state.markAsLoading();

                this.fetchData(
                    namedQueryId,
                    this.dashboardPreferences,
                    this.dashboardQueryParams,
                    this.widgetQueryParams,
                    this.dashboardQueryParams?.comparing,
                    !!(this.widgetQueryParams?.isComparing ?? this.dashboardQueryParams?.isComparing),
                    state.key,
                    clientCode,
                    this.isMultiClient);
            }

            if (state.isFetchingCanceled()) {
                this.managerService.sendMessageToExistingWidget(widgetId, WIDGET_LIFECYCLE_EVENT.DATA_LOADING_CANCELLED);
            } else if (state.isLoading()) {
                this.managerService.sendMessageToExistingWidget(widgetId, WIDGET_LIFECYCLE_EVENT.LOADING_DATA);
            }
        });

        return state.fetchDataObservable();
    }

    refreshDataset(key: DatasetFetchKey, markDatasetAsLoading = false): void {
        const state = this.stateManager.getState(key);
        if (!state) {
            return;
        }

        this.refreshDatasetForState(state, markDatasetAsLoading);
    }

    loadNoData(
        clientCode: string,
        dashboardId: string | number,
        widgetId: number | undefined,
        namedQueryId: number | string | undefined,
        isSubscribedToDashboardFilters: boolean | undefined,
    ): void {
        if (!widgetId || !namedQueryId) {
            return;
        }

        const uniqueKey = this.getUniqueKey(clientCode, dashboardId, widgetId, namedQueryId, isSubscribedToDashboardFilters);
        setTimeout(() => {
            const dataWrapper = { uniqueKey, data: [], compareMode: undefined };
            this.widgetDataSourceService.addDataSource(dataWrapper);
        }, 0);
    }

    removeDataset(widgetId: number): void {
        const state = this.stateManager.getState(widgetId);
        if (!state) {
            return;
        }

        // this is only for compare mode
        if (state.hasWidgetData()) {
            this.stashedWidgetData.set(widgetId, state.widgetData());
        }

        this.cancelRequest(state.key);
        this.stateManager.allStatesForKey(state.key).forEach((s) => s.clearFetchedData());
        this.widgetDataSourceService.removeDataSource(state.key);

        this.stateManager.removeState(widgetId);
        this.widgetFilterParam.delete(widgetId);
        this.queryParamsService.removeWidgetQueryParam(widgetId);
    }

    private findSharedKey(
        namedQueryId: string | number,
        isSubscribedToDashboardFilters: boolean | undefined,
    ): DatasetFetchKey | undefined {
        if (!isSubscribedToDashboardFilters) {
            return;
        }

        return this.stateManager.allSharedStatesForNamedQuery(namedQueryId)[0]?.key;
    }

    private onDashboardQueryParamsChanged(dashboardPreferences: DashboardPreference): void {
        this.dashboardPreferences = dashboardPreferences;
        const currentQueryParam = toFilterQueryParams(dashboardPreferences);

        if (
            currentQueryParam.comparing === CompareMode.BOTH ||
            !this.queryParamsDiffer.areQueryParamsEqual(this.dashboardQueryParams, currentQueryParam)
        ) {
            this.fetchDataForUpdatedDashboardQueryParams();
        } else if (!deepCompare(this.dashboardQueryParams, currentQueryParam)) {
            this.dashboardQueryParams = toFilterQueryParams(dashboardPreferences);
            this.triggerDataUpdateEvent(dashboardPreferences);
        }
    }

    private fetchDataForUpdatedDashboardQueryParams(): void {
        delete this.dashboardPreferences.isPreferenceChangedOnRefresh;
        this.dashboardQueryParams = toFilterQueryParams(this.dashboardPreferences);

        this.stateManager.allStatesForDashboards().forEach((state) => {
            this.managerService.sendMessageToExistingWidget(state.widgetId, WIDGET_LIFECYCLE_EVENT.LOADING_DATA);
            this.refreshDatasetForState(state, false);
        });
    }

    private onWidgetQueryParamsChanged(widgetId: number, widgetQueryParams: FilterPreference | undefined): void {
        this.widgetQueryParams = widgetQueryParams;
        const widgetPreferences = this.managerService.getWidgetPreferences(widgetId);
        // this is a strange check.  i'd guess that just checking that widgetPreferences are defined would be sufficient
        // i do not think there is a pattern by which the preferences can be defined but not have an id
        if (!widgetPreferences?.id) {
            return;
        }

        const currentQueryParam = toFilterQueryParams(widgetQueryParams);
        if (this.shouldFetchNewDataOnWidgetQueryParamsChange(widgetId, currentQueryParam, widgetPreferences)) {
            this.fetchDataForUpdatedWidgetQueryParams(widgetId, currentQueryParam, widgetPreferences);
        }
    }

    private shouldFetchNewDataOnWidgetQueryParamsChange(
        widgetId: number,
        currentQueryParam: FilterQueryParam,
        widgetPreferences: AppWidgetState | undefined,
    ): boolean {
        if (currentQueryParam.isComparing && currentQueryParam.comparing === CompareMode.BOTH) {
            return true;
        }

        // this is a really odd check.  i find it very unlikely that you can get here with a datasetDefinition without an id
        if (widgetPreferences?.datasetDefinition?.id && !deepCompare(this.widgetFilterParam.get(widgetId), currentQueryParam)) {
            return true;
        }

        // this check used to be this.onRealtimeUpdate
        // which was set to true anytime a unsubscribed widget was called for getDataset
        // see comment in ApplicationBaseWidget.onModeChange
        return !!widgetPreferences?.realtimeUpdates; // && !isSubscribedToDashboardFilters
    }

    private fetchDataForUpdatedWidgetQueryParams(
        widgetId: number,
        currentQueryParam: FilterQueryParam,
        widgetPreferences: AppWidgetState,
    ): void {
        const state = this.stateManager.getState(widgetId);
        if (!state) {
            return;
        }

        if (widgetPreferences.realtimeUpdates) {
            state.markAsLoading();
        }
        this.managerService.sendMessageToExistingWidget(widgetPreferences.id ?? 0, WIDGET_LIFECYCLE_EVENT.LOADING_DATA);

        this.fetchData(
            widgetPreferences.namedQueryId ?? widgetPreferences.datasetDefinition?.id ?? 0, // the ?? 0 should never be hit
            this.widgetQueryParams,
            this.dashboardQueryParams,
            this.widgetQueryParams,
            this.widgetQueryParams?.comparing,
            !!(this.widgetQueryParams?.isComparing ?? this.dashboardQueryParams?.isComparing),
            state.key,
            widgetPreferences.clientCode ?? '', // the ?? should never be hit
            this.isMultiClient);

        this.widgetFilterParam.set(widgetId, currentQueryParam);
    }

    private cancelRequest(uniqueKey: DatasetFetchKey): void {
        this.stateManager.allStatesForKey(uniqueKey).forEach((state) => {
            state.clearLoadTime();
            state.cancelFetching();
        });

        // leaving this for now as its been here for years, but also, uncalled for years
        // widgetId was an optional param to the function, but this function was never called with one
        // if (uniqueKey.sourceType === 'dashboard' && widgetId) {
        //     this.stateManager.allStates().forEach((state) => {
        //         state.markAsFetchingCanceled();
        //         if (datasetFetchKeysMatch(uniqueKey, state.key) && widgetId !== state.widgetId) {
        //             this.managerService.sendMessageToExistingWidget(widgetId, WIDGET_LIFECYCLE_EVENT.DATA_LOADING_CANCELLED);
        //         }
        //     });
        // }
    }

    private fetchData(
        namedQueryId: number | string,
        queryParams: QueryParams | undefined,
        dashboardQueryParam: FilterQueryParam | undefined,
        widgetQueryParams: QueryParams | undefined,
        compareMode: CompareMode | undefined,
        isComparing: boolean,
        key: DatasetFetchKey,
        clientCode: string,
        isMultiClient: boolean,
    ): void {
        this.cancelRequest(key);

        const states = this.stateManager.allStatesForKey(key);
        const fetchDataSubscription = this.datasetFetcher
            .fetchDataset(
                clientCode,
                key.dashboardId,
                states.map((state) => state.widgetId),
                namedQueryId,
                queryParams,
                onlySpecialCaseParams(dashboardQueryParam), // this is just makes the intent and testing easier
                widgetQueryParams,
                compareMode,
                isComparing,
                isMultiClient,
            )
            .subscribe({
                next: (results) => {
                    results.forEach((result) => {
                        states.forEach((state) => state.setWidgetData(result.data, result.compareMode));
                    });

                    this.updateDatasource(
                        states[0],
                        compareMode,
                        namedQueryId,
                        key);
                },
            });

        this.stateManager.allStatesForKey(key).forEach((state) => state.markAsFetching(fetchDataSubscription));
    }

    private updateDatasource(
        state: DatasetLoadState,
        compareMode: string | undefined,
        namedQueryId: number | string | undefined,
        uniqueKey: DatasetFetchKey,
    ): void {
        this.sourceDataCopy = state.widgetData();

        this.widgetDataSourceService.addDataSource({
            uniqueKey,
            data: state?.widgetData() ?? [],
            compareData: state?.widgetCompareData() ?? [],
            compareMode,
        });
    }

    private triggerDataUpdateEvent(queryParams: FilterQueryParam): void {
        this.stateManager.allStatesForDashboards().forEach((state) => {
            const widget = this.managerService.getWidgetById(state.widgetId);
            if (!widget) {
                return;
            }

            queryParams.isComparing = this.widgetQueryParams?.isComparing;
            widget.lifeCycleCallBack?.(
                WIDGET_LIFECYCLE_EVENT.DATA_UPDATE,
                {
                    data: state.fetchedData(),
                    filters: queryParams,
                    compareMode: queryParams.comparing,
                });
        });
    }

    private getOrCreateState(
        clientCode: string,
        dashboardId: string | number,
        widgetId: number,
        namedQueryId: number | string,
        isSubscribedToDashboardFilters: boolean | undefined,
    ): DatasetLoadState {
        let state = this.stateManager.getState(widgetId);
        if (!state) {
            // this forces the state to be created with the correct key
            this.getUniqueKey(clientCode, dashboardId, widgetId, namedQueryId, isSubscribedToDashboardFilters);
            state = this.stateManager.getState(widgetId) as DatasetLoadState;

            const sharedKey = this.findSharedKey(namedQueryId, isSubscribedToDashboardFilters);
            if (sharedKey) {
                const sharedState = this.stateManager.allStatesForKey(sharedKey)[0];
                sharedState.shareFetchSubscription(state);
            }

            if (!state.hasData()) {
                state.markAsLoading();
            }
        }

        // this is only for compare mode
        if (state && this.stashedWidgetData.has(widgetId)) {
            state.setWidgetData(this.stashedWidgetData.get(widgetId), undefined);
            this.stashedWidgetData.delete(widgetId);
        }

        return state;
    }

    private emitExistingData(state: DatasetLoadState): void {
        if (state.hasError()) {
            this.sendGenericError(state.widgetId, state.getError());
        }

        setTimeout(() => state.emitData(), 100);
    }

    private sendGenericError(widgetId: number, time: string): void {
        this.managerService.sendMessageToExistingWidget(
            widgetId,
            {
                action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                exception: {
                    message: TREBEK_QUERY_ERROR_MESSAGE,
                    time,
                },
            });
    }

    private refreshDatasetForState(state: DatasetLoadState, markDatasetAsLoading: boolean): void {
        if (markDatasetAsLoading) {
            state.markAsLoading();
        }

        const key = state.key;
        this.fetchData(
            key.namedQueryId,
            key.sourceType === 'widget' ? this.widgetQueryParams : this.dashboardPreferences,
            this.dashboardQueryParams,
            this.widgetQueryParams,
            this.determineActiveCompareMode(key.sourceType, key.dashboardId),
            !!(this.widgetQueryParams?.isComparing ?? this.dashboardQueryParams?.isComparing),
            key,
            state.clientCode,
            this.isMultiClient);
    }

    private determineActiveCompareMode(sourceType: 'dashboard' | 'widget', dashboardId: string | number | undefined): CompareMode | undefined {
        const widgetComparing = this.widgetQueryParams?.comparing;
        const dashboardComparing = this.dashboardQueryParams?.comparing;
        return sourceType === 'widget' ?
            widgetComparing :
            dashboardId === undefined ? widgetComparing : dashboardComparing;
    }

    private onWidgetDataSourceChanged(datasource: Datasource, currentWidgetInCompareMode: number | undefined): void {
        if (!datasource.lastChangedDataset) {
            return;
        }

        const dataSourceForLastKey = datasource.datasources.find((dw) => {
            return datasetFetchKeysMatch(dw.uniqueKey, datasource.lastChangedDataset);
        });
        if (!dataSourceForLastKey) {
            return;
        }

        this.emitUpdatedDataset(datasource.lastChangedDataset, dataSourceForLastKey, currentWidgetInCompareMode);
    }

    private emitUpdatedDataset(
        key: DatasetFetchKey,
        dataSourceForLastKey: DataWrapper,
        currentWidgetInCompareMode: number | undefined,
    ): void {
        this.stateManager.allStatesForKey(key).forEach((state) => {
            if (state.widgetId === currentWidgetInCompareMode) {
                this.compareModeService.updateCompareDataByWidgetId(state.widgetId, dataSourceForLastKey.compareData);
            }

            state.emitUpdatedData(
                dataSourceForLastKey?.data,
                dataSourceForLastKey?.compareMode,
                this.compareModeService.compareDataMap.get(state.widgetId));
        });
    }
}

function onlySpecialCaseParams(queryParam: FilterQueryParam | undefined): SpecialCaseQueryParams {
    return {
        acknowledged: queryParam?.acknowledged,
        includeManuallyReleased: queryParam?.includeManuallyReleased,
    };
}
