import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DashboardNote, SaveDashboardNote } from '../../models/dashboard-note';

@Component({
    selector: 'app-dashboard-bar',
    templateUrl: 'dashboard-bar.component.html',
    styleUrls: ['dashboard-bar.component.scss'],
})
export class DashboardBarComponent {
    @Input() dashboardNote: DashboardNote | undefined;
    @Input() dashboardId: string | undefined;
    @Input() inPresentationMode = false;
    @Input() isGlobal = false;
    @Input() isGlobalEditPartial = false;

    @Output() deleteDashboardNote: EventEmitter<void> = new EventEmitter();
    @Output() saveDashboardNote: EventEmitter<SaveDashboardNote> = new EventEmitter();

    protected listIconHighlight = false;
    protected plusIconHighlight = false;
    protected isDashboardNotesModalOpen = false;
    protected isConfigureDashboardNotesModalOpen = false;

    protected openDashboardNotesModal(): void {
        this.isDashboardNotesModalOpen = true;
    }

    protected closeDashboardNotesModal(): void {
        this.isDashboardNotesModalOpen = false;
    }

    protected openConfigureDashboardNotesModal(): void {
        this.isConfigureDashboardNotesModalOpen = true;
    }

    protected closeConfigureDashboardNotesModal(): void {
        this.isConfigureDashboardNotesModalOpen = false;
    }

    protected onDeleteDashboardNote(): void {
        this.deleteDashboardNote.emit();
    }

    protected onSaveDashboardNote(saveDashboardNote: SaveDashboardNote): void {
        this.saveDashboardNote.emit(saveDashboardNote);
    }
}
