import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsageTrackingToken } from '@ddv/models';
import { Observable, throwError } from 'rxjs';

import { CommunicationService } from './communication.service.interface';
import { ErrorResponseDetail } from './model/error-response-detail';
import { ResponseInterceptor } from './model/response-interceptor.interface';

interface RequestOptions {
    headers?: HttpHeaders;
    withCredentials?: boolean;
}

interface JSONBody extends RequestOptions {
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

interface StringBody extends RequestOptions {
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
}

@Injectable()
export class HttpCommunicationService implements CommunicationService {
    constructor(private readonly http: HttpClient) {}

    fireService<T>(url: string, queryParams?: Record<string, unknown>, usageTracker?: UsageTrackingToken): Observable<T> {
        const options: JSONBody = { responseType: 'json' };
        return this.http.get<T>(this.prepareURLForGET(url, queryParams), this.initializeOptions(options, usageTracker));
    }

    fireServiceWithBody<T>(url: string, method: string, body: unknown, usageTracker?: UsageTrackingToken): Observable<T> {
        const options: JSONBody = { responseType: 'json' };
        this.initializeOptions(options, usageTracker);

        switch (method) {
            case 'GET':
                return throwError(() => 'GET requests cannot have bodies');
            case 'POST':
                return this.http.post<T>(url, body, options);
            case 'PUT':
                return this.http.put<T>(url, body, options);
            case 'PATCH':
                return this.http.patch<T>(url, body, options);
            case 'DELETE':
                if (body != null) {
                    return throwError(() => 'DELETE requests cannot contain a body');
                }
                return this.http.delete<T>(url, options);
            default:
                return throwError(() => `Unmapped http method: ${method}`);
        }
    }

    fetchFullResponseFromService<T>(
        url: string,
        method: string,
        body: unknown,
        usageTracker?: UsageTrackingToken,
    ): Observable<HttpResponse<T>> {
        const options: JSONBody = { responseType: 'json' };
        this.initializeOptions(options, usageTracker);

        switch (method) {
            case 'GET':
                return throwError(() => 'GET requests cannot have bodies');
            case 'POST':
                return this.http.post<T>(url, body, { ...options, observe: 'response' });
            case 'PUT':
                return this.http.put<T>(url, body, { ...options, observe: 'response' });
            case 'DELETE':
                if (body != null) {
                    return throwError(() => 'DELETE requests cannot contain a body');
                }
                return this.http.delete<T>(url, { ...options, observe: 'response' });
            default:
                return throwError(() => `Unmapped http method: ${method}`);
        }
    }

    fetchTextFromService(url: string, method: string, body: unknown, usageTracker?: UsageTrackingToken): Observable<string> {
        const options: StringBody = { responseType: 'text' };
        this.initializeOptions(options, usageTracker);

        switch (method) {
            case 'GET':
                return throwError(() => 'GET requests cannot have bodies');
            case 'POST':
                return this.http.post(url, body, options);
            case 'PUT':
                return this.http.put(url, body, options);
            default:
                return throwError(() => `Unmapped http method: ${method}`);
        }
    }

    registerInterceptors(_: ResponseInterceptor): void {}

    private initializeOptions(options: RequestOptions, usageTracker?: UsageTrackingToken): RequestOptions {
        const requestId = Math.random().toString(16).substring(2, 10);

        if (usageTracker) {
            usageTracker.idForRequest(requestId);
        }

        options.headers = new HttpHeaders()
            .set('x-hedgeserv-request-id', requestId)
            .set('x-hedgeserv-include-tracing', 'true')
            .set('x-hedgeserv-initiator', 'ddv');

        options.withCredentials = true;
        return options;
    }

    private prepareURLForGET(url: string, requestParams?: Record<string, unknown>): string {
        if (!requestParams) {
            return url;
        }

        const queryParam: string = this.prepareQueryParameter(requestParams);
        if (url.indexOf('?') !== -1) {
            return `${url}&${queryParam}`;
        } else {
            return `${url}?${queryParam}`;
        }
    }

    private prepareQueryParameter(requestParams?: Record<string, unknown>): string {
        if (!requestParams) {
            return '';
        }

        let queryString = '';
        for (const prop in requestParams) {
            if (prop) {
                queryString += `${prop}=${requestParams[prop]}&`;
            }
        }
        return queryString.substring(0, queryString.lastIndexOf('&'));
    }

    getErrorResponseDetail(error: HttpErrorResponse): ErrorResponseDetail {
        return new ErrorResponseDetail(error.status, error.statusText, error.url, error.error);
    }
}
