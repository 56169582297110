import { UserDefinedField, WidgetData } from '@ddv/models';

export class ConversableType {
    asciiOnly: boolean;
    maxCommentLength: number;
    id: string;
    type: string;
    displayName: string;
    fields: string[];
    createdBy: string;
    created: Date;
    updated: Date | null;
    displayFields: string[];
    userDefinedFields: UserDefinedField[];

    constructor(init?: Partial<ConversableType>) {
        this.asciiOnly = !!init?.asciiOnly;
        this.maxCommentLength = init?.maxCommentLength ?? 0;
        this.id = init?.id ?? '';
        this.type = init?.type ?? '';
        this.displayName = init?.displayName ?? '';
        this.fields = init?.fields ?? [];
        this.createdBy = init?.createdBy ?? '';
        this.created = init?.created ? new Date(init.created) : new Date();
        this.updated = init?.updated ? new Date(init.updated) : null;
        this.displayFields = init?.displayFields ?? [];
        this.userDefinedFields = init?.userDefinedFields ?? [];
    }

    liftValuesForKeyFields(row: WidgetData): string[] {
        return this.fields.map((field) => row[field] as string);
    }

    getUserDefinedFields(): UserDefinedField[] {
        if (this.userDefinedFields?.length) {
            return this.userDefinedFields.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        }

        return [];
    }
}
