import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TextValuePair, DashboardFilter } from '@ddv/models';

import { DashboardFiltersService } from '../../services/dashboard-filters.service';

@Component({
    selector: 'app-filterable-attribute',
    templateUrl: 'filterable-attribute.component.html',
    styleUrls: ['filterable-attribute.component.scss'],
})
export class FilterableAttributeComponent implements OnInit {
    searchAttributeFilter = '';
    @Input() selectedDashboardFilter: DashboardFilter | undefined;
    @Output() selectAttribute = new EventEmitter<DashboardFilter>();

    protected metadataList: TextValuePair[] = [];

    constructor(public dashboardFiltersService: DashboardFiltersService) {}

    ngOnInit(): void {
        this.selectedDashboardFilter = this.selectedDashboardFilter ?? { values: [] };
        this.metadataList = this.dashboardFiltersService.getMetadataList();
    }

    onSelectAttribute(attribute: TextValuePair): void {
        if (!this.selectedDashboardFilter) {
            return;
        }

        this.selectedDashboardFilter.name = attribute.value;
        this.selectedDashboardFilter.displayName = attribute.text;
        this.selectedDashboardFilter.values = [];
        this.dashboardFiltersService.updateValueOptions(attribute.value);
        this.selectAttribute.emit(this.selectedDashboardFilter);
    }
}
