import {
    CellRendererSelectorResult,
    ColDef,
    HeaderValueGetterParams,
    IRowNode,
    PostSortRowsParams,
    ValueGetterParams,
} from '@ag-grid-community/core';
import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import {
    CrosstalkCommentCounterComponent,
    CrosstalkCustomCheckboxComponent,
    CrosstalkCustomHeaderCheckboxComponent,
    CrosstalkModalService,
    CrosstalkService,
} from '@ddv/crosstalk';
import {
    DataGridComponent,
    CustomColDef,
    GridConfiguration,
    DataGridOptions,
    CustomCellRendererParams,
    HeaderCellRendererComponent,
} from '@ddv/data-grid';
import { UserEntitlementService } from '@ddv/entitlements';
import { ErrorLoggerService } from '@ddv/error-handling';
import { FormatterService } from '@ddv/formatters';
import { ApiServices, ApiExecutorService } from '@ddv/http';
import { ManagerService } from '@ddv/layout';
import {
    crosstalkCheckboxFieldId,
    CrosstalkFields,
    DdvDate,
    ConfigItem,
    GRID_ICONS,
    TFL_DETAILS_AUTO_GROUP_COLUMN_ID,
    TRANSACTION_TYPE_FIELD,
    VisualizationConfigs,
    getCurrentVisualizationId,
    trebekRealTimeCommentFields,
    TrebekConversationFields,
    UserDefinedField,
    isTradeFileDetails,
    isTFLIncompleteFiles,
    DatasetDefinitionDetails,
    QueryTypeName,
} from '@ddv/models';
import { FuzzyDatesServiceV2 } from '@ddv/reference-data';
import { FuzzyDate } from '@hs/ui-core-date';
import { Observable } from 'rxjs';

import { SelectedWidgetRelayService } from '../../base/selected-widget-relay.service';
import { GroupTooltipComponent } from '../components/group-tooltip/group-tooltip.component';
import { ColumnRankingService } from './column-ranking.service';
import { ColumnTransformService } from './column-transform.service';
import { GridConfigService, isManageWidgetMode } from './grid-config.service';
import { GridUriBuilderService } from './grid-uri-builder.service';
import { UserGridColumnOverridesService } from './user-grid-column-overrides.service';

const COMMENT_COUNTER_HEADER = '<span class="hs-icon-comment"></span>';
const ALLOWED_QUERY_TYPES = [QueryTypeName.NET_SETTLEMENT_DETAILS, QueryTypeName.SETTLEMENT_BREAKS, QueryTypeName.TRADE_FILE_DETAILS];

@Injectable()
export class AdvancedGridConfigService extends GridConfigService {
    private isUserInternal = false;
    private fuzzyDates: FuzzyDate[] = [];
    private userDefinedFields: Map<string, UserDefinedField[]> = new Map();
    private readonly datePipe: DatePipe;
    private readonly checkboxColumnDefinition: ColDef = {
        colId: crosstalkCheckboxFieldId,
        field: crosstalkCheckboxFieldId,
        headerComponent: CrosstalkCustomHeaderCheckboxComponent,
        cellRenderer: CrosstalkCustomCheckboxComponent,
        headerName: '',
        pinned: 'left',
        headerCheckboxSelectionFilteredOnly: true,
        resizable: false,
        width: 34,
        minWidth: 34,
        maxWidth: 34,
        pivot: false,
        enablePivot: false,
        hide: false,
        lockVisible: true,
        suppressColumnsToolPanel: true,
        filter: false,
    };

    constructor(
        formatter: FormatterService,
        errorLoggerService: ErrorLoggerService,
        columnRankingService: ColumnRankingService,
        columnTransformService: ColumnTransformService,
        manager: ManagerService,
        crosstalkModalService: CrosstalkModalService,
        private readonly entitlementsService: UserEntitlementService,
        currentStateService: CurrentStateService,
        @Inject(ApiServices.trebek) private readonly trebekApiService: ApiExecutorService,
        private readonly fuzzyDatesService: FuzzyDatesServiceV2,
        private readonly crosstalkService: CrosstalkService,
        userGridColumnOverridesService: UserGridColumnOverridesService,
        selectedWidgetRelayService: SelectedWidgetRelayService,
        gridBuilderService: GridUriBuilderService,
    ) {
        super(formatter,
            errorLoggerService,
            columnRankingService,
            columnTransformService,
            manager,
            crosstalkModalService,
            currentStateService,
            userGridColumnOverridesService,
            selectedWidgetRelayService,
            gridBuilderService);

        this.visualizationType = 'ADVANCED_GRID';

        this.datePipe = new DatePipe('en-US');

        // This is bad and can definitely lead to a race condition
        this.entitlementsService.userData$.subscribe((data) => this.isUserInternal = data.internal);

        this.fuzzyDatesService.getFuzzyDatesCC().subscribe((fuzzyDates) => this.fuzzyDates = fuzzyDates);

        this.crosstalkService.userDefinedFields.subscribe((udfs) => this.userDefinedFields = udfs);

        this.userGridColumnOverridesService.setCheckboxColumnDefinition(this.checkboxColumnDefinition);
    }

    override getGridOptions(vizConfigs: VisualizationConfigs, widgetId: number): DataGridOptions {
        const gridOptions = super.getGridOptions(vizConfigs);
        gridOptions.icons = {
            ...gridOptions.icons,
            menu: `<i class="${GRID_ICONS.menu} ag-icon-menu hamburger-menu"/>`,
            columns: `<i class="${GRID_ICONS.columns} ag-icon-columns"/>`,
            columnGroupOpened: '<span class="icon-arrow-left"></span>',
            columnGroupClosed: '<span class="icon-arrow-right"></span>',
        };
        gridOptions.groupUseEntireRow = false;
        gridOptions.suppressAggFuncInHeader = false;
        gridOptions.suppressDragLeaveHidesColumns = true;
        gridOptions.suppressRowGroupHidesColumns = true;
        gridOptions.suppressGroupRowsSticky = true;
        gridOptions.groupDisplayType = 'singleColumn';
        gridOptions.rowGroupPanelShow = 'always';
        gridOptions.rowGroupPanelSuppressSort = true;
        gridOptions.pivotMode = vizConfigs.isPivotMode;
        gridOptions.autoGroupColumnDef = {
            cellClass: vizConfigs.alternateRowShading ? '' : 'no-grid-alternateshade',
            valueGetter: (params: ValueGetterParams): string => this.getAutoGroupValue(params, vizConfigs),
            headerValueGetter: (params: HeaderValueGetterParams): string => {
                const groups = params.api.getRowGroupColumns();
                return groups.length === 1 ? groups[0].getColDef().headerName! : 'Group';
            },
            cellRendererParams: {
                suppressCount: !vizConfigs.showGrouperCount,
                innerRenderer: (params: CustomCellRendererParams): string => this.getAutoGroupInnerRendererValue(params),
            },
            suppressSpanHeaderHeight: true,
        };
        const conversableType = isManageWidgetMode(widgetId) ?
            this.selectedWidgetRelayService.getCurrentDatasetDefinition()?.normalizeConversableType() :
            // this is an instance of DatasetDefinitionDetails even when we use NamedQuery
            // so there is definitely something wrong that we need to address
            (this.getWidgetPreferences(widgetId)?.datasetDefinition as DatasetDefinitionDetails)?.normalizeConversableType();
        const queryTypeName = isManageWidgetMode(widgetId) ?
            this.selectedWidgetRelayService.getCurrentDatasetDefinition()?.normalizeQueryTypeName() :
            // this is an instance of DatasetDefinitionDetails even when we use NamedQuery
            // so there is definitely something wrong that we need to address
            (this.getWidgetPreferences(widgetId)?.datasetDefinition as DatasetDefinitionDetails)?.normalizeQueryTypeName();
        const queryTypeIsTFLDetails = isTradeFileDetails(queryTypeName);
        gridOptions.rowSelection = {
            ...gridOptions.rowSelection!,
            isRowSelectable: (node: IRowNode): boolean => !!node.group || Object.prototype.hasOwnProperty.call(node, 'data'),
            enableClickSelection: !(queryTypeIsTFLDetails || conversableType),
        };
        gridOptions.rowClass = vizConfigs.alternateRowShading ? 'grid-alternateshade' : '';
        if (queryTypeIsTFLDetails) {
            gridOptions.postSortRows = (params: PostSortRowsParams): void => this.sortRowsForTFLDetails(params.nodes, queryTypeName ?? '');
        }
        gridOptions.functionsReadOnly = queryTypeIsTFLDetails;
        return gridOptions;
    }

    override getGridConfiguration(
        vizConfigs: VisualizationConfigs,
        widgetId: number,
        datasetId: number | string,
        bulkCommentingOn = false,
        includeCheckboxColumn?: boolean,
    ): GridConfiguration {
        const gridConfiguration = super.getGridConfiguration(vizConfigs, widgetId, datasetId);
        gridConfiguration.isRowGroupEnabled = isRowGroupEnabled(vizConfigs.configs?.values ?? []);
        // be warned, if the parent widget is attached to a named-query, this will always be the dummy dataset definition
        // that we hardcoded into the DB to be used to keep SQL happy and not the named query
        const currentDatasetDefinition = this.getCurrentDatasetDefinition(widgetId);

        const conversableType = currentDatasetDefinition?.normalizeConversableType();
        const queryTypeName = currentDatasetDefinition?.normalizeQueryTypeName();

        gridConfiguration.columnDefinitions.forEach((colDef: ColDef | CustomColDef) => {
            if (colDef.field && trebekRealTimeCommentFields.includes(colDef.field)) {
                this.setCommentFieldColDef(colDef, bulkCommentingOn);
            } else if (trebekRealTimeCommentFields.some((x) => `${x}Author` === colDef.field)) {
                colDef.cellRenderer = 'authorCellRendererComponent';
            } else if (trebekRealTimeCommentFields.some((x) => `${x}Created` === colDef.field)) {
                colDef.cellRenderer = 'datetimeCellRendererComponent';
            } else if (colDef.field === CrosstalkFields.Attachments) {
                setAttachmentsFieldColDef(colDef, conversableType);
            } else if (colDef.field === CrosstalkFields.CommentCounter) {
                setCommentCounterFieldColDef(colDef);
            } else if (this.isUserDefinedFieldColumn(colDef?.field)) {
                this.setUserDefinedFieldColDef(colDef, conversableType);
            } else if (isTFLDetailsAutoGroupColumn(queryTypeName, colDef.field)) {
                colDef.rowGroup = true;
                colDef.rowGroupIndex = 0;
            }
        });

        const commentEditModeOn = gridConfiguration.columnDefinitions.some((colDef: ColDef | CustomColDef) => (
            trebekRealTimeCommentFields.includes(colDef.field!) ||
            this.isUserDefinedFieldColumn(colDef?.field) ||
            colDef.field === CrosstalkFields.Attachments
        ));

        if (commentEditModeOn) {
            this.setCrosstalkCheckboxColumnPosition(gridConfiguration, widgetId);
        }

        if (
            includeCheckboxColumn ||
            isTFLIncompleteFiles(queryTypeName) ||
            (ALLOWED_QUERY_TYPES.includes(queryTypeName as QueryTypeName) &&
            gridConfiguration.columnDefinitions.some((column) => this.isColumnEditable(column)))
        ) {
            this.customCheckboxColumnDefinition.cellRendererParams = { queryTypeName };
            gridConfiguration.columnDefinitions = [
                this.customCheckboxColumnDefinition,
                ...gridConfiguration.columnDefinitions,
            ];
        }

        return gridConfiguration;
    }

    override getRankedColumnIds(columnConfigs: ConfigItem[]): string[] {
        return this.columnRankingService.getRankedColumnIds(columnConfigs);
    }

    override getExportFilteredData(
        dataGridComponent: DataGridComponent | undefined,
        preferences: VisualizationConfigs | undefined,
        isCSV = false,
        onlySelectedRows = false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): any[] {
        const exportFilteredData = super.getExportFilteredData(dataGridComponent, preferences, isCSV, onlySelectedRows);
        for (const row of exportFilteredData) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete row[crosstalkCheckboxFieldId];
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete row[COMMENT_COUNTER_HEADER];
        }
        return exportFilteredData;
    }

    override getExportFilteredDataGroupers(dataGridComponent: DataGridComponent): string[] {
        return super.getExportFilteredDataGroupers(dataGridComponent);
    }

    override getFilteredExportVisibleColumns(dataGridComponent: DataGridComponent): string[] {
        return super.getFilteredExportVisibleColumns(dataGridComponent);
    }

    // this has absolutely no business being here:
    //   it has nothing to do with configuring anything
    //   it has nothing to do with a grid
    handleAction(
        clientCode: string,
        url: string,
        method: string,
        payload: { id: number }[] | { fileIds: unknown[] },
    ): Observable<{ success: boolean }> {
        return this.trebekApiService.invokeServiceWithBody(clientCode, url, method, payload);
    }

    // this has absolutely no business being here:
    //   it has nothing to do with configuring anything
    //   it has nothing to do with a grid
    uploadFile(clientCode: string, url: string, method: string, payload: FormData): Observable<{ success: boolean }> {
        return this.trebekApiService.invokeServiceWithBody(clientCode, url, method, payload);
    }

    private getAutoGroupValue(params: ValueGetterParams, vizConfigs: VisualizationConfigs): string {
        const groups = params.api.getRowGroupColumns();

        if (!groups.length) {
            return '';
        }

        const groupColumnId = groups[groups.length - 1].getColDef().field;

        if (this.isCrosstalkCommentTimeColumn(groupColumnId)) {
            return vizConfigs.repeatGroupData ? this.getCrosstalkCommentTimeFormattedValue(params.data[groupColumnId ?? ''], '') : '';
        }

        return (params.data && vizConfigs.repeatGroupData) ? params.data[groupColumnId ?? ''] : '';
    }

    private getAutoGroupInnerRendererValue(params: CustomCellRendererParams): string {
        if (this.isCrosstalkCommentTimeColumn(params.node?.field)) {
            return this.getCrosstalkCommentTimeFormattedValue(params.value, 'Blanks');
        }

        if (this.isCrosstalkColumn(params.node?.field)) {
            return params.value || 'Blanks';
        }

        return typeof params.value !== 'boolean' && !params.value && params.valueFormatted === '' ? 'Blanks' : params.value;
    }

    private getCrosstalkCommentTimeFormattedValue(value: string, emptyStringValue: 'Blanks' | ''): string {
        return value ? (this.datePipe.transform(value, 'MM/dd/yyyy hh:mm:ss a') ?? '') : emptyStringValue;
    }

    private isColumnEditable(column: CustomColDef): boolean {
        return !(this.isCrosstalkCommentColumn(column.field)) &&
            column.field !== CrosstalkFields.Attachments &&
            !column.isUserDefinedField &&
            !!column.editable;
    }

    private isCrosstalkColumn(field?: string | null): boolean {
        return this.isCrosstalkCommentColumn(field) ||
            this.isCrosstalkCommentAuthorColumn(field) ||
            this.isCrosstalkCommentTimeColumn(field);
    }

    private isCrosstalkCommentColumn(field?: string | null): boolean {
        return field === TrebekConversationFields.ClientComment || field === TrebekConversationFields.HSComment;
    }

    private isCrosstalkCommentAuthorColumn(field?: string | null): boolean {
        return field === `${TrebekConversationFields.ClientComment}Author` || field === `${TrebekConversationFields.HSComment}Author`;
    }

    private isCrosstalkCommentTimeColumn(field?: string | null): boolean {
        return field === `${TrebekConversationFields.ClientComment}Created` || field === `${TrebekConversationFields.HSComment}Created`;
    }

    private setCommentFieldColDef(colDef: CustomColDef, bulkCommentingOn: boolean): void {
        colDef.cellRenderer = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        colDef.cellRendererSelector = (params): any => (
            isCrosstalkRow(params.node) ? { component: 'commentCellRendererComponent', params } : null
        );

        const userCanEditCell = (this.isUserInternal && colDef.field !== TrebekConversationFields.ClientComment) ||
            (!this.isUserInternal && colDef.field === TrebekConversationFields.ClientComment);

        if (userCanEditCell) {
            colDef.editable = bulkCommentingOn ?
                ({ node }): boolean => isCrosstalkSelectedRow(node) :
                ({ node }): boolean => isCrosstalkRow(node);

            colDef.cellEditor = 'commentCellEditorComponent';
            colDef.cellClassRules = {
                selected: ({ node }): boolean => isCrosstalkSelectedRow(node),
                'inline-background': ({ node }): boolean => isCrosstalkRow(node),
            };
        }
    }

    private setUserDefinedFieldColDef(colDef: CustomColDef, conversableType?: string): void {
        const type = colDef.userDefinedFieldType;

        const cellRendererParams = { userDefinedFieldType: type } as CustomCellRendererParams;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let comparator: ((valueA: any, valueB: any, nodeA: IRowNode, nodeB: IRowNode, isInverted: boolean) => number) | undefined;
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        const cellHasNoValue = (data: UserDefinedField | null | undefined): boolean => (data == null || data.value == null);
        const canEditUdf = !!colDef.editable;

        colDef.isUserDefinedField = true;
        colDef.cellRenderer = null;
        colDef.cellRendererSelector = (params): CellRendererSelectorResult | undefined => isCrosstalkRow(params.node) ?
            {
                component: colDef.field?.endsWith('_commentTS') ?
                    'datetimeCellRendererComponent' :
                    'userDefinedFieldCellRendererComponent',
                params,
            } :
            undefined;

        if (type === 'date') {
            cellRendererParams.fuzzyDates = this.fuzzyDates;
            cellRendererParams.canEditUdf = canEditUdf;
            comparator = (a, b): number => {
                const valueA = cellHasNoValue(a) ? 0 : DdvDate.fromDashFormat(a.value).toMilliseconds();
                const valueB = cellHasNoValue(b) ? 0 : DdvDate.fromDashFormat(b.value).toMilliseconds();
                return valueA - valueB;
            };
        } else if (type === 'choice') {
            cellRendererParams.choices = this.userDefinedFields.get(conversableType ?? '')?.find((udf) => `udf_${udf.name}` === colDef.field)?.choices ?? [];
            cellRendererParams.canEditUdf = canEditUdf;
        }

        if (type === 'date' || type === 'choice') {
            colDef.editable = false;
            colDef.cellClass = 'user-defined-field-cell';
        }

        if (type === 'string' || type === 'decimal') {
            if (canEditUdf) {
                colDef.cellEditor = 'userDefinedFieldCellEditorComponent';
                colDef.editable = (params): boolean => !params.node.isRowPinned() &&
                    !(type === 'decimal' && (params.api.isPivotMode() || !!params.node.allChildrenCount));
            }
        }

        if (type === 'string' || type === 'choice') {
            comparator = (a, b): number => {
                const valueA: string = cellHasNoValue(a) ? '' : a.value;
                const valueB: string = cellHasNoValue(b) ? '' : b.value;
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
            };
        }

        if (type === 'decimal') {
            comparator = (a, b): number => {
                const valueA: number = cellHasNoValue(a) ? 0 : a.value;
                const valueB: number = cellHasNoValue(b) ? 0 : b.value;
                return valueA - valueB;
            };

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            colDef.valueGetter = (params): any => {
                if (params.api && (params.api.isPivotMode() || params.api.getRowGroupColumns().length)) {
                    if (!params.column.getAggFunc()) {
                        return undefined;
                    }

                    const value = Number(params.data?.[params.colDef.field ?? '']?.value);
                    return isNaN(value) ? undefined : value;
                }

                return params.data?.[params.colDef.field ?? ''];
            };
        }

        if (type === 'boolean') {
            comparator = (a: UserDefinedField | null | undefined, b: UserDefinedField | null | undefined): number => {
                const valueA: boolean = cellHasNoValue(a) ? false : (a!.value as boolean);
                const valueB: boolean = cellHasNoValue(b) ? false : (b!.value as boolean);
                return Number(valueA) - Number(valueB);
            };
            cellRendererParams.canEditUdf = canEditUdf;
        }

        colDef.cellRendererParams = cellRendererParams;
        colDef.comparator = comparator;
    }

    private setCrosstalkCheckboxColumnPosition(gridConfiguration: GridConfiguration, widgetId: number): void {
        const columnDefinitions = gridConfiguration.columnDefinitions;
        const checkboxColumnIndex = columnDefinitions.findIndex((col: ColDef) => col.colId === crosstalkCheckboxFieldId);
        if (checkboxColumnIndex > -1) {
            const visualizationId = getCurrentVisualizationId(this.getWidgetPreferences(widgetId)) ?? 0;
            this.userGridColumnOverridesService.setCheckboxColumnPosition(
                widgetId,
                visualizationId,
                columnDefinitions,
                'ColDef',
                this.isInViewMode,
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const checkboxColumn: any | undefined = columnDefinitions.find((c: ColDef) => c.colId === crosstalkCheckboxFieldId);
            this.checkboxColumnDefinition.pinned = checkboxColumn?.pinned;
            gridConfiguration.columnDefinitions.splice(checkboxColumnIndex, 1, this.checkboxColumnDefinition);
        } else {
            this.checkboxColumnDefinition.pinned = 'left';
            gridConfiguration.columnDefinitions = columnDefinitions;
            if (!gridConfiguration.gridOptions.pivotMode) {
                gridConfiguration.columnDefinitions.unshift(this.checkboxColumnDefinition);
            }
        }
    }

    // yes, I know we are modifying nodes in here
    // but it looks like this is the way for now
    // https://ag-grid.com/angular-data-grid/row-sorting/#post-sort
    private sortRowsForTFLDetails(nodes: IRowNode[], queryTypeName: string): void {
        if (nodes.every((node) => this.rowHasGroupId(node) && isTFLDetailsAutoGroupColumn(queryTypeName, node.parent?.field))) {
            const blockIndex = nodes.findIndex((node) => node.data[TRANSACTION_TYPE_FIELD]);
            if (blockIndex !== -1) {
                const block = nodes.splice(blockIndex, 1)[0];
                nodes.unshift(block);
            }
        }
    }

    private rowHasGroupId(node: IRowNode): boolean {
        return !!node.data?.[TFL_DETAILS_AUTO_GROUP_COLUMN_ID];
    }
}

export function isTFLDetailsAutoGroupColumn(queryTypeName?: string, field?: string | null): boolean {
    return isTradeFileDetails(queryTypeName) && field === TFL_DETAILS_AUTO_GROUP_COLUMN_ID;
}

function setAttachmentsFieldColDef(colDef: ColDef, conversableType?: string): void {
    colDef.headerName = '';
    colDef.enableRowGroup = false;
    colDef.cellRenderer = null;
    colDef.sortable = false;
    colDef.suppressColumnsToolPanel = true;
    colDef.minWidth = 60;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    colDef.cellRendererSelector = (params): any => (
        isCrosstalkRow(params.node) ? { component: 'attachmentsCellRendererComponent', params } : null
    );
    colDef.cellRendererParams = { conversableType };
}

function setCommentCounterFieldColDef(colDef: ColDef): void {
    colDef.headerComponent = HeaderCellRendererComponent;
    colDef.cellRenderer = CrosstalkCommentCounterComponent;
    colDef.headerName = COMMENT_COUNTER_HEADER;
    colDef.enableRowGroup = false;
    colDef.resizable = true;
    colDef.minWidth = 60;
    colDef.width = 60;
    colDef.pivot = false;
    colDef.enablePivot = false;
    colDef.suppressColumnsToolPanel = true;
    colDef.filter = 'agNumberColumnFilter';
    colDef.filterValueGetter = (params: ValueGetterParams): number => params.data?.[CrosstalkFields.CommentCounter];
    colDef.comparator = (a: number, b: number): number => a - b;
    colDef.valueGetter = (params): string | ((params: ValueGetterParams) => number) =>
        params.data?.[CrosstalkFields.CommentCounter];
    colDef.tooltipComponent = GroupTooltipComponent;
}

function isCrosstalkRow(node: IRowNode): boolean {
    return !node.isRowPinned() && !node.group;
}

function isCrosstalkSelectedRow(node: IRowNode): boolean {
    return !!(node.isSelected() && isCrosstalkRow(node));
}

function isRowGroupEnabled(columnConfigs: ConfigItem[]): boolean {
    return columnConfigs.some((columnConfig) => columnConfig.canPivotOn);
}
