import { Injectable } from '@angular/core';
import { DatasetFetchKey, datasetFetchKeysMatch } from '@ddv/models';

import { DatasetLoadState } from './dataset-load-state';

@Injectable()
export class DatasetStateManagementService {
    private readonly stateByWidgetId: Map<number, DatasetLoadState> = new Map();

    getState(widgetIdOrKey: number | DatasetFetchKey): DatasetLoadState | undefined {
        if (typeof widgetIdOrKey === 'number') {
            return this.stateByWidgetId.get(widgetIdOrKey);
        }

        return [...this.stateByWidgetId.entries()].find(([, s]) => s.keysMatch(widgetIdOrKey))?.[1];
    }

    createState(widgetId: number, clientCode: string, key: DatasetFetchKey): DatasetLoadState {
        const state = new DatasetLoadState(clientCode, widgetId, key);
        this.stateByWidgetId.set(widgetId, state);
        return state;
    }

    removeState(widgetId: number): void {
        this.stateByWidgetId.delete(widgetId);
    }

    // this was only needed in one place in the DSM and that place APPEARS to be dead code.  but leaving for now
    // allStates(): DatasetLoadState[] {
    //     return [...this.stateByWidgetId.values()];
    // }

    allStatesForDashboards(): DatasetLoadState[] {
        return [...this.stateByWidgetId.values()].filter((s) => s.key.sourceType === 'dashboard');
    }

    allStatesForKey(key: DatasetFetchKey): DatasetLoadState[] {
        return [...this.stateByWidgetId.values()].filter((s) => datasetFetchKeysMatch(s.key, key));
    }

    allSharedStatesForNamedQuery(namedQueryId: string | number): DatasetLoadState[] {
        return [...this.stateByWidgetId.values()].filter((s) => s.key.sourceType === 'dashboard' && s.key.namedQueryId === namedQueryId);
    }
}
