@if (currentDashboard && !isLastDashboardClosed) {
    <div class="dashboard-name">
        <h1 [title]="currentDashboard.getExtraParameters().name" [class.viewmode]="inPresentationMode">
            {{currentDashboard.getExtraParameters().name}}
        </h1>
        <cc-icon
            [color]="inPresentationMode ? '' : 'var(--icon-hover)'"
            [hidden]="hideEditIcon()"
            [icon]="'edit-pencil'"
            [size]="'small'"
            [routerLink]="editDashboardPath"
            title="Edit Workspace"
        >
        </cc-icon>

        @if (!inPresentationMode) {
            <div class="btn-group">
                <button
                    (click)="saveClick.emit()"
                    [disabled]="!dirtyFlagService.isDirty || currentDashboard.getExtraParameters().privilege === 'VIEW'"
                    [class.saved]=""
                    class="ghost-btn hs-db-header-save"
                >
                    Save
                </button>

                <button
                    class="ghost-btn hs-db-header-cancel"
                    (click)="cancelEdit.emit()"
                >
                    Cancel
                </button>

                <button
                    class="ghost-btn hs-db-header-revert"
                    [disabled]="!dirtyFlagService.isDirty"
                    (click)="revertChanges.emit()"
                >
                    Revert
                </button>
            </div>
        }

        @if (views) {
            <div class="view-group">
                {{views.name}}

                @for(view of views.dashboards; track $index) {
                    <span
                        class="view-circle"
                        [title] = "view.name"
                        [ngClass]="{'selected': currentDashboard.id === view.id?.toString()}"
                        (click)="switchDashboard(view.id?.toString())">
                    </span>
                }
            </div>
        }
    </div>
}
