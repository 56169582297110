import { CompareMode, DatasetFetchKey, datasetFetchKeysMatch, WidgetData } from '@ddv/models';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

import { IDataLoad } from '../models/data-load';
import { PublicApiResponseRow } from '../models/trebek';

export class DatasetLoadState {
    private readonly _publicSubject: Subject<IDataLoad> = new ReplaySubject(1);
    private _fetchedData: WidgetData[] | undefined;
    private _error: string | undefined;
    private _loadTime: string | undefined;
    private _isLoading = false;
    private _fetchDatasetSubscription: Subscription | undefined;
    private _fetchDatasetCanceled = false;
    private _widgetData: PublicApiResponseRow[] | undefined;
    private _widgetCompareData: PublicApiResponseRow[] | undefined;

    constructor(
        public readonly clientCode: string,
        public readonly widgetId: number,
        public readonly key: DatasetFetchKey,
    ) {}

    keysMatch(other: DatasetLoadState | DatasetFetchKey): boolean {
        const key = other instanceof DatasetLoadState ? other.key : other;
        return datasetFetchKeysMatch(this.key, key);
    }

    hasData(): boolean {
        return !!this._fetchedData;
    }

    fetchedData(): WidgetData[] | undefined {
        return this._fetchedData;
    }

    fetchDataObservable(): Observable<IDataLoad> {
        return this._publicSubject.asObservable();
    }

    hasError(): boolean {
        return !!this._error;
    }

    getError(): string {
        return this._error ?? '';
    }

    // this is only used when a call to DatasetManagerService.getDataset
    // comes in for a subscribed widget that has already been loaded
    // that code path will only ever be hit AFTER emitUpdatedData has been called
    emitData(): void {
        this._publicSubject?.next({
            isLoaded: !this._isLoading,  // this can probably be hardcoded to false
            data: this._fetchedData,
            key: this.key,
            loadTime: this._loadTime,
        });
    }

    // this is only called when DatasetManagerService gets an update from the WidgetDatasourceService
    // that update is actually triggered by the DatasetManagerService AFTER calling setWidgetData
    emitUpdatedData(
        data: WidgetData[] | undefined,
        compareMode: string | undefined,
        compareData: WidgetData[] | undefined,
    ): void {
        this._fetchedData = data;

        this._publicSubject?.next({
            isLoaded: true,
            data: this._fetchedData,
            compareMode,
            compareData,
            key: this.key,
            loadTime: this._loadTime,
        });
    }

    markAsLoading(): void {
        this._isLoading = true;
    }

    isLoading(): boolean {
        return this._isLoading;
    }

    markAsFetching(subscription: Subscription): void {
        this._fetchDatasetSubscription = subscription;
    }

    clearFetchedData(): void {
        this._fetchedData = undefined;
        this._error = undefined;
        this._fetchDatasetCanceled = false;
    }

    shareFetchSubscription(other: DatasetLoadState): void {
        other._fetchDatasetSubscription = this._fetchDatasetSubscription;
        other._fetchedData = this._fetchedData;
        other._error = this._error;
    }

    isFetching(): boolean {
        return !!this._fetchDatasetSubscription;
    }

    markAsFetchingCanceled(): void {
        this._fetchDatasetCanceled = true;
    }

    cancelFetching(): void {
        this._fetchDatasetSubscription?.unsubscribe();
        this._fetchDatasetSubscription = undefined;
        this._isLoading = false;
    }

    isFetchingCanceled(): boolean {
        return this._fetchDatasetCanceled;
    }

    hasWidgetData(): boolean {
        return !!this._widgetData;
    }

    widgetData(): PublicApiResponseRow[] {
        return this._widgetData ?? [];
    }

    widgetCompareData(): PublicApiResponseRow[] {
        return this._widgetCompareData ?? [];
    }

    setWidgetData(data: PublicApiResponseRow[] | undefined, compareMode: CompareMode | undefined): void {
        if (compareMode === CompareMode.COMPARED) {
            this._widgetCompareData = data;
        } else {
            this._widgetData = data;
        }

        this._loadTime = new Date().toISOString();
        this._isLoading = false;
        this._fetchDatasetCanceled = false;
    }

    loadTime(): string | undefined {
        return this._loadTime;
    }

    clearLoadTime(): void {
        this._loadTime = undefined;
    }
}
