<div class="advanced-data-grid data-grid">
    <div
        class="grid-wrapper widget-flex"
        [ngClass]="{
            'tp-opened': isToolPanelShowing,
            'maximized': isWidgetMaximized && enableCompareMode,
            'ag-readonly': isWidgetReadOnly
        }"
    >
        <div class="search-wrap">
            <ddv-search-box
                [quickSearchPlaceholder]="quickSearchPlaceholder"
                [rowSearchText]="rowSearchText"
                (quickFilterChanged)="onQuickFilterChanged($event)"
            >
            </ddv-search-box>

            @if (isWidgetMaximized && actionIconsAllowed) {
                <ddv-action-icons
                    [params]="params"
                    [isTFLDetails]="isTFLDetails"
                    (handleAcknowledge)="handleAcknowledge()"
                    (handleResubmit)="handleResubmit()"
                >
                </ddv-action-icons>
            }

            @if (isWidgetMaximized && actions?.length && !actionIconsAllowed) {
                <ddv-actions-handler
                    [actions]="actions"
                    [filterName]="actionHandlerFilterParameter?.displayName"
                    (filterActiveChange)="onActionHandlerFilterActiveChange($event)"
                    (actionPressed)="handleActionPressed($event)"
                >
                </ddv-actions-handler>
            }

            @if (searchColumns.length > 0) {
                <app-grid-column-search
                    (columnVisibleChange)="showDataGridColumn($event)"
                    [gridColumns]="searchColumns"
                    [isReadOnly]="isWidgetReadOnly"
                >
                </app-grid-column-search>
            }

            @if (this.isWidgetMaximized || this.isToolPanelShowing) {
                <button class="empty-btn" (click)="toggleToolpanel()">
                    <i [ngClass]="{'icon-right-expand': !isToolPanelShowing, 'icon-left-expand': isToolPanelShowing}"></i>

                    <i class="icon-advancedgrid-list"></i>
                </button>
            }
        </div>

        <div class="widget-wrap" (mousedown)="isMousedownInside = true">
            @if (showGrid) {
                <app-data-grid
                    [gridConfiguration]="gridConfiguration"
                    [frameworkComponents]="allFrameworkComponents"
                    [suppressAggFuncInHeader]="!!preferences?.useCustomHeaderNames"
                    [isManagingWidget]="isManagingWidget"
                    [hideLoaderAfterFirstDataLoad]="hideLoaderAfterFirstDataLoad"
                    [isOnMasterWidget]="isMaster"
                    [isShowingTFLData]="isTFLDetails || isTFLIncompleteFiles"
                    [datasetIncludesCrosstalk]="isCrosstalkGrid"
                    [widgetId]="widgetId"
                    [visualizationId]="visualizationId"
                    (cellClickedEvent)="conditionallyFollowLink($event)"
                    (cellValueChanged)="onCellValueChanged($event)"
                    (columnEverythingChanged)="onColumnEverythingChanged($event)"
                    (columnMovedEvent)="onColumnMoved($event.event, $event.columns)"
                    (columnPivotModeChangedEvent)="onColumnPivotModeChanged($event)"
                    (columnVisibleEvent)="setColumnVisibility($event)"
                    (displayedColumnsChangedEvent)="onDisplayedColumnsChangedEvent()"
                    (gridFilterChangedEvent)="onGridFilterChanged()"
                    (gridReadyEvent)="onGridReady()"
                    (dragStoppedEvent)="onDragStopped($event.event, $event.columns)"
                    (pivotColumnChangedEvent)="onPivotColumnChanged()"
                    (rowGroupColumnChangedEvent)="onRowGroupColumnChanged($event)"
                    (rowGroupOpenedEvent)="onRowGroupOpened($event)"
                    (rowSelectionEvent)="onRowSelected($event)"
                    (rowUnselectedEvent)="onRowUnselected($event)"
                    (valueColumnChangedEvent)="onValueColumnChanged($event)"
                    (selectionChangedEvent)="onSelectionChanged($event)"
                >
                </app-data-grid>
            }
        </div>
    </div>
</div>

<ng-template #bulkEditTemplate>
    <span class="bulk-edit-text">You are about to upload comment to <strong>{{ bulkRowsNumber }} rows</strong></span>
</ng-template>
