import { Injectable } from '@angular/core';
import {
    CompareMode,
    DatasetFetchRuntimeParams,
    isTradeFileDetails,
    QueryParams,
    QueryTypeName,
    SpecialCaseQueryParams,
} from '@ddv/models';

import { DatasetRuntimeParamsBuilderService } from './dataset-runtime-params-builder.service';

@Injectable()
export class DatasetQueryToRuntimeParamsConverterService {
    constructor(private readonly runtimeParamsBuilder: DatasetRuntimeParamsBuilderService) {}

    convert(
        queryType: string,
        queryParams: QueryParams | undefined, // these are either the dashboard or widget query params, depending on subscribed
        dashboardQueryParams: SpecialCaseQueryParams | undefined, // only used special case queries (TFL/EOD/NetSettlement)
        widgetQueryParams: QueryParams | undefined, // only used for compare mode
        compareMode: CompareMode | undefined,
        isComparing: boolean | undefined,
        isMultiClient: boolean,
    ): { runtimeParams: DatasetFetchRuntimeParams, effectiveCompareMode: CompareMode | undefined }[] {
        const requestParams: { runtimeParams: DatasetFetchRuntimeParams, effectiveCompareMode: CompareMode | undefined }[] = [
            {
                runtimeParams: this.runtimeParamsBuilder.build(
                    queryType,
                    compareMode,
                    queryParams,
                    isMultiClient),
                effectiveCompareMode: compareMode === CompareMode.BOTH ? CompareMode.ORIGINAL : compareMode,
            },
        ];

        if (compareMode === CompareMode.BOTH && isComparing) {
            requestParams.push({
                runtimeParams: this.runtimeParamsBuilder.build(
                    queryType,
                    CompareMode.COMPARED,
                    widgetQueryParams,
                    isMultiClient),
                effectiveCompareMode: CompareMode.COMPARED,
            });
        }

        const specialCaseParams = parseSpecialCaseParams(queryType, dashboardQueryParams);
        requestParams.forEach((request) => request.runtimeParams.specialCaseParams = specialCaseParams);

        return requestParams;
    }
}

function parseSpecialCaseParams(queryTypeName: string, queryParams: SpecialCaseQueryParams | undefined): SpecialCaseQueryParams {
    const specialCaseParams: SpecialCaseQueryParams = {};
    if (isTradeFileDetails(queryTypeName) && !queryParams?.acknowledged) {
        specialCaseParams.acknowledged = queryParams?.acknowledged;
    } else if (isNetSettlementBreaksQuery(queryTypeName, queryParams)) {
        specialCaseParams.includeManuallyReleased = queryParams?.includeManuallyReleased;
    }

    return specialCaseParams;
}

// we need a smarter way to do this
// we also need to be sure we only send it to net-settlement queries
// or the other queries will break because it's not allowed per the OpenAPI spec
function isNetSettlementBreaksQuery(queryTypeName: string, dashboardQueryParam: SpecialCaseQueryParams | undefined): boolean {
    return queryTypeName === QueryTypeName.SETTLEMENT_BREAKS && dashboardQueryParam?.includeManuallyReleased !== undefined;
}
