import { Component, EventEmitter, Injector, OnDestroy, Output } from '@angular/core';
import { ColorInfo, ColorMetadataService, ColorType, LINE_CHART_COLOR_FILL } from '@ddv/charts';
import { DropdownOption } from '@ddv/common-components';
import { DashboardFiltersService } from '@ddv/dashboards';
import { WidgetDataSourceService } from '@ddv/datasets';
import {
    ConfigItem,
    CustomStyleConfig,
    ValueFilterOption,
    MANAGE_WIDGET_ID,
    SlicerValueColorConfig,
    VisualizationType,
    VizConfigs,
    WidgetData,
} from '@ddv/models';
import { Theme, ThemeService } from '@hs/ui-core-presentation';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-mw-color-viz-config',
    templateUrl: './color-chart.component.html',
    styleUrls: ['./color-chart.component.scss'],

})
export class ColorChartComponent implements OnDestroy {
    @Output() updatePreview = new EventEmitter();
    configColorSlicers: ConfigItem[] | undefined;
    isReadOnly = false;
    isTableSortDirectionCustom = false;
    vizType: string | undefined;
    colorConfigList: ColorConfig[] | undefined;
    dynamicColorConfigList: ColorConfig[] | undefined;
    selectedColorType: ColorType | undefined;
    selectedColor: string | undefined;
    isMirrored = false;
    isLineChart = false;
    isStackedAreaChart = false;
    isLineValue2 = false;
    filterAttributeList: ValueFilterOption[] = [];
    filterSearchValue: string | undefined;
    slicerValue: string | undefined;
    stackedFilterValue: string | undefined;
    theme: Theme = Theme.light;
    colorSlicerOptions: DropdownOption[] | undefined;
    selectedSlicerOption: DropdownOption | undefined;
    colorSortDirectionOptions: DropdownOption[] = [
        { text: 'Slicer Ascending', key: 'slicer-asc', value: 'slicer-asc' },
        { text: 'Slicer Descending', key: 'slicer-desc', value: 'slicer-desc' },
        { text: 'Value Ascending', key: 'value-asc', value: 'value-asc' },
        { text: 'Value Descending', key: 'value-desc', value: 'value-desc' },
    ];
    selectedColorSortDirection: DropdownOption | undefined;
    isClosed = false;

    private dataSourceSubscription: Subscription | undefined;

    constructor(
        public dashboardFiltersService: DashboardFiltersService,
        private readonly injector: Injector,
        private readonly colorMetadataService: ColorMetadataService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly themeService: ThemeService,
    ) {
        this.themeService.currentTheme$.subscribe((theme: Theme) => {
            this.theme = theme;
            this.renderPreview();
        });

        this.initDashboardFilter();
        this.onInit();
    }

    onInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.vizType = this.injector.get<VisualizationType>('visualizationType' as any);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const configs: VizConfigs = this.injector.get<VizConfigs>('configs' as any);
        this.isMirrored = this.vizType === 'VERTICAL_MIRRORED_BAR_CHART';
        this.isLineChart = this.vizType === 'LINE_CHART';
        this.isStackedAreaChart = this.vizType === 'STACKED_AREA_CHART';
        this.colorConfigList = this.getColorConfigList();
        this.selectedColorType = this.getDefaultColorType();
        this.selectedColor = this.getDefaultColor();
        this.selectedColorSortDirection = this.colorSortDirectionOptions[3];

        if (!this.isLineChart && (configs?.values?.length || configs?.slicers?.length)) {
            switch (this.vizType) {
                case 'VERTICAL_MIRRORED_BAR_CHART':
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.selectedColorType = ColorType[configs.values[0].colorType as any] as any;
                    // eslint-disable-next-line no-case-declarations
                    const colorConfigListLength = this.colorConfigList.length;
                    if (colorConfigListLength) {
                        this.colorConfigList[0].selectedValue = configs.values[0].colorName ?? '';
                        if (colorConfigListLength > 1) {
                            this.colorConfigList[1].selectedValue = configs.mirroredValues?.[0].colorName ?? '';
                        }
                    }
                    break;
                case 'VERTICAL_STACKED_BAR_CHART':
                case 'HORIZONTAL_STACKED_BAR_CHART':
                    this.stackedFilterValue = configs.details?.length ? configs.details[0].value : '';
                    this.setConfiguredColorType(configs.slicers[0]);
                    this.colorSlicerOptions = this.getColorSlicerOptions(configs.slicers);
                    this.setSelectedSlicerValue(configs.slicers[0]?.value);
                    break;
                default:
                    this.setConfiguredColorType(configs.slicers[0]);
                    this.colorSlicerOptions = this.getColorSlicerOptions(configs.slicers);
                    this.setSelectedSlicerValue(configs.slicers[0]?.value);
            }
        }

        if (this.isLineChart) {
            this.dataSourceSubscription = this.widgetDataSourceService.dataSource$.subscribe({
                next: (res) => {
                    if (configs?.values?.length || configs?.slicers?.length) {
                        let values: WidgetData[] = [];
                        res.datasources.forEach((dw) => {
                            if (dw.uniqueKey.sourceType === 'widget' && dw.uniqueKey.sourceId === MANAGE_WIDGET_ID) {
                                values = dw.data;
                            }
                        });

                        this.selectedColorType = ColorType.SOLID;
                        this.colorSlicerOptions = this.getColorSlicerOptions(configs.slicers);
                        this.updateLineColorConfig(configs, values);
                    } else {
                        this.renderPreview();
                    }
                },
            });
        }
    }

    ngOnDestroy(): void {
        if (this.dataSourceSubscription) {
            this.dataSourceSubscription.unsubscribe();
        }
    }

    setColorSlicers(value?: ConfigItem[]): void {
        this.configColorSlicers = value;
        this.colorSlicerOptions = this.configColorSlicers ?
            this.configColorSlicers.map((s): DropdownOption => ({ text: s.label ?? '', key: s.value, value: s.value })) :
            [];
    }

    setSelectedSlicerValue(value?: string): void {
        this.slicerValue = value;
        this.selectedSlicerOption = this.colorSlicerOptions?.find((o) => o.key === value);
    }

    getSelectedConfigs(dataConfig: VizConfigs): object {
        switch (this.vizType) {
            case 'LINE_CHART':
                this.updateLineColorConfig(dataConfig);
                if (this.isLineValue2) {
                    dataConfig.values.forEach((value, index) => {
                        value.colorType = ColorType[ColorType.SOLID];
                        value.colorName = this.dynamicColorConfigList?.[index].selectedValue;
                    });
                } else {
                    dataConfig.slicers.forEach((slicer) => {
                        slicer.colorType = ColorType[ColorType.SOLID];
                        slicer.colorName = LINE_CHART_COLOR_FILL;
                    });
                    this.updateSlicerConfig(dataConfig);
                }
                break;
            case 'VERTICAL_MIRRORED_BAR_CHART':
                dataConfig.values.forEach((value) => {
                    value.colorType = ColorType[ColorType.SOLID];
                    value.colorName = this.colorConfigList?.[0].selectedValue;
                });
                dataConfig.mirroredValues?.forEach((value) => {
                    value.colorType = ColorType[ColorType.SOLID];
                    value.colorName = this.colorConfigList?.[1].selectedValue;
                });
                break;
            case 'VERTICAL_STACKED_BAR_CHART':
            case 'HORIZONTAL_STACKED_BAR_CHART':
                this.stackedFilterValue = dataConfig.details?.length ? dataConfig.details[0].value : '';
                this.updateSlicerConfig(dataConfig);
                break;
            default:
                this.updateSlicerConfig(dataConfig);
        }
        return {};
    }

    updateSlicerConfig(dataConfig: VizConfigs): void {
        this.setColorSlicers(dataConfig.slicers);
        if (this.configColorSlicers?.length === 0) {
            this.setSelectedSlicerValue();
            this.selectedColorType = this.getDefaultColorType();
            this.selectedColor = this.getDefaultColor();
            this.colorConfigList = this.getColorConfigList();
        }

        if (dataConfig.slicers?.length &&
            (!this.slicerValue || !dataConfig.slicers.some((slicer) => slicer.value === this.slicerValue))) {
            this.setSelectedSlicerValue(dataConfig.slicers[0].value);
            this.selectedColorType = this.isLineChart ? ColorType.SOLID : this.selectedColorType;
            this.selectedColor = this.isLineChart ?
                this.colorConfigList?.find((config) => config.configType === this.selectedColorType)?.selectedValue :
                this.selectedColor;
        }

        if (this.slicerValue) {
            dataConfig.slicers.forEach((slicer) => {
                const customStyleObj = this.getCustomStyling(slicer);
                let [sortBy, sortDirection] = slicer.colorType === 'MONOCHROMATIC' && !this.isTableSortDirectionCustom ?
                    this.getColorSortDirectionOption(slicer)!.key.split('-') :
                    [null, null];

                if (slicer.value === this.slicerValue) {
                    if (slicer.colorType !== ColorType[this.selectedColorType!]) {
                        sortBy = null;
                        sortDirection = null;
                    }
                    slicer.isDefault = true;
                    if (customStyleObj && slicer.colorType !== ColorType[this.selectedColorType!]) {
                        customStyleObj.customColorConfigList = [];
                    }
                    slicer.configCustomStyles = customStyleObj ? customStyleObj.customColorConfigList : [];

                    this.colorConfigList = this.getColorConfigList();

                    slicer.colorType = ColorType[this.selectedColorType!];
                    if (this.selectedColor) {
                        this.colorConfigList?.map((colorConfig) => {
                            if (colorConfig.configType === this.selectedColorType) {
                                colorConfig.colorPaletteList = this.getColorValues(colorConfig, true);
                                colorConfig.selectedValue = this.isSelectedColorTransparent() ?
                                    ColorMetadataService.normalizeStackedAreaTransparentColorName(this.selectedColor ?? '') :
                                    this.selectedColor ?? '';
                                colorConfig.customStyleConfig = customStyleObj;
                                if (slicer.colorType === 'MONOCHROMATIC') {
                                    colorConfig.selectedColorDirection =
                                        this.getColorSortDirectionOption(slicer) || this.colorSortDirectionOptions[3];
                                }

                                return colorConfig;
                            }

                            return colorConfig;
                        });
                        slicer.colorName = this.selectedColor;

                        slicer.colorSortBy = sortBy || null;
                        slicer.colorSortDirection = sortDirection || null;
                        this.selectedColorSortDirection = this.getColorSortDirectionOption(slicer);
                    }
                } else {
                    slicer.isDefault = false;
                    const defaultColorType = this.getDefaultColorType();
                    slicer.colorType = slicer.colorType ?? ColorType[defaultColorType!];
                    slicer.colorName = slicer.colorName ?? this.colorConfigList?.find((colorConfig) => {
                        return colorConfig.configType === defaultColorType;
                    })?.selectedValue;
                    slicer.configCustomStyles = customStyleObj ? customStyleObj.customColorConfigList : [] || [];

                    slicer.colorSortBy = slicer.colorSortBy ?? sortBy;
                    slicer.colorSortDirection = slicer.colorSortDirection ?? sortDirection;
                }
            });
        }
    }

    getCustomStyling(slicer: ConfigItem): CustomStyleConfig {
        return {
            attributeName: slicer.value!,
            customColorConfigList: this.getCustomColorConfigList(slicer),
        };
    }

    getCustomColorConfigList(slicer?: ConfigItem): SlicerValueColorConfig[] {
        if (slicer?.configCustomStyles && slicer.configCustomStyles.length > 0) {
            return slicer.configCustomStyles;
        }
        const customStyle = this.colorConfigList?.find((config) => config.configType === this.selectedColorType)?.customStyleConfig;
        return (customStyle?.attributeName === slicer?.value ? customStyle?.customColorConfigList : []) ?? [];
    }

    updateLineColorConfig(dataConfig: VizConfigs, values?: WidgetData[]): void {
        if (!dataConfig.slicers) {
            return;
        }
        if (dataConfig.slicers.length === 0) {
            if (dataConfig.values.length) {
                this.isLineValue2 = true;
                this.setColorSlicers();
                this.dynamicColorConfigList = this.getDynamicColorConfig(dataConfig);
            }
        } else {
            this.isLineValue2 = false;

            if (!this.colorSlicerOptions?.length) {
                this.colorSlicerOptions = this.getColorSlicerOptions(dataConfig.slicers);
            }

            this.setSelectedSlicerValue(this.getSelectedSlicer(dataConfig.slicers)?.value || dataConfig.slicers[0].value);
            const slicerValueOptions = this.dashboardFiltersService.getValueOptionsByAttribute(this.slicerValue, values);
            this.updateLineChartColorConfigList(dataConfig.slicers, slicerValueOptions);
        }
    }

    setConfiguredColorType(slicer: ConfigItem): void {
        if (slicer.colorType) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.selectedColorType = ColorType[slicer.colorType as any] as any;
        }
        const selectedConfig = this.colorConfigList?.find((colorConfig) => colorConfig.configType === this.selectedColorType);
        if (selectedConfig) {
            selectedConfig.selectedValue = slicer.colorName ?? '';
        }
        if (slicer.colorName) {
            this.selectedColor = slicer.colorName;
        }

        this.selectedColorSortDirection = this.getColorSortDirectionOption(slicer);
    }

    getColorValues(selectedColorValue: ColorConfig, isDisplayValue: boolean): string[] {
        let colorValue: ColorInfo | undefined;

        if (this.isLineChart && this.selectedColor !== LINE_CHART_COLOR_FILL) {
            colorValue = selectedColorValue.customColorPalette?.find((color) => color.label === this.selectedColor);
        } else if (this.isStackedAreaChart && this.isSelectedColorTransparent()) {
            const selectedColor = ColorMetadataService.normalizeStackedAreaTransparentColorName(this.selectedColor ?? '');
            colorValue = selectedColorValue.colorValues.find((color) => color.label === selectedColor);
        } else {
            colorValue = selectedColorValue.colorValues.find((color) => color.label === this.selectedColor);
        }

        return (isDisplayValue ? colorValue?.displayValues : colorValue?.values) ?? [];
    }

    onColorTypeSelect(): void {
        this.selectedColor = this.getDefaultColor();
        if (this.slicerValue) {
            this.renderPreview();
        }
    }

    onColorSelected(ctx: ColorConfig, item: ColorInfo, el: HTMLElement, isCustomColor?: boolean, index?: number): void {
        el.hidden = true;
        if (!isCustomColor && (this.isLineValue2 || ctx.configType === this.selectedColorType)) {
            ctx.selectedValue = typeof item === 'string' ? item : item.label;
            this.selectedColor = ctx.selectedValue;
            this.renderPreview();
        }
        if (isCustomColor) {
            const colorConfig = this.colorConfigList?.find((config) => config.configType === this.selectedColorType);
            colorConfig?.customStyleConfig?.customColorConfigList.forEach((slicer, i) => {
                if (i === index) {
                    slicer.attributeColorName = typeof item === 'string' ? item : item.label;
                }
                return slicer;
            });
            this.renderPreview();
        }
    }

    onColorTransparencyChanged(colorConfig: ColorConfig): void {
        if (colorConfig.isTransparencyOn) {
            this.selectedColor = `${this.selectedColor} Transparent`;
        } else {
            this.selectedColor = ColorMetadataService.normalizeStackedAreaTransparentColorName(this.selectedColor ?? '');
        }
        this.renderPreview();
    }

    onSlicerSelect(): void {
        if (this.slicerValue) {
            const selectedSlicer = this.getSelectedSlicer(this.configColorSlicers ?? []);
            if (selectedSlicer) {
                this.setConfiguredColorType(selectedSlicer);
            }
        }
        this.renderPreview();
    }

    renderPreview(): void {
        this.updatePreview.emit();
    }

    getDynamicSelectedColorValue(loadedColorConfigList: ColorConfig[], dataConfigValue: ConfigItem, index: number): string {
        if (loadedColorConfigList?.[index] && loadedColorConfigList.filter((color) => color.type === dataConfigValue.label).length) {
            return loadedColorConfigList[index].selectedValue;
        }
        if (dataConfigValue.colorName) {
            return dataConfigValue.colorName;
        }
        return index === 0 ? '#25A9E0' : '#A4C439';
    }

    addMoreColor(): void {
        this.filterSearchValue = '';
        const colorConfig = this.colorConfigList?.find((config) => config.configType === this.selectedColorType);
        colorConfig?.customStyleConfig?.customColorConfigList.push({
            attributeValue: '',
            attributeColorName: '',
        });
    }

    addAdditionalLineChartColor(): void {
        const colorConfig = this.colorConfigList?.find((config) => config.configType === this.selectedColorType);
        colorConfig?.customStyleConfig?.customColorConfigList.push({
            attributeValue: '',
            attributeColorName: '',
            lineChartCustomConfig: true,
        });
    }

    addCustomAttribute(index: number): void {
        if (this.filterSearchValue?.trim()) {
            const convertedSearchValue = this.filterSearchValue.toLowerCase();
            if (!this.filterAttributeList.some((filterValue) => filterValue.value.toLowerCase() === convertedSearchValue)) {
                this.insertIntoFilterValues(this.filterSearchValue);
                const customAttribute: ValueFilterOption = {
                    value: this.filterSearchValue,
                    text: this.filterSearchValue,
                };
                this.onFilterValueSelect(customAttribute, index);
            }
        }
        this.filterSearchValue = '';
    }

    addLineChartCustomAttributeValue(index: number): void {
        const colorConfig = this.colorConfigList?.find((config) => config.configType === this.selectedColorType);
        const customColorConfigList = colorConfig?.customStyleConfig?.customColorConfigList;

        if (customColorConfigList?.[index].attributeValue) {
            customColorConfigList[index].lineChartCustomConfig = false;
        }
    }

    removeConfigAtIndex(index: number): void {
        const colorConfig = this.colorConfigList?.find((config) => config.configType === this.selectedColorType);
        colorConfig?.customStyleConfig?.customColorConfigList.splice(index, 1);
        this.renderPreview();
    }

    toggleFilterValues(filterValuesList: HTMLElement, ctx: SlicerValueColorConfig): void {
        if (filterValuesList.hidden) {
            this.showFiltersValues(filterValuesList, ctx);
        } else {
            this.hideFiltersValues(filterValuesList);
        }
    }

    showFiltersValues(filterValuesList: HTMLElement, ctx: SlicerValueColorConfig): void {
        filterValuesList.hidden = false;
        this.loadFilterAttributeList();
        if (ctx.attributeValue && this.filterAttributeList.findIndex((customColor) => customColor.value === ctx.attributeValue) < 0) {
            this.insertIntoFilterValues(ctx.attributeValue);
        }
    }

    hideFiltersValues(filterValuesList: HTMLElement): void {
        filterValuesList.hidden = true;
    }

    onFilterValueSelect(item: ValueFilterOption, index: number): void {
        const colorConfig = this.colorConfigList?.find((config) => config.configType === this.selectedColorType);
        colorConfig?.customStyleConfig?.customColorConfigList.forEach((slicer, i) => {
            if (i === index) {
                slicer.attributeValue = item.value;
            }
        });
        this.renderPreview();
    }

    getDropdownClass(type: string): string {
        return (this.isMirrored || this.isLineChart || type === 'Multi-Colored') ? 'mirrored-bar-dropdown' :
            type === 'Solid Color' ? 'solid-color-dropdown' : 'color-dropdown';
    }

    onColorSortDirectionSelect(event: DropdownOption): void {
        this.selectedColorSortDirection = event;

        const [sortBy, sortDirection] = event.key.split('-');
        const selectedSlicer = this.getSelectedSlicer(this.configColorSlicers ?? []);
        if (selectedSlicer) {
            selectedSlicer.colorSortBy = sortBy;
            selectedSlicer.colorSortDirection = sortDirection;
        }

        this.renderPreview();
    }

    shouldShowColorSorting(colorConfig: ColorConfig): boolean {
        return colorConfig.type === 'Monochromatic' &&
            !this.isTableSortDirectionCustom &&
            ['PIE_CHART', 'DONUT_CHART', 'VERTICAL_BAR_CHART', 'HORIZONTAL_BAR_CHART'].indexOf(this.vizType ?? '') !== -1;
    }

    private getDefaultColorType(): ColorType | undefined {
        const vizTypeColorConfig = this.colorConfigList?.find((colorConfig) => {
            return colorConfig.defaultVisualizations?.indexOf(this.vizType ?? '') !== -1;
        });

        return vizTypeColorConfig?.configType;
    }

    private getDefaultColor(): string {
        return this.colorConfigList?.find((colorConfig) => colorConfig.configType === this.selectedColorType)?.selectedValue ?? '';
    }

    private getDynamicColorConfig(dataConfig: VizConfigs): ColorConfig[] {
        const customColorPaletteValues = this.colorMetadataService.getMirroredBarAndLineChartColorConfig();
        return dataConfig.values.map((value, index) => ({
            type: value.label ?? '',
            configType: ColorType.SOLID,
            colorValues: customColorPaletteValues,
            selectedValue: this.getDynamicSelectedColorValue(this.dynamicColorConfigList ?? [], value, index),
        }));
    }

    private updateLineChartColorConfigList(slicers: ConfigItem[], slicerValueOptions: ValueFilterOption[]): void {
        const currentSlicer = this.getSelectedSlicer(slicers);
        const customColorConfigList = this.getCustomColorConfigList(currentSlicer);

        slicerValueOptions.forEach((value, index) => {
            if (!customColorConfigList.find((config) => config.attributeValue === value.text)) {
                customColorConfigList.push({
                    attributeValue: value.text,
                    attributeColorName: this.getLineChartSelectedValue(index),
                });
            }
        });

        this.colorConfigList![0].customStyleConfig = { attributeName: this.slicerValue ?? '', customColorConfigList };
        this.colorConfigList![0].customColorPalette = this.colorMetadataService.getMirroredBarAndLineChartColorConfig();
    }

    private getLineChartSelectedValue(index: number): string {
        const lineChartColorOptions = this.colorMetadataService.getLineChartColorOptions();
        return lineChartColorOptions[0].values[index % lineChartColorOptions[0].values.length];
    }

    private getColorConfigList(): ColorConfig[] {
        if (this.isMirrored) {
            return this.getMirroredBarColorConfigList();
        }

        if (this.isLineChart) {
            return this.getLineChartColorConfigList();
        }

        const customMonochromaticColorPaletteValues = this.colorMetadataService.getMonochromaticColorConfig();
        const customSolidColorPaletteValues = this.colorMetadataService.getSolidColorConfig(this.theme);
        const customMultiColorPaletteValues = this.colorMetadataService.getMultiColorConfig();

        const solidColorPaletteValues = this.colorMetadataService.getSolidColorConfig(this.theme);
        const monochromaticColorPaletteValues = this.colorMetadataService.getColorConfig().filter((colorConfig) => {
            return colorConfig.type === ColorType.MONOCHROMATIC;
        });
        const multiColorPaletteValues = this.colorMetadataService.getColorConfig().filter((colorConfig) => {
            return colorConfig.type === ColorType.MULTICOLORED;
        });

        const commonColorConfigs: ColorConfig[] = [
            {
                type: 'Monochromatic',
                configType: ColorType.MONOCHROMATIC,
                typeDetail: 'Best for most basic charting',
                colorValues: monochromaticColorPaletteValues,
                selectedValue: 'Mixed Green-Yellow Family',
                defaultVisualizations: [
                    'PIE_CHART',
                    'DONUT_CHART',
                    'VERTICAL_STACKED_BAR_CHART',
                    'HORIZONTAL_STACKED_BAR_CHART',
                    'STACKED_AREA_CHART',
                ],
                colorPaletteList: monochromaticColorPaletteValues.find((colorConfig) => colorConfig.label === 'Mixed Green-Yellow Family')?.displayValues,
                customStyleConfig: {
                    attributeName: this.slicerValue ?? '',
                    customColorConfigList: [],
                },
                customColorPalette: customMonochromaticColorPaletteValues,
            },
            {
                type: 'Multi-Colored',
                configType: ColorType.MULTICOLORED,
                typeDetail: 'Best for line chart with multiple values',
                colorValues: multiColorPaletteValues,
                selectedValue: 'Diverse Group',
                defaultVisualizations: ['LINE_CHART'],
                colorPaletteList: multiColorPaletteValues.find((colorConfig) => colorConfig.label === 'Diverse Group')?.displayValues,
                customStyleConfig: {
                    attributeName: this.slicerValue ?? '',
                    customColorConfigList: [],
                },
                customColorPalette: customMultiColorPaletteValues,
            },
        ];

        if (this.isStackedAreaChart) {
            commonColorConfigs.forEach((colorConfig) => (
                colorConfig.isTransparencyOn = this.isSelectedColorTransparent() && colorConfig.configType === this.selectedColorType
            ));
            return commonColorConfigs;
        }

        return [
            {
                type: 'Solid Color',
                configType: ColorType.SOLID,
                typeDetail: 'Best for simple bar chart',
                colorValues: solidColorPaletteValues,
                selectedValue: 'Cyan Fill',
                defaultVisualizations: ['VERTICAL_BAR_CHART', 'HORIZONTAL_BAR_CHART'],
                colorPaletteList: solidColorPaletteValues.find((colorConfig) => colorConfig.label === 'Cyan Fill')?.displayValues,
                customStyleConfig: {
                    attributeName: this.slicerValue ?? '',
                    customColorConfigList: [],
                },
                customColorPalette: customSolidColorPaletteValues,
            },
            ...commonColorConfigs,
        ];
    }

    private getMirroredBarColorConfigList(): ColorConfig[] {
        const customMirroredBarChartPaletteValues = this.colorMetadataService.getMirroredBarAndLineChartColorConfig();

        return [
            {
                type: 'VALUE 1 (Top Axis)',
                configType: ColorType.SOLID,
                colorValues: customMirroredBarChartPaletteValues,
                selectedValue: '#25A9E0',
                defaultVisualizations: ['VERTICAL_MIRRORED_BAR_CHART'],
                colorPaletteList: ['#25A9E0'],
            },
            {
                type: 'VALUE 2 (Bottom Axis)',
                configType: ColorType.SOLID,
                colorValues: customMirroredBarChartPaletteValues,
                selectedValue: '#E74C3C',
                defaultVisualizations: ['VERTICAL_MIRRORED_BAR_CHART'],
                colorPaletteList: ['#25A9E0'],
            },
        ];
    }

    private getLineChartColorConfigList(): ColorConfig[] {
        const lineChartPaletteValues = this.colorMetadataService.getLineChartColorOptions();
        const customLineChartPaletteValues = this.colorMetadataService.getMirroredBarAndLineChartColorConfig();

        return [
            {
                type: 'Solid Color',
                configType: ColorType.SOLID,
                colorValues: lineChartPaletteValues,
                selectedValue: LINE_CHART_COLOR_FILL,
                defaultVisualizations: ['LINE_CHART'],
                customStyleConfig: {
                    attributeName: this.slicerValue ?? '',
                    customColorConfigList: [],
                },
                customColorPalette: customLineChartPaletteValues,
            },
        ];
    }

    private initDashboardFilter(): void {
        this.dashboardFiltersService.setFilterType('coreWidget');
        this.dashboardFiltersService.setSlicerValues(MANAGE_WIDGET_ID);
    }

    private loadFilterAttributeList(): void {
        let filterAttribute = this.slicerValue;
        if (this.vizType === 'VERTICAL_STACKED_BAR_CHART' || this.vizType === 'HORIZONTAL_STACKED_BAR_CHART') {
            filterAttribute = this.stackedFilterValue;
        }
        this.filterAttributeList = this.dashboardFiltersService.getValueOptionsByAttribute(filterAttribute);
    }

    private insertIntoFilterValues(filterValue: string): void {
        this.filterAttributeList.unshift({
            value: filterValue,
            text: filterValue,
        });
    }

    private getSelectedSlicer(slicers: ConfigItem[]): ConfigItem | undefined {
        return slicers.find((slicer) => slicer.value === this.slicerValue);
    }

    private getColorSlicerOptions(slicers: ConfigItem[]): DropdownOption[] {
        return slicers.length ?
            slicers.map((i): DropdownOption => ({ text: (i.showCustomName ? i.customName : i.label) ?? '', key: i.value, value: i.value })) :
            [];
    }

    private getColorSortDirectionOption(slicer: ConfigItem): DropdownOption | undefined {
        if (!slicer.colorSortBy || !slicer.colorSortDirection) {
            return this.colorSortDirectionOptions[3];
        }

        const key = `${slicer.colorSortBy}-${slicer.colorSortDirection}`;

        return this.colorSortDirectionOptions.find((option) => option.key === key);
    }

    private isSelectedColorTransparent(): boolean {
        return !!this.selectedColor?.includes('Transparent');
    }
}

export interface ColorConfig {
    type: string;
    typeDetail?: string;
    colorValues: ColorInfo[];
    selectedValue: string;
    defaultVisualizations?: string[];
    configType: ColorType;
    colorPaletteList?: string[];
    customStyleConfig?: CustomStyleConfig;
    customColorPalette?: ColorInfo[];
    selectedColorDirection?: DropdownOption;
    isTransparencyOn?: boolean;
}
