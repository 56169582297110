import { Injectable } from '@angular/core';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { ManagerService } from '@ddv/layout';
import {
    CompareMode,
    DatasetDefinitionDetails,
    DatasetFetchRuntimeParams,
    NamedQuery,
    Query,
    QueryParams,
    QueryTemplateHolder,
    SpecialCaseQueryParams,
    WIDGET_LIFECYCLE_EVENT,
} from '@ddv/models';
import { NamedQueriesService } from '@ddv/named-queries';
import { Observable, map, mergeMap, combineLatest, of } from 'rxjs';

import { PublicApiResponseRow, TrebekError } from '../models/trebek';
import { DatasetQueryRunnerService } from './dataset-query-runner.service';
import { DatasetQueryToRuntimeParamsConverterService } from './dataset-query-to-runtime-params-converter.service';

export interface DatasetFetchResult {
    data: PublicApiResponseRow[];
    compareMode: CompareMode | undefined;
}

@Injectable()
export class DatasetDatasetFetcherService {
    constructor(
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly namedQueriesService: NamedQueriesService,
        private readonly queryParamsConverter: DatasetQueryToRuntimeParamsConverterService,
        private readonly queryRunner: DatasetQueryRunnerService,
        private readonly managerService: ManagerService,
    ) {}

    fetchDataset(
        clientCode: string,
        dashboardId: string | number,
        widgetIds: number[],
        namedQueryId: string | number,
        queryParams: QueryParams | undefined,
        dashboardQueryParam: SpecialCaseQueryParams | undefined,
        widgetQueryParams: QueryParams | undefined,
        compareMode: CompareMode | undefined,
        isComparing: boolean | undefined,
        isMultiClient: boolean,
    ): Observable<DatasetFetchResult[]> {
        return this.fetchNamedQueryOrDatasetDefinition(namedQueryId)
            .pipe(
                mergeMap((namedQuery) => {
                    const requests = this.queryParamsConverter.convert(
                        namedQuery.normalizeQueryTypeName(),
                        queryParams,
                        dashboardQueryParam,
                        widgetQueryParams,
                        compareMode,
                        isComparing,
                        isMultiClient);

                    return combineLatest(
                        requests.map(({ runtimeParams, effectiveCompareMode }) => {
                            try {
                                return this.fetchDataForRuntimeParams(
                                    clientCode,
                                    dashboardId,
                                    widgetIds,
                                    namedQuery,
                                    runtimeParams,
                                    effectiveCompareMode);
                            } catch (error) {
                                this.sendGenericError(widgetIds, error as Error);
                                return of({
                                    data: [],
                                    compareMode: effectiveCompareMode,
                                });
                            }
                        }),
                    );
                }),
            );
    }

    private fetchNamedQueryOrDatasetDefinition(id: string | number): Observable<NamedQuery | DatasetDefinitionDetails> {
        if (typeof id === 'number') {
            return this.datasetDefinitionsService.fetchDatasetDefinitionDetails(id);
        }

        return this.namedQueriesService.fetchNamedQuery(id);
    }

    private fetchDataForRuntimeParams(
        clientCode: string,
        dashboardId: string | number,
        widgetIds: number[],
        queryTemplateHolder: QueryTemplateHolder,
        runtimeParams: DatasetFetchRuntimeParams,
        compareMode: CompareMode | undefined,
    ): Observable<DatasetFetchResult> {
        let queries: Query[];
        if (queryTemplateHolder.normalizeQueryIsStacked()) {
            queries = queryTemplateHolder.populateStackedQueryTemplate(runtimeParams);
        } else {
            queries = [queryTemplateHolder.populateQueryTemplate(runtimeParams)];
        }

        return this.queryRunner
            .runQueries(
                clientCode,
                dashboardId, // this is only used for usage tracking
                widgetIds[0], // this is only used for usage tracking, so it really doesn't matter which widgetId we use
                queryTemplateHolder.id,
                queries,
                queryTemplateHolder.normalizeQueryEndPoint(clientCode),
                runtimeParams)
            .pipe(
                map(({ data, trebekError }) => {
                    if (trebekError) {
                        this.sendTrebekErrorMessage(widgetIds, trebekError);
                    }

                    // this probably only needs to happen once and we should probably pull it up a function
                    this.sendHideCancelMessage(widgetIds);

                    return {
                        data,
                        compareMode,
                    };
                }),
            );
    }

    private sendHideCancelMessage(widgetIds: number[]): void {
        widgetIds.forEach((widgetId) => {
            this.managerService.sendMessageToExistingWidget(widgetId, WIDGET_LIFECYCLE_EVENT.HIDE_CANCEL);
        });
    }

    private sendTrebekErrorMessage(widgetIds: number[], trebekError: TrebekError): void {
        this.sendErrorMessageToEachWidget(widgetIds, trebekError.message, trebekError.timestamp);
    }

    private sendGenericError(widgetIds: number[], error: Error): void {
        this.sendErrorMessageToEachWidget(widgetIds, error.message);
    }

    private sendErrorMessageToEachWidget(widgetIds: number[], message: string, time?: string): void {
        widgetIds.forEach((widgetId) => {
            this.managerService.sendMessageToExistingWidget(
                widgetId,
                {
                    action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                    exception: {
                        message,
                        time,
                    },
                });
        });
    }
}
