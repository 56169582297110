import { Injectable } from '@angular/core';
import { ClientDatasetFilterService } from '@ddv/filters';
import { DatasetFetchRuntimeParams, Query } from '@ddv/models';
import { combineLatest, map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PublicAPIQueryResponse, PublicApiResponseRow, TrebekError } from '../models/trebek';
import { addCrosstalkLinksToData } from './crosstalk-data.service';
import { DatasetTrebekQueryRunnerService } from './dataset-trebek-query-runner.service';

export interface Dataset extends PublicAPIQueryResponse {
    trebekError?: TrebekError;
}

@Injectable()
export class DatasetQueryRunnerService {
    constructor(
        private readonly trebekInvoker: DatasetTrebekQueryRunnerService,
        private readonly clientDatasetFilterService: ClientDatasetFilterService,
    ) {}

    runQueries(
        clientCode: string,
        dashboardId: string | number | undefined,
        widgetId: number,
        namedQueryId: number | string,
        queries: Query[],
        queryEndpoint: string,
        runtimeParams: DatasetFetchRuntimeParams,
    ): Observable<Dataset> {
        const queries$: Observable<Dataset>[] = queries.map((dataQuery) => {
            return this.invokePublicAPI(
                dashboardId,
                widgetId,
                namedQueryId,
                dataQuery,
                queryEndpoint,
                runtimeParams.clientCodeList);
        });

        return combineLatest(queries$)
            .pipe(
                map((responses) => {
                    const queryError = responses.find((response) => response.trebekError)?.trebekError;
                    if (queryError) {
                        return {
                            trebekError: queryError,
                            data: [],
                        };
                    }

                    const data = this.combineDatasets(
                        responses.map((response) => response.data),
                        queries[0].selectedColumns);

                    data.forEach((datum) => addCrosstalkLinksToData(clientCode, datum));
                    return { data };
                }),
            );
    }

    private invokePublicAPI(
        dashboardId: string | number | undefined,
        widgetId: number,
        namedQueryId: number | string,
        dataQuery: Query,
        queryEndpoint: string,
        clientCodeList: string[] | undefined,
    ): Observable<PublicAPIQueryResponse & { trebekError?: TrebekError }> {
        return this.trebekInvoker
            .runQuery(
                dashboardId,
                widgetId,
                namedQueryId,
                dataQuery,
                queryEndpoint,
                clientCodeList)
            .pipe(
                catchError((error) => {
                    return of({
                        trebekError: error as TrebekError,
                        data: [],
                    });
                }),
            );
    }

    private combineDatasets(datas: PublicApiResponseRow[][], columns: { columnId: string }[] | undefined): PublicApiResponseRow[] {
        if (datas.length === 1 || !columns?.length) {
            return datas[0];
        }

        return this.clientDatasetFilterService.removeDuplicateRows(
            datas.flatMap((data) => data),
            columns.map((column) => column.columnId));
    }
}
